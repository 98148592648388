import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { SideNavComponent } from './side-nav/side-nav.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button';
import { HeaderComponent } from './header/header.component';
import { AuthModule } from '../auth/auth.module';
import { FooterComponent } from './footer/footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SearchLinksComponent } from './search-links/search-links.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { HeaderViewComponent } from './header-view/header-view.component';
import { FindPropertiesComponent } from './find-properties/find-properties.component';
import { MatSelectModule } from '@angular/material/select';
import { PaisesComponent } from './paises/paises.component';
import { AyudaVentaComponent } from './ayuda-venta/ayuda-venta.component';
import { FormsModule } from '@angular/forms';
import { ZonaLayoutModule } from '../utils/zona-layout/zona-layout.module';
import { ZonaSeccionModule } from '../utils/zona-layout/zona-seccion/zona-seccion.module';
import { TranslateModule } from '@ngx-translate/core';
import { TraduccionesModule } from '../utils/traducciones/traducciones.module';
import { UtilsModule } from '../utils/utils.module';
import { VenderFloatingButtonComponent } from './vender-floating-button/vender-floating-button.component';

@NgModule({
  declarations: [
    SideNavComponent,
    TopNavComponent,
    HeaderComponent,
    FooterComponent,
    SearchLinksComponent,
    HeaderViewComponent,
    FindPropertiesComponent,
    PaisesComponent,
    AyudaVentaComponent,
    VenderFloatingButtonComponent

  ],
  exports: [
    SideNavComponent,
    TopNavComponent,
    HeaderComponent,
    FooterComponent,
    SearchLinksComponent,
    HeaderViewComponent,
    FindPropertiesComponent,
    AyudaVentaComponent,
    MatSelectModule,
    VenderFloatingButtonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
    AuthModule,
    ZonaLayoutModule,
    RouterModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatMenuModule,
    FlexLayoutModule,
    MatExpansionModule,
    ZonaSeccionModule,
    MatSelectModule,
    TraduccionesModule,
    TranslateModule
  ]
})
export class ZonaCommonModule { }
