import { AppInjector } from './app-injector';
import { Type } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PerformanceTimer, DTOUtil } from './utils';


export class ZonaHabitatObject {

    /**
     * Verifica si una cadena de texto es null o está vacía o solo tiene
     * espacios
     */
    protected isBlank(value: string): boolean {
        if (value == null) {
            return true;
        }
        value = value.trim();
        return value.length === 0;
    }

    protected toInt(value: any): number {
        if (value == null) {
            return null;
        }
        if (typeof (value) === 'number') {
            return value;
        }
        if (typeof (value) === 'string') {
            const converted = parseInt(value);
            return isNaN(converted) ? null : converted;
        }
        throw new Error(`unnable to convert value of type ${typeof (value)}`);
    }

    protected logD(msg: string, ...args: any[]) {
        this.log(0, msg, ...args);
    }

    protected logI(msg: string, ...args: any[]) {
        this.log(10, msg, ...args);
    }

    protected logW(msg: string, ...args: any[]) {
        this.log(20, msg, ...args);
    }

    protected logE(msg: string, ...args: any[]) {
        this.log(30, msg, ...args);
    }

    protected analyticsEvent(category: string, action: string, label: string = null, value: number = null) {
    }

    private log(level: number, msg: string, ...args: any[]) {
        if (!environment.enableLogging) {
            return;
        }
        const formatted = this.format(msg, ...args);
        if (level < 10) {
            console.debug(formatted);
        } else if (level < 20) {
            console.log(formatted);
        } else if (level < 30) {
            console.warn(formatted);
        } else { console.error(formatted); }
    }

    protected format(pattern: string, ...args: any[]) {
        if (args.length === 0) { return pattern; }
        return pattern.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] !== 'undefined' ? args[number] : match;
        });
    }
    protected getInjectable<T>(token: Type<T>) {
        return AppInjector().get(token);
    }

    protected printError(e) {
        console.log(e);
    }

    protected logObject(e) {
        if (!environment.production) {
            console.log(e);
        }
    }

    protected time(name: string = null): PerformanceTimer {
        return new PerformanceTimer(name)
    }


    protected validateOrGetFirst(value: any, ...valid: any[]): any {
        if (valid.includes(value)) {
            return value;
        }
        return valid[0];
    }
}
