import { Injectable } from '@angular/core';
import { AbstractService } from '../abstract.service';
import { MatDialog } from '@angular/material/dialog';
import { PqrsDialogComponent } from './pqrs-dialog/pqrs-dialog.component';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PqrsService extends AbstractService {

  constructor(public dialog: MatDialog) {
    super()
  }

  abrirDialogoPqrs() {
    let dialogRef = this.dialog.open(PqrsDialogComponent);
    dialogRef.componentInstance.pqrsService = this
  }

  enviarPqrs(pqrs: Pqrs): Observable<any> {
    return this.apiPost('contacto', '/pqrs', pqrs)
  }

}

export interface Pqrs {
  mensaje: string,
  tipo: string
  ruta: string
}
