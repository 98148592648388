import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaceBoxComponent } from './place-box/place-box.component';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TraduccionesModule } from '../../utils/traducciones/traducciones.module';



@NgModule({
  declarations: [PlaceBoxComponent],
  exports: [PlaceBoxComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    TraduccionesModule
  ]
})
export class ZonaGeoModule { }
