import { Injectable, EventEmitter } from '@angular/core';
import { AbstractService } from './abstract.service';
import { Router, ActivatedRoute, Event as REvent, NavigationEnd, Params } from '@angular/router';
import { DTOUtil } from './utils';
import { Location } from '@angular/common';

/**
 * @deprecated Usar ActivatedRoute y queryParamsHandling="merge"
 */
@Injectable({ 'providedIn': 'root' })
export class QueryParamsService extends AbstractService {

  private paramMap = new Map<string, QueryParam>();
  private currentParams: any;
  private queryParams: QueryParams;
  public readonly onParamsChanged = new EventEmitter()

  constructor(private router: Router, private location: Location) {
    super();
    router.events.subscribe(e => this.onNavigationEvent(e));
    this.currentParams = this.paramsToObject(router.routerState.root.snapshot.queryParams);
    this.queryParams = new QueryParams(this.currentParams);
    location.onUrlChange((url, state) => {
      console.log(url, state)
    })
  }

  public mergeQueryParamsToLink(params: any) : string {
    const merged = this.mergeWithQueryParams(params)
    let strParams = Object.keys(merged).reduce((serial, k, i) => `${serial}${i === 0 ? '?' : '&'}${k}=${encodeURIComponent(merged[k])}`, "")
    return window.location.pathname + (strParams.length > 0 ? `&${strParams}` : "")
  }


  public mergeQueryParams(params: any) {
    const actual = this.queryParamsToObject()
    const merged = this.mergeWithQueryParams(params)
    if (DTOUtil.equals(actual, merged)) {
      return
    }
    let strParams = Object.keys(merged).reduce((serial, k, i) => `${serial}${i === 0 ? '?' : '&'}${k}=${encodeURIComponent(merged[k])}`, "")
    this.location.go(window.location.pathname, strParams)
    this.onParamsChanged.emit()
  }



  public getParamValue(name: string, defaultVal: any = null): QueryParamValue {
    const params = this.queryParamsToObject()
    const param = params[name]
    return new QueryParamValue(param, defaultVal)
  }

  private mergeWithQueryParams(params: any) {
    const actual = this.queryParamsToObject()
    Object.keys(params).forEach((k: string) => {
      const value = params[k]
      if (value == null) delete actual[k]
      else actual[k] = value
    })
    return actual
  }

  private queryParamsToObject(): any {
    const { search } = window.location
    if (search.length < 2) return {}
    return search.substring(1, search.length)
      .split("&").map(p => p.split("="))
      .filter(p => p.length == 2)
      .reduce((obj, item) => ({ ...obj, [item[0]]: decodeURIComponent(item[1]) }), {})
  }

  private onNavigationEvent(e: REvent) {
    if (!(e instanceof NavigationEnd)) {
      return;
    }
    this.checkChanges();
  }

  private checkChanges(newParams: any = null) {
    const params = newParams ? newParams : this.router.routerState.root.snapshot.queryParams;
    if (!this.hasChanges(params)) {
      return;
    }
    this.currentParams = this.paramsToObject(params);
    this.queryParams = new QueryParams(this.currentParams);
    this.updateParamObjects(params);
  }


  /**
   * @deprecated
   */
  getParam(name: string): QueryParam {
    let param = this.paramMap.get(name);
    if (param == null) {
      param = new EditableQueryParam(name, this.currentParams[name]);
      this.paramMap.set(name, param);
    }
    return param;
  }

  onAnyChange(...names: string[]): QueryParamGroupListener {
    const listeners: Array<QueryParam> = [];
    names.forEach(n => listeners.push(this.getParam(n)));
    return new QueryParamGroupListener(listeners);
  }

  private paramsToObject(params: Params): any {
    const object = {};
    Object.keys(params).forEach(k => object[k] = this.toString(params[k]));
    return object;
  }

  private toString(value) {
    return value ? value.toString() : null;
  }

  private hasChanges(params: Params): boolean {
    const newParams = this.paramsToObject(params);
    return !DTOUtil.equals(this.currentParams, newParams);
  }

  private updateParamObjects(params: Params) {
    this.paramMap.forEach((v, k) => {
      (v as EditableQueryParam).updateValue(this.toString(params[k]));
    });
  }

  public get params(): QueryParams {
    return this.queryParams;
  }

  updateParams(activatedRoute: ActivatedRoute, params: any) {
    const newParams = DTOUtil.clone(this.currentParams);
    for (const key of Object.keys(params)) {
      const value = params[key];
      if (value == null) {
        delete newParams[key];
      } else {
        newParams[key] = params[key];
      }
    }
    this.router.navigate([], { relativeTo: activatedRoute, queryParams: newParams });
    this.checkChanges(newParams);
  }
}


export class QueryParamValue {
  private _value: string = null
  private _default: any = null
  constructor(value: string, defValue: any) {
    this._value = value
    this._default = defValue
  }

  asString(): string {
    return this._value || this._default ? `${this._default}` : null
  }

  asInteger(): number {
    const value = parseInt(this._value)
    return isNaN(value) ? this._default : value
  }

  asFloat(): number {
    const value = parseFloat(this._value)
    return isNaN(value) ? this._default : value
  }

  asBoolean(): boolean {
    const val = this.asString()
    return val == null ? this._default : val.toLowerCase() === "true"
  }
}


export class QueryParamGroupListener {
  readonly onChange = new EventEmitter();

  constructor(params: QueryParam[]) {
    params.forEach(p => p.onChange.subscribe(_ => this.onChange.emit()));
  }
}

export class QueryParams {
  constructor(private value: any) {
  }

  isDefined(name: string): boolean {
    return this.value[name] != null;
  }

  getInteger(name: string): number {
    const val = this.getString(name);
    if (!val) { return null; }
    const parsed = parseInt(val, 10);
    return isNaN(parsed) ? null : parsed;
  }

  getString(name: string): string {
    return this.value[name];
  }

  getFloat(name: string): number {
    const val = this.getString(name);
    if (!val) { return null; }
    const parsed = parseFloat(val);
    return isNaN(parsed) ? null : parsed;
  }

}

export class QueryParam {

  readonly onChange = new EventEmitter<QueryParam>();

  constructor(public readonly name: string, protected value: string) {
  }

  isSet(): boolean {
    return this.value != null;
  }

  stringValue(): string {
    return this.value;
  }

  intValue(radix = 10): number {
    if (this.value == null) {
      return null;
    }
    const parsed = parseInt(this.value, radix);
    return isNaN(parsed) ? null : parsed;
  }

  floatValue(): number {
    if (this.value == null) {
      return null;
    }
    const parsed = parseFloat(this.value);
    return isNaN(parsed) ? null : parsed;
  }

  boolValue(): boolean {
    if (this.value == null) {
      return false;
    }
    let val = this.value.toLowerCase();
    return val == "1" || val == "true";
  }
}

class EditableQueryParam extends QueryParam {

  updateValue(value: string) {
    if (value === this.value) {
      return;
    }
    this.value = value;
    this.onChange.emit(this);
  }
}
