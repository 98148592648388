<div class="zona-imagen" [ngStyle]="{'height': alto + 'px'}">
    <div class="fondo" [ngStyle]="{'background-image': 'url(' + _url + '), url(' + urlNoDisponible + ')'}"></div>
    <div class="contenedor-imagen">
        <img class="imagen" [src]="_url" (error)="errorImagen()" (load)="onImagenCargada()">
    </div>
    <div class="cargando" *ngIf="cargando">
        <svg class="animated" xmlns='http://www.w3.org/2000/svg' width='145.26' height='94.62'
            viewBox='0 0 145.26 94.62'>
            <polyline fill='none' stroke='#fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='6px'
                points='3 91.35 3 70.91 95.43 3 95.43 91.61 51.93 91.56 52.01 34.91 142.11 56.41 142.26 91.61' />
        </svg>
        <img src="/assets/icons/svg/zh_texto.svg" class="mt20" width="145px">
        <div class="mt20">Cargando Imagen...</div>
    </div>
    <div class="no-disponible" *ngIf="noDisponible && !cargando">
        <svg xmlns='http://www.w3.org/2000/svg' width='145.26' height='94.62'
            viewBox='0 0 145.26 94.62'>
            <polyline fill='none' stroke='#fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='6px'
                points='3 91.35 3 70.91 95.43 3 95.43 91.61 51.93 91.56 52.01 34.91 142.11 56.41 142.26 91.61' />
        </svg>
        <img src="/assets/icons/svg/zh_texto.svg" class="mt20" width="145px">
        <div class="mt20">Imagen no disponible</div>
    </div>
    <div class="capa-frontal">
        <ng-content></ng-content>
    </div>
</div>
