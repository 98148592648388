import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Router, ActivatedRoute } from '@angular/router';
import { bounceInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { Subscription, Observable, of, pipe } from 'rxjs';
import { delay, finalize } from 'rxjs/operators';
import { InmuebleDTO, InmuebleService, ResultadoConsultaAgrupada } from 'src/app/inmuebles/inmueble.service';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { SeoService } from 'src/app/utils/seo.service';
import { ArrayUtil, FormatUtil, GeoUtils, WordUtils } from 'src/app/utils/utils';
import { MapComponent, MapLocation, MapMarker, MarkerIcon } from 'src/app/utils/zona-map/map-component/map.component';
import { BusquedaDTO, FiltrosBusquedaAvanzadaComponent } from '../../busqueda-avanzada/filtros/filtros.component';
import { FavoritesService, FavoriteState } from '../../favorites/favorites.service';
import { AuthService, UserInfo } from 'src/app/auth/auth.service';
import { GeoService, RegionDTO } from '../../zona-geo/geo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QueryParams } from '../../../utils/query-params.service';
import { catchError, map } from 'rxjs/operators';
import { RestQueryBuilder } from '../../../utils/rest.utils';
import * as L from 'leaflet';
import { AnalyticsService } from 'src/app/utils/analytics.service';
import { TraduccionService } from 'src/app/utils/traducciones/traducciones.services';


interface Banner {
  titulo: string
  texto: string
  vinculo: string
  accion?: string
}

@Component({
  selector: 'zona-busqueda-mapa',
  templateUrl: './busqueda-mapa.component.html',
  styleUrls: ['./busqueda-mapa.component.scss'],
  animations: [
    bounceInOnEnterAnimation(),
    fadeOutOnLeaveAnimation()
  ]
})
export class BusquedaMapaComponent extends AbstractComponent implements OnInit, AfterViewInit, OnDestroy {

  fullScreen = true;
  mostrarFiltros = false;
  _buscando = true
  set buscando(value: boolean) {
    const old = this._buscando
    this._buscando = value
    if (old != value) this.onBuscandoChanged()
  }
  get buscando(): boolean {
    return this._buscando
  }

  banners: Banner[] = [
    {
      titulo: "¿Tomaste la decisión de vender?",
      texto: "Descarga nuestro ebook y aprende a establecer el precio acertado de un inmueble para vender en menos tiempo.", vinculo: "/ebooks/venta-inmueble",
      accion: "Descargar"
    },
    {
      titulo: "Hazte estas 5 preguntas para encontrar tu inmueble ideal",
      texto: "Descarga nuestro ebook gratuito y conoce los factores a tener en cuenta para encontrar tu nueva vivienda.", vinculo: "/ebooks/5-preguntas",
      accion: "Descargar"
    },
    {
      titulo: "¿Que prefieres, comprar o arrendar una vivienda?", texto: "Descarga nuestro Ebook, conoce las ventajas y desventajas de cada modelo, y toma la mejor decisión.",
      vinculo: "/ebooks/venta-arriendo", accion: "Descargar"
    },
  ]
  banner: Banner;

  _cargandoVistaPrevia = false
  set cargandoVistaPrevia(value: boolean) {
    const old = this._cargandoVistaPrevia
    this._cargandoVistaPrevia = value
    if (old != value) this.onCargandoVistaPreviaChanged()
  }

  get cargandoVistaPrevia(): boolean {
    return this._cargandoVistaPrevia
  }

  busqueda: BusquedaDTO
  mensaje: { tipo, texto } = null
  ubicacionMapa: MapLocation = null

  private resultadosGeohash: Map<string, ResultadoGeohash> = new Map<string, ResultadoGeohash>()
  private resultadosAgrupados: Map<string, ResultadoGeoHashAgrupado> = new Map<string, ResultadoGeoHashAgrupado>()

  _capaResultadosAgrupados?: any
  _capaResultadosAltoNivel?: any

  private minZoomLevel = 15;
  private presicionGeohashGrupo = 5
  tipoResultado: 'agrupado' | 'individual' = 'individual'
  private actualizandoUbicacion = false
  private marcadorSeleccionado: MapMarker = null
  @ViewChild('listaResultados')
  private listaResultados: ElementRef

  @ViewChild(MapComponent)
  private map: MapComponent;

  @ViewChild(FiltrosBusquedaAvanzadaComponent)
  private filtros: FiltrosBusquedaAvanzadaComponent;

  private readonly mediaWatcher: Subscription;
  ///Lista lateral
  private listaVisible = false
  tamPaginaLista = 7
  totalPaginasLista = 0
  _inmueblesVisibles: Array<InmuebleDTO> = []
  _codigosInmueblesVisibles = []
  paginaActualLista = -1
  cargandoInmueblesVisibles = false;
  vistaInicializada = false;
  usuario: UserInfo
  requiereActualizarVisibles = false
  editarPosicion: boolean = false

  adInmuebleConfig = {
    posicion: -1
  };

  get codigosInmueblesVisibles() {
    return this._codigosInmueblesVisibles
  }
  set codigosInmueblesVisibles(val: string[]) {
    this._codigosInmueblesVisibles = val
    this.actualizarPaginacionLista()
    this.cargarPaginaLista(1)
  }

  get inmueblesVisibles() {
    return this._inmueblesVisibles;
  }
  set inmueblesVisibles(inmuebles: Array<InmuebleDTO>) {
    this.adInmuebleConfig.posicion = (Math.floor(inmuebles.length / 2) - 1);
    this._inmueblesVisibles = inmuebles;
  }


  get capaResultadosAgregados() {
    if (!this._capaResultadosAgrupados) {
      this._capaResultadosAgrupados = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          // iterate all markers and count
          var markers = cluster.getAllChildMarkers();
          var weight = 0;
          for (var i = 0; i < markers.length; i++) {
            if (markers[i].options.hasOwnProperty("customWeight")) {
              weight += markers[i].options['customWeight'];
            }
          }
          // create the icon with the "weight" count, instead of marker count
          return L.divIcon({
            html: '<div class="weight-marker weight-marker-cluster"><span>' + weight + '</span></div>',
            className: 'marker-cluster testcluster marker-cluster-small marker-cluster-zona'
          });
        }
      });
      this.map.addLayer(this._capaResultadosAgrupados)
    }
    return this._capaResultadosAgrupados
  }


  busquedaPais = {
    "ar": "/busqueda/mapa/apartamentos/venta/REGION_51400-capital-federal",
    "cl": "/busqueda/mapa/apartamentos/venta/REGION_55942-providencia",
    "co": "/busqueda/mapa/apartamentos/venta/MPIO_11001-Bogota",
    "pe": "/busqueda/mapa/apartamentos/venta/REGION_55993-lima",
    "ec": "/busqueda/mapa/apartamentos/venta/REGION_56230-quito",
    "mx": "/busqueda/mapa/apartamentos/venta/REGION_110531-ciudad-mexico"
  }

  codigoPais: string = 'co'
  subscripcionLenaguaje: Subscription = undefined

  constructor(private inmuebles: InmuebleService, private cdr: ChangeDetectorRef, auth: AuthService, private geo: GeoService,
    private snackbar: MatSnackBar, private analytics: AnalyticsService,
    private router: Router, private seo: SeoService, media: MediaObserver, private favoritos: FavoritesService,
    private route: ActivatedRoute, traduccion: TraduccionService
  ) {
    super()
    const lenguaje = traduccion.getLenguajeActual()
    this.codigoPais = lenguaje.codigoPais
    this.subscripcionLenaguaje = traduccion.observarLenguajeActual().subscribe(c => {
      this.codigoPais = c.codigoPais
      const url = this.busquedaPais[c.codigoPais]
      router.navigate([url])
      this.filtros.actualizar(url)
    })
    this.mediaWatcher = media.asObservable().subscribe((change: MediaChange[]) => {
      const ultimo = change[0]
      this.listaVisible = ultimo.mqAlias !== 'xs' && ultimo.mqAlias !== 'sm'
      if (this.vistaInicializada) this.actualizarVisibles()
    });
    this.usuario = auth.userInfo
    auth.userChange.subscribe(u => this.usuario = u)
    this.actualizarBanner();
  }

  actualizarBanner() {
    this.banner = this.banners[Math.round(Math.random() * this.banners.length)]
  }

  onCargandoVistaPreviaChanged() {
    this.actualizarBloqueoMapa()
  }

  onBuscandoChanged() {
    this.actualizarBloqueoMapa()
  }

  private actualizarBloqueoMapa() {
    if (this.map) this.map.locked = this.buscando && !this.cargandoVistaPrevia
  }

  ngOnDestroy(): void {
    this.mediaWatcher.unsubscribe();
    this.subscripcionLenaguaje.unsubscribe();
  }


  ubicarRegion() {
    const center = this.map.bounds.getCenter()
    const id = this.busqueda.region.id
    this.geo.ubicarRegion(id, center.lat, center.lng).subscribe(() => {
      this.snackbar.open(`Nueva ubicación almacenada`, null, { duration: 3000 })
    }, e => {
      this.snackbar.open(`No se pudo actualizar la ubicación`, null, { duration: 3000 })
    })
  }

  limpiarResultadosIndividuales() {
    this.map.clearMarkers()
    this.resultadosGeohash.clear()
  }

  limpiarResultadosAgrupados() {
    this.capaResultadosAgregados.clearLayers()
    this.resultadosAgrupados.clear()
  }

  buscar(evt: BusquedaDTO) {
    this.limpiarResultadosIndividuales()
    this.limpiarResultadosAgrupados()
    this.mostrarFiltros = false
    this.busqueda = evt
    this.seo.setSEOInfo(this.busqueda.titulo, this.busqueda.titulo)
    this.actualizarBusqueda(true)
  }

  mapMoved(location: MapLocation) {
    if (this.actualizandoUbicacion || this.map == null) return
    this.mostrarFiltros = false
    this.ubicacionMapa = location
    this.actualizarBusqueda(false)
  }

  actualizarVisibles() {
    if (!this.listaVisible || !this.requiereActualizarVisibles) return
    this.requiereActualizarVisibles = false
    if (this.tipoResultado == 'agrupado') {
      this.consultaListado();
      return
    }
    const boxes = this.map.getGeoHashBoxes(6).map(h => this.getResultadoIndividual(h))
    const cargando = boxes.find(b => !b.cargado)
    if (cargando) return
    let inmuebles: any[] = boxes.reduce((list, box) => [...list, ...box.inmuebles.map(i => i.inmueble)], [])

    const bounds = this.map.bounds
    inmuebles = inmuebles.filter(i => bounds.contains([i.lat, i.lng]))
    const favoritos = inmuebles.reduce((p, i) => ({ ...p, [i.id]: this.favoritos.obtenerEstadoElemento(i.id) }), {})

    inmuebles = inmuebles.sort((a, b) => {
      const aFav = favoritos[a.id]
      const bFav = favoritos[b.id]
      if (aFav === bFav) return 0
      if (aFav === FavoriteState.UNKNOWN) {
        return -1
      }
      if (aFav === FavoriteState.DISLIKE) {
        return bFav === FavoriteState.UNKNOWN ? -1 : 1
      }
      if (aFav === FavoriteState.LIKE) {
        if (bFav === FavoriteState.NOTHING) return 1
        return -1
      }
      if (aFav === FavoriteState.NOTHING) {
        return -1
      }
      return 0
    })
    const codigos = inmuebles.map(i => i.codigo)
    if (ArrayUtil.equals(this.codigosInmueblesVisibles, codigos)) {
      return
    }
    if (codigos.length < 1) {
      this.consultaListado();
    } else {
      //busqueda ya desarrollada
      this.codigosInmueblesVisibles = codigos
      this.cargarPaginaLista(1)
    }

  }

  actualizarPaginacionLista() {
    this.paginaActualLista = 1
    this.totalPaginasLista = Math.ceil(this.codigosInmueblesVisibles.length / this.tamPaginaLista)
  }

  cargarPaginaLista(pagina: number) {
    //if(pagina === this.paginaActualLista) return
    this.paginaActualLista = pagina
    const start = (pagina - 1) * this.tamPaginaLista
    const idsPagina = this.codigosInmueblesVisibles.slice(start, start + this.tamPaginaLista)
    this.inmueblesVisibles = []
    this.cargandoInmueblesVisibles = true
    this.actualizarBanner()
    this.inmuebles.obtenerVistaPrevia(idsPagina).pipe(delay(0))
      .subscribe(r => {
        this.inmueblesVisibles = Array.from(r.values())
        this.cargandoInmueblesVisibles = false
        this.listaResultados.nativeElement.scrollTop = 0
        this.cdr.detectChanges()
      })
  }

  mostrarEnLista() {
    const { location } = window
    const ruta = location.pathname.replace("/mapa/", "/lista/") + location.search
    this.router.navigateByUrl(ruta)
  }

  markerClick(marker: MapMarker) {

    if (marker['previewReady'] !== true) {
      this.cargandoVistaPrevia = true
      marker.component.bindPopup(this.getPopupCarga())
      marker.component.openPopup()
      this.cargarVistaPrevia(marker);

    }

    this.marcadorSeleccionado = marker
    const bounds = this.map.bounds
    const delta = ((bounds.getSouth() - bounds.getNorth()) * 0.25)
    this.map.setMapLocation(marker.lat - delta, marker.lng)
  }

  @HostListener("window:cambiarEstadoOculto")
  cambiarEstadoOculto() {

  }

  @HostListener("window:cambiarEstadoFavorito")
  cambiarEstadoFavorito() {

    const { id } = this.marcadorSeleccionado.data

  }

  @HostListener("window:editarPosicionInmueble")
  editarPosicionInmueble() {

    this.editarPosicion = true
    const { id } = this.marcadorSeleccionado.data
  }

  mapClick(event) {
    const { latlng } = event
    const { codigo } = this.marcadorSeleccionado.data
    const marker = this.marcadorSeleccionado.component

    this.snackbar.open(`Editando posición de inmueble`, null, { duration: 1000 })
    this.inmuebles.editarPosicionInmueble(codigo, latlng.lat, latlng.lng).subscribe(r => {
      this.snackbar.open(`Se ha editado la posición`, null, { duration: 1000 })
      marker.setLatLng([r.lat, r.lng])
    }, e => {
      this.handleError(e)
      this.snackbar.open(`No se pudo editar la posición`, null, { duration: 2000 })
    })
  }



  private cargarVistaPrevia(marker: MapMarker) {
    const { codigo } = marker.data
    this.inmuebles.obtenerVistaPrevia([codigo])
      .pipe(finalize(() => this.cargandoVistaPrevia = false))
      .subscribe(inmuebles => {
        const inmueble = inmuebles.get(codigo)
        const html = this.crearPrevisalizacion(inmueble);

        marker.component.bindPopup(html)

        marker['previewReady'] = true;
        var btnFav = L.DomUtil.get("btn-popup-fav-" + codigo);
        L.DomEvent.addListener(btnFav, 'click', L.DomEvent.stop);
        L.DomEvent.addListener(btnFav, "click", (e) => {
          this.runOnNgZone(() => {
            var resultado = this.favoritos.cambiarEstadoFavorito(codigo);
          });
        });

        var btnHidden = L.DomUtil.get("btn-popup-hidden-" + codigo);
        L.DomEvent.addListener(btnHidden, 'click', L.DomEvent.stop);
        L.DomEvent.addListener(btnHidden, 'click', (e) => {
          this.runOnNgZone(() => {
            this.favoritos.cambiarEstadoOculto(codigo);
          });
        })
        this.favoritos.observar(codigo).subscribe((estado) => {
          if (estado === FavoriteState.LIKE) {
            btnFav.classList.remove("favorito-desactivado");
            btnFav.classList.add("favorito-activado");
            btnHidden.getElementsByTagName("i")[0].classList.remove("fa-eye");
            btnHidden.getElementsByTagName("i")[0].classList.add("fa-eye-slash");
          } else if (estado == FavoriteState.DISLIKE) {
            btnHidden.getElementsByTagName("i")[0].classList.remove("fa-eye-slash");
            btnHidden.getElementsByTagName("i")[0].classList.add("fa-eye");
            btnFav.classList.add("favorito-desactivado");
            btnFav.classList.remove("favorito-activado");
          } else if (estado == FavoriteState.NOTHING) {
            btnFav.classList.add("favorito-desactivado");
            btnFav.classList.remove("favorito-activado");
            btnHidden.getElementsByTagName("i")[0].classList.remove("fa-eye");
            btnHidden.getElementsByTagName("i")[0].classList.add("fa-eye-slash");
          }
        });
      }, e => this.logE(`Error cargando mapa`, e)) //TODO: Manejar errores en carga de resultados
  }

  private crearPrevisalizacion(inmueble: any): string {
    const { habitaciones, parqueaderos = 0, banos = 0, areaTotal, areaConstruida } = inmueble.caracteristicas
    const imagen = inmueble.imagenes && inmueble.imagenes.length > 0 ? inmueble.imagenes[0] : ""
    const { venta, arriendo } = inmueble.precios
    let negocio = this.busqueda?.negocio || 'venta'
    //http://embed.plnkr.co/8qVoW5/
    const precioVenta = FormatUtil.moneda(venta ? venta.valor : null)
    const precioArriendo = FormatUtil.moneda(arriendo ? arriendo.valor : null)
    const html = `
      <div class="vista-previa">
        <div class="imagen" style="background-image:url('${imagen}'),url('/assets/images/preview_img_no_disponible.png')">
          <div class="etiquetas" >
            <span class="etiqueta tipo">${WordUtils.capitalize(inmueble.tipoInmueble)}</span>
            ${inmueble.arriendo ? '<span class="etiqueta negocio">Arriendo: ' + precioArriendo + ' </span>' : ''}
            ${inmueble.venta ? '<span class="etiqueta negocio">Venta: ' + precioVenta + ' </span>' : ''}
          </div>
          <div class="body-popup">
          </div>
          <div class="footer-popup">
            <div class="acciones-popup">
             <button mat-icon-button=""  id="btn-popup-fav-${inmueble.codigo}"  class="${this.favoritos.isFavorito(inmueble.codigo) ? 'favorito-activado' : 'favorito-desactivado'} mat-focus-indicator mat-tooltip-trigger favorito mat-icon-button mat-button-base ng-star-inserted" ng-reflect-message="Quitar Favorito" aria-describedby="cdk-describedby-message-25" cdk-describedby-host="">
                <span class="mat-button-wrapper">
                <i  class="fas fa-heart zona-text-lg" aria-hidden="true"></i>
                </span>
                <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]">
                </span>
                <span class="mat-button-focus-overlay">
                </span>
             </button>
             <button mat-icon-button="" id="btn-popup-hidden-${inmueble.codigo}" mattooltip="Mostrar" class="mat-focus-indicator mat-tooltip-trigger mat-icon-button mat-button-base ng-star-inserted" style="color: white;" ng-reflect-message="Mostrar" aria-describedby="cdk-describedby-message-8" cdk-describedby-host="">
              <span class="mat-button-wrapper">
              <i  class="fal fa-eye${!this.favoritos.isOculto(inmueble.codigo) ? '-slash' : ''} zona-text-lg" aria-hidden="true"></i>
              </span>
              <span matripple="" class="mat-ripple mat-button-ripple mat-button-ripple-round" ng-reflect-disabled="false" ng-reflect-centered="true" ng-reflect-trigger="[object HTMLButtonElement]"></span>
              <span class="mat-button-focus-overlay"></span>
             </button>

            </div>
          </div>
        </div>
        <h4 id="accion-popup">${inmueble.titulo}</h4>
        <div class="caracteristicas">
          ${this.caracteristicaHtml("fas fa-door-closed", "Hab", habitaciones || "--")}
          ${this.caracteristicaHtml("fas fa-bath", "Baños", banos || "--")}
          ${this.caracteristicaHtml("fas fa-parking", "Parq", parqueaderos || "--")}
          ${this.caracteristicaHtml("fas fa-ruler-combined", "Área", `${areaTotal || areaConstruida || "--"}m²`)}
        </div>
      </div>
      <div class="acciones" >
          ${this.usuario?.hasRole('admin') ? `<button class="mr5" onclick="window.dispatchEvent(new CustomEvent('editarPosicionInmueble'))">EDITAR POSICION</button>` : ''}
          <a href="/busqueda/inmueble/${inmueble.codigo}?origen=mapa&oferta=${negocio}" target="_blank"><span>VER DETALLE</span></a>
      </div>
    `
    //<button id="preview.${inmueble.codigo}.oculto" onclick="window.dispatchEvent(new CustomEvent('cambiarEstadoOculto'))">Ocultar</button>
    //<button id="preview.${inmueble.codigo}.favorito" onclick="window.dispatchEvent(new CustomEvent('cambiarEstadoFavorito'))">Favorito</button>

    return html
  }

  private caracteristicaHtml(icono: string, etiqueta: string, valor) {
    return `<div class="caracteristica">
    <i class="${icono}"></i>
    <div class="valor-wrapper">
      <div class="label">${etiqueta}</div>
      <div class="valor">${valor}</div>
    </div>
  </div>
    `
  }

  private getPopupCarga() {
    const iconos = [`fal fa-fan`, `fas fa-spinner`, `fas fa-cog`, "fas fa-compact-disc", "fas fa-atom"]
    const icono = iconos[Math.floor(Math.random() * iconos.length)]
    return `<div class="carga"><div class="titulo">Por favor espera...</div><div class="icono">
    <i class="${icono} fa-spin fa-2x"></i></div><div class="mensaje">Cargando vista previa</div></div>`
  }

  private actualizarBusqueda(moverMapa: boolean) {
    if (moverMapa) {
      this.actualizandoUbicacion = true
      const { centro: { lat, lng }, tipo } = this.busqueda.region
      const zoom = tipo == 'BARRIO' ? 16 : tipo == 'LOCALIDAD' || tipo == "UBICACION" || tipo == "MUNICIPIO" ? 14 : 12
      this.map.setMapLocation(lat, lng, zoom)
      this.tipoResultado = zoom > this.minZoomLevel ? 'individual' : 'agrupado'
      this.requiereActualizarVisibles = true
      this.actualizarVisibles()
      this.actualizandoUbicacion = false
    }
    if (this.validarBusqueda()) {
      this.registrarBusqueda()
      this.realizarBusqueda(moverMapa)
      if (this.tipoResultado == 'individual') {
        this.requiereActualizarVisibles = true
        this.actualizarVisibles()
      }
    }
  }

  private registrarBusqueda() {
    const busqueda = this.busqueda
    const q = `tipo=mapa&tipoInmueble=${busqueda?.tipoInmueble}&negocio=${busqueda?.negocio}&region=${busqueda?.region?.nombre}`
    const params = { tipoBusqueda: 'mapa', tipoInmuebleBusqueda: busqueda.tipoInmueble, negocioBusqueda: busqueda.negocio, regionBusqueda: busqueda?.region?.nombre, q }
    this.analytics.registerEvent('search', params)
  }

  private hacerBusquedaAgrupada() {
    const presicionGeohash = this.ubicacionMapa.zoom < 11 ? 4 : 5
    if (presicionGeohash != this.presicionGeohashGrupo) {
      this.limpiarResultadosAgrupados()
    }
    this.presicionGeohashGrupo = presicionGeohash

    const geohashes = this.map.getGeoHashBoxes(presicionGeohash).map(g => this.getResultadoAgrupado(g)).filter(g => !g.cargado && !g.cargando)
    if (geohashes.length == 0) return
    this.cargarResultadosAgrupados(geohashes, presicionGeohash)
  }

  private hacerBusquedaIndividual() {
    const geohashes = this.map.getGeoHashBoxes(6).map(h => this.getResultadoIndividual(h))
    geohashes.forEach(r => r.visitar())
    const cargar = geohashes.filter(r => !r.cargado && !r.cargando)
    if (cargar.length == 0) return
    this.cargarResultadosIndividuales(cargar)
  }


  private validarBusqueda(): boolean {
    if (!this.map || !this.ubicacionMapa || !this.busqueda) {
      this.logW("No se puede identificar la posición del mapa")
      return false
    }
    this.mensaje = null
    return true
  }


  private realizarBusqueda(moverMapa: boolean) {

    const tipoResultadoAnterior = this.tipoResultado
    this.tipoResultado = this.ubicacionMapa.zoom > this.minZoomLevel ? 'individual' : 'agrupado'
    const cambioTipoResultado = this.tipoResultado !== tipoResultadoAnterior
    if (cambioTipoResultado) {
      this.logD(`Cambiando tipo de resultado a ${this.tipoResultado}`)
      this.requiereActualizarVisibles = true
      this.actualizarVisibles()
      if (tipoResultadoAnterior == 'agrupado') this.limpiarResultadosAgrupados()
      else this.limpiarResultadosIndividuales()
    }
    if (this.tipoResultado == 'individual') this.requiereActualizarVisibles = true

    if (this.tipoResultado == 'agrupado') this.hacerBusquedaAgrupada()
    else this.hacerBusquedaIndividual()
  }


  private getResultadoAgrupado(geohash: string): ResultadoGeoHashAgrupado {
    let grupo = this.resultadosAgrupados.get(geohash)
    if (!grupo) {
      grupo = new ResultadoGeoHashAgrupado(geohash)
      this.resultadosAgrupados.set(geohash, grupo)
    }
    return grupo
  }

  private getResultadoIndividual(geohash: string): ResultadoGeohash {
    //TODO: inmuebles sin lat lon?
    let result = this.resultadosGeohash.get(geohash)
    if (!result) {
      result = new ResultadoGeohash(geohash)
      this.resultadosGeohash.set(geohash, result)
    }
    return result
  }

  /**
   * Se encarga de realizar la busqueda de numero de inmuebles para los clusters
   * @param zonas
   * @param longGeohashGrupo
   */
  private cargarResultadosAgrupados(zonas: ResultadoGeoHashAgrupado[], longGeohashGrupo: number) {
    zonas.forEach(z => z.cargando = true)
    this.logI(`Zoom: ${this.map.zoom} -> Cargando grupos  ${zonas.length}`)
    const geohashes = zonas.map(z => z.geohash)
    this.buscando = true;

    this.inmuebles.buscarMapaAgrupado(geohashes, this.busqueda).pipe(finalize(() => {
      this.buscando = false
      this.mensaje = null
    })).subscribe(resultados => {
      const indice = {}
      resultados.forEach(r => {
        const grupo = r.geohash.substr(0, longGeohashGrupo)
        let subresultado = indice[grupo]
        if (!subresultado) {
          subresultado = []
          indice[grupo] = subresultado
        }
        subresultado.push(r)
      })
      zonas.forEach(z => {
        z.subzonas = indice[z.geohash] || []
      })
      this.agregarResultadoAgrupadoMapa(zonas)
    }, e => this.handleError(e))
  }

  private agregarResultadoAgrupadoMapa(zonas: ResultadoGeoHashAgrupado[]) {

    var capa = this.capaResultadosAgregados
    zonas.forEach(z => {
      z.subzonas.forEach(r => {
        // const { lat, lng } = Geohash.decode(r.geohash)
        const { lat, lng } = GeoUtils.randomCenter(r.geohash)
         const marker = new WeightMarker([lat, lng], { customWeight: r.cantidad })
          marker.addEventListener("click", (e: any) => {
            let latlng = e.latlng;
            this.map.flyTo(latlng.lat, latlng.lng, 16)
          });
          capa.addLayer(marker);
      })
    })
  }

  private cargarResultadosIndividuales(zonas: ResultadoGeohash[]) {
    const hashes = zonas.map(z => z.geohash)
    zonas.forEach(z => z.cargando = true)
    this.buscando = true
    this.inmuebles.buscarMapa(hashes, this.busqueda)
      .pipe(finalize(() => {
        this.buscando = false; zonas.forEach(z => z.cargando = false)
        this.mensaje = null
      }))
      .subscribe(r => {
        r.forEach(i => {
          const { lat, lng, geohash } = i.ubicacion.visible
          const resultado = this.getResultadoIndividual(geohash)
          resultado.agregarPropiedad(i.codigo, lat, lng, this.editarPosicion)
        })
        zonas.forEach(z => z.cargado = true)
        zonas.forEach(z =>
          this.agregarResultadoIndividualMapa(z)
        )
        this.requiereActualizarVisibles = true
        this.actualizarVisibles();
      }, e => {
        this.handleError(e)
      })
  }

  private agregarResultadoIndividualMapa(resultado: ResultadoGeohash) {
    if (resultado.visible) return
    resultado.inmuebles.forEach(i => {
      this.favoritos.observar(i.inmueble.codigo).subscribe(estado => {

        if (estado === FavoriteState.LIKE) {
          i.marcador.color = MarkerIcon.FAVORITE;
        } else if (estado === FavoriteState.NOTHING) {
          i.marcador.color = MarkerIcon.NORMAL;
        } else {
          i.marcador.color = MarkerIcon.HIDDEN_SELECTED;
        }

      });

      this.map.addMarker(i.marcador)
    })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.vistaInicializada = true
    this.map.locked = this.buscando
  }

  toggleFiltros() {
    this.mostrarFiltros = !this.mostrarFiltros
    if (this.mostrarFiltros)
      this.filtros.reiniciar()
  }

  private consultaListado() {

    const [filtros, caracteristicas] = this.cargarFiltros(new QueryParams(this.route.snapshot.queryParams))
    this.cargandoInmueblesVisibles = true
    this.parsearDesdeRuta().subscribe(p => {
      const [tipo, negocio, region] = p
      const busqueda = new BusquedaDTO(tipo, negocio, region)
      busqueda.filtros = filtros

      let query = this.crearConsulta(negocio, tipo, region, filtros, caracteristicas);

      query.pageNumber(1).pageSize(11);

      this.inmuebles.buscar(query.build())
        .pipe(finalize(() => {
          this.cargandoInmueblesVisibles = false
        }))
        .subscribe(
          r => {
            let inmueblesVisibles = Array.from(r.content.values());
            this.codigosInmueblesVisibles = inmueblesVisibles.map(i => i.codigo);
            this.inmueblesVisibles = inmueblesVisibles;
            this.listaResultados.nativeElement.scrollTop = 0
            this.cdr.detectChanges()
          },
          e => this.handleError(e)
        );
    });
  }

  private cargarFiltros(params: QueryParams): any {
    let filters: any = {};
    let priceStartingAt = params.getInteger("f.precioDesde");
    if (priceStartingAt) {
      filters['precioDesde'] = parseInt(priceStartingAt.toString());
    }
    let priceEndingAt = params.getInteger("f.precioHasta");
    if (priceEndingAt) {
      filters['precioHasta'] = parseInt(priceEndingAt.toString());
    }
    let bathFilter = params.getString("f.banos");
    if (bathFilter && bathFilter.match(/^[1-5]*$/g)) {
      filters['banos'] = parseInt(bathFilter);
    }
    let roomsFilter = params.getString("f.habitaciones");
    if (roomsFilter && roomsFilter.match(/^[1-5]*$/g)) {
      filters['habitaciones'] = parseInt(roomsFilter);
    }
    let parkingFilter = params.getString("f.parqueaderos");
    if (parkingFilter && parkingFilter.match(/^[1-5]*$/g)) {
      filters['parqueaderos'] = parseInt(parkingFilter);
    }
    let filtroEstado = params.getString("f.estado");
    if (filtroEstado && filtroEstado.match(/^nuevo|usado$/)) {
      filters['estado'] = filtroEstado;
    }
    let filtroCertificado = params.getString("f.certificado");
    if(filtroCertificado && filtroCertificado.match(/^true|false$/)){
      filters['certificado'] = filtroCertificado === 'true' ? true : false;
    }
    const caracteristicasStr = params.getString("f.caracteristicas")
    let caracteristicas = []
    if (caracteristicasStr != null && caracteristicasStr.length > 0) {
      caracteristicas = caracteristicasStr.split("-").map(c => c.trim())
    }
    return [filters, caracteristicas];
  }

  private parsearDesdeRuta(): Observable<any[]> {
    let parts: string[] = window.location.pathname.split('/').filter(s => s.length > 0);
    let tipo = "apartamento"
    let negocio = "arriendo"
    let region = FiltrosBusquedaAvanzadaComponent.DEFAULT_REGION


    if (parts.length > 2) {
      tipo = parts[2].toLowerCase();
      tipo = tipo.endsWith("s") ? tipo.substring(0, tipo.length - 1) : tipo
    }

    if (parts.length > 3) {
      negocio = parts[3].toLowerCase();
    }

    if (parts.length > 4) {
      let regionStr = parts[4].toLocaleLowerCase();
      return this.buscarRegion(regionStr).pipe(map(r => [tipo, negocio, r]))
    }
    return of([tipo, negocio, region])
  }

  private buscarRegion(region: string): Observable<RegionDTO> {
    const matcher = region.match(FiltrosBusquedaAvanzadaComponent.regionRegex)
    if (!matcher) {
      return of(FiltrosBusquedaAvanzadaComponent.DEFAULT_REGION)
    }
    const id = matcher[1]
    return this.geo.buscarRegionPorId(id).pipe(
      catchError(e => {
        this.logE(`Error leyendo region`, e);
        return of(FiltrosBusquedaAvanzadaComponent.DEFAULT_REGION);
      })
    );
  }

  ubicarEnMapa(inmueble: InmuebleDTO) {
    const ubicacion = inmueble?.ubicacion?.visible
    if (ubicacion)
      this.map.flyTo(ubicacion.lat, ubicacion.lng, 17)
  }

  crearConsulta(negocio, tipoInmueble, region, filtros, caracteristicas): RestQueryBuilder {
    let query = new RestQueryBuilder().eq('activo', true)
      .eq(negocio, true)
      .eq('tipoInmueble', tipoInmueble).countElements(true)
      .select('titulo', 'relevancia', 'codigo', 'caracteristicas.habitaciones', 'precios', 'caracteristicas.banos',
        'caracteristicas.areaConstruida', 'ubicacion.regiones', 'ubicacion.visible', 'imagenes', 'tipoInmueble', 'arriendo', 'venta')
    query.sortBy("relevancia");

    const tipo = region.tipo.toLowerCase();
    if (tipo === "ciudad" || tipo === "municipio") {
      query.eq("ubicacion.regiones.ciudad.id", region.id)
    } else if (tipo === "barrio") {
      query.eq("ubicacion.regiones.barrio.id", region.id)
    } else if (tipo === "localidad") {
      query.eq("ubicacion.regiones.localidad.id", region.id)
    } else if (tipo === "sector") {
      query.eq("ubicacion.regiones.sector.id", region.id)
    } else if (tipo == "estado") {
      query.eq("ubicacion.regiones.estado.id", region.id)
    } else if (tipo == "ubicacion") {
      query.eq("ubicacion.regiones.ubicacion.id", region.id)
    }

    if (filtros.banos != null) {
      query.eq('caracteristicas.banos', filtros.banos)
    }
    if (filtros.habitaciones != null) {
      query.eq('caracteristicas.habitaciones', filtros.habitaciones)
    }
    if (filtros.parqueaderos != null) {
      query.eq('caracteristicas.parqueaderos', filtros.parqueaderos)
    }
    if (filtros.estado) {
      query.eq('nuevo', filtros.estado === 'nuevo')
    }
    for (let c of caracteristicas) {
      query.eq(`caracteristicas.${c}`, 1)
    }

    if (filtros.precioDesde != null) {
      query.gte(`precios.${negocio}.valor`, filtros.precioDesde)
    }
    if (filtros.precioHasta != null) {
      query.lte(`precios.${negocio}.valor`, filtros.precioHasta)
    }
    //se filtra por certificado
    if (filtros.certificado) {
      query.exists('inventario.calificacion',true)
    }
    return query
  }
}

class ResultadoGeoHashAgrupado {
  cargando = false
  cargado = false
  visible = false
  subzonas: ResultadoConsultaAgrupada[] = []
  constructor(public readonly geohash) {
  }
}


class ResultadoGeohash {
  cargando = false
  cargado = false
  visible = false
  inmuebles: Array<ResultadoInmueble> = []
  visitas = 1
  constructor(public readonly geohash) {
  }

  agregarPropiedad(codigo: string, lat: number, lng: number, draggable: boolean = false, markerIcon: any = MarkerIcon.NORMAL) {
    let marcador = new MapMarker(1, lat, lng, codigo, { codigo }, markerIcon);
    marcador.draggable = draggable
    this.inmuebles.push({ inmueble: { codigo, lat, lng }, marcador })
  }



  visitar() {
    this.visitas++
  }
}

interface ResultadoInmueble {
  inmueble: { codigo, lat, lng }
  marcador: MapMarker
}

export class WeightMarker extends L.Marker {
  constructor(latlng: L.LatLngExpression, options: any) {
    const icon = L.divIcon({
      html: '<div class="weight-marker"><span>' + options['customWeight'] + '</span></div>',
      className: 'marker-cluster marker-cluster-small marker-cluster-zona'
    })
    super(latlng, { ...options, icon })
  }
}
