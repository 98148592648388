import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { ZonaPanelBusquedaModule } from '../busqueda/zona-panel-busqueda/zona-panel-busqueda.module';
import { ZonaCommonModule } from '../common/zona-common.module';
import { ZonaLayoutModule } from '../utils/zona-layout/zona-layout.module';
import { ZonaSeccionModule } from '../utils/zona-layout/zona-seccion/zona-seccion.module';
import { IndexComponent } from './index/index.component';
import { ToolsComponent } from './tools/tools.component';
import { NotFountComponent } from './not-fount/not-fount.component';
import { TranslateModule } from '@ngx-translate/core';
import { InmueblesDestactadosModule } from '../busqueda/inmuebles-destactados/inmuebles-destactados.module';



@NgModule({
  declarations: [
    IndexComponent,
    ToolsComponent,
    NotFountComponent
  ],
  exports: [
    ToolsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatListModule,
    FlexLayoutModule,
    MatExpansionModule,
    ZonaCommonModule,
    ZonaPanelBusquedaModule,
    ZonaLayoutModule,
    ZonaSeccionModule,
    TranslateModule,
    InmueblesDestactadosModule
  ]
})
export class HomeModule { }
