export const GRUPOS_CARACTERISTICA = [
    {
        clave: "general",
        nombre: "General",
        caracteristicas: [
            { etiqueta: "Habitaciones", descripcion: "", tipo: "int", clave: "habitaciones" },
            { etiqueta: "Baños", descripcion: "", tipo: "int", clave: "banos" },
            { etiqueta: "Parqueaderos", descripcion: "", tipo: "int", clave: "parqueaderos" },
            { etiqueta: "Sala - Comedor", descripcion: "", tipo: "int", clave: "salaComedor" },
            { etiqueta: "Cuarto de servicio", descripcion: "", tipo: "int", clave: "cuartoServicio" },
            { etiqueta: "Estudio", descripcion: "", tipo: "int", clave: "estudio" },
            { etiqueta: "Closet", descripcion: "", tipo: "int", clave: "closet" },
            { etiqueta: "Altillo", descripcion: "", tipo: "bool", clave: "altillo" },
            { etiqueta: "Lavandería", descripcion: "", tipo: "bool", clave: "lavanderia" },
            { etiqueta: "Chimenea", descripcion: "", tipo: "bool", clave: "chimenea" },
            { etiqueta: "Zona de Ropas", descripcion: "", tipo: "bool", clave: "zonaRopas" },
            { etiqueta: "Antejardín", descripcion: "", tipo: "bool", clave: "antejardin" },
            { etiqueta: "Patio", descripcion: "", tipo: "bool", clave: "patio" },
            { etiqueta: "Depósito", descripcion: "", tipo: "bool", clave: "deposito" },
        ]
    },
    {
        clave: "cocina",
        nombre: "Cocina",
        icono: 'fas fa-cookie-bite',
        caracteristicas: [
            { etiqueta: "Cocina integral", descripcion: "", tipo: "bool", clave: "cocinaIntegral" },
            { etiqueta: "Cocina Americana", descripcion: "", tipo: "bool", clave: "cocinaAmericana" },
            { etiqueta: "Cocina semiintegral", descripcion: "", tipo: "bool", clave: "cocinaSemiIntegral" },
            { etiqueta: "Gas Natural", descripcion: "", tipo: "bool", clave: "gasNatural" },
            { etiqueta: "Gas Propano", descripcion: "", tipo: "bool", clave: "gasPropano" },
            { etiqueta: "Horno a gas", descripcion: "", tipo: "bool", clave: "hornoGas" },
            { etiqueta: "Horno eléctrico", descripcion: "", tipo: "bool", clave: "hornoElectrico" },
            { etiqueta: "Despensa", descripcion: "", tipo: "bool", clave: "despensa" },
            { etiqueta: "Barra américana", descripcion: "", tipo: "bool", clave: "barraAmericana" },
            { etiqueta: "Cocineta", descripcion: "", tipo: "bool", clave: "cocineta" },
            { etiqueta: "Comedor auxiliar", descripcion: "", tipo: "bool", clave: "comedorAuxiliar" },
        ]
    },
    {
        clave: "bano",
        nombre: "Baño",
        icono: "fas fa-bath",
        caracteristicas: [
            { etiqueta: "Baño cabinado", descripcion: "", tipo: "bool", clave: "banoCabinado" },
            { etiqueta: "Tina", descripcion: "", tipo: "bool", clave: "tina" },
            { etiqueta: "Vestier", descripcion: "", tipo: "bool", clave: "vestier" },
            { etiqueta: "Calentador a Gas", descripcion: "", tipo: "bool", clave: "calentadorGas" },
            { etiqueta: "Calentador Eléctrico", descripcion: "", tipo: "bool", clave: "calentadorElectrico" },
            { etiqueta: "Calentador de paso", descripcion: "", tipo: "bool", clave: "calentadorPaso" },
            { etiqueta: "Calentador de acomulación", descripcion: "", tipo: "bool", clave: "calentadorAcumulacion" },
            { etiqueta: "Baño de servicio", descripcion: "", tipo: "int", clave: "banoServicio" },
        ]
    },
    {
        clave: "aclimatacion",
        nombre: "Aclimatación",
        icono: "fas fa-temperature-low",
        caracteristicas: [
            { etiqueta: "Aire acondicionado", descripcion: "", tipo: "bool", clave: "aireAcondicionado" },
            { etiqueta: "Mini Split", descripcion: "Pequeño sistema de control de clima que permite calentar o enfriar habitaciones", tipo: "bool", clave: "miniSplit" },
            { etiqueta: "Mezzannine", descripcion: "Pisos intermedios que permiten la vista a los pisos inferiores", tipo: "bool", clave: "mezannine" },
        ]
    },
    {
        clave: "entretenimiento",
        nombre: "Entretenimiento",
        icono: "far fa-futbol",
        caracteristicas: [
            { etiqueta: "Parque", descripcion: "", tipo: "bool", clave: "parque" },
            { etiqueta: "Parque Infantil", descripcion: "", tipo: "bool", clave: "parqueInfantil" },
            { etiqueta: "Cancha de tenis", descripcion: "", tipo: "bool", clave: "canchaTennis" },
            { etiqueta: "Cancha de fútbol", descripcion: "", tipo: "bool", clave: "canchaFutbol" },
            { etiqueta: "Cancha de baloncesto", descripcion: "", tipo: "bool", clave: "canchaBaloncesto" },
            { etiqueta: "Auditorio", descripcion: "", tipo: "bool", clave: "auditorio" },
            { etiqueta: "Sala de juntas", descripcion: "", tipo: "bool", clave: "salaJuntas" },
            { etiqueta: "Club House", descripcion: "", tipo: "bool", clave: "clubHouse" },
            { etiqueta: "Jaula de Golf", descripcion: "", tipo: "bool", clave: "jaulaGolf" },
            { etiqueta: "Kiosko", descripcion: "", tipo: "bool", clave: "kiosco" },
            { etiqueta: "Zona de Camping", descripcion: "", tipo: "bool", clave: "zonaCamping" },
            { etiqueta: "Terraza Comunal", descripcion: "", tipo: "bool", clave: "terrazaComunal" },
        ]
    },

    {
        clave: "zona",
        nombre: "Zona",
        icono: "fas fa-shopping-cart",
        caracteristicas: [
            { etiqueta: "Zonas Comercial", descripcion: "", tipo: "bool", clave: "zonaComercial" },
            { etiqueta: "Zona Residencial", descripcion: "", tipo: "bool", clave: "zonaResidencial" },
            { etiqueta: "Zona Industrial", descripcion: "", tipo: "bool", clave: "zonaIndustrial" },
            { etiqueta: "Zona Rural", descripcion: "", tipo: "bool", clave: "zonaRural" },
            { etiqueta: "Zona Financiera", descripcion: "", tipo: "bool", clave: "zonaFinanciera" },


            { etiqueta: "Estación de policía", descripcion: "", tipo: "bool", clave: "policia" },
            { etiqueta: "Hospitales", descripcion: "", tipo: "bool", clave: "hospitales" },
            { etiqueta: "Transporte", descripcion: "", tipo: "bool", clave: "transporte" },
            { etiqueta: "Vías principales", descripcion: "", tipo: "bool", clave: "viasPrincipales" },
            { etiqueta: "Museos", descripcion: "", tipo: "bool", clave: "museos" },
            { etiqueta: "Bibliotecas", descripcion: "", tipo: "bool", clave: "bibliotecas" },
            { etiqueta: "Sitios Turisticos", descripcion: "", tipo: "bool", clave: "sitiosTuristicos" },
            { etiqueta: "Guarnición", descripcion: "", tipo: "bool", clave: "guarnicion" },
            { etiqueta: "Gasolineras", descripcion: "", tipo: "bool", clave: "gasolineras" },
            { etiqueta: "Bomberos", descripcion: "", tipo: "bool", clave: "bomberos" },
            { etiqueta: "Centros comerciales", descripcion: "", tipo: "bool", clave: "centroComercial" },
            { etiqueta: "Vias Secundarias", descripcion: "", tipo: "bool", clave: "viasSecundarias" },
        ]
    },
    {
        clave: "construccion",
        nombre: "Construcción",
        icono: "far fa-building",
        caracteristicas: [
            { etiqueta: "Pent House", descripcion: "", tipo: "bool", clave: "pentHouse" },
            { etiqueta: "Edificio Inteligente", descripcion: "", tipo: "bool", clave: "edificioInteligente" },
            { etiqueta: "Rociadores", descripcion: "", tipo: "bool", clave: "rociadores" },
            { etiqueta: "Puerta blindada", descripcion: "", tipo: "bool", clave: "puertaBlindada" },
            { etiqueta: "Camaras de vigilancia", descripcion: "", tipo: "bool", clave: "camarasVigilancia" },
            { etiqueta: "Ascensor", descripcion: "", tipo: "bool", clave: "ascensor" },
            { etiqueta: "Escaleras de emergencia", descripcion: "", tipo: "bool", clave: "escalerasEmergencia" },
            { etiqueta: "Alarma de incendio", descripcion: "", tipo: "bool", clave: "alarmaIncendio" },
            { etiqueta: "Alarma perimetral", descripcion: "", tipo: "bool", clave: "alarmaPerimetral" },
            { etiqueta: "Ventilación natural", descripcion: "", tipo: "bool", clave: "ventilacionNatural" },
            { etiqueta: "Vista panorámica", descripcion: "", tipo: "bool", clave: "vistaPanoramica" },
        ]
    },
    {
        clave: "servicios",
        nombre: "Servicios",
        icono: "fas fa-concierge-bell",
        caracteristicas: [
            { etiqueta: "Recepción", descripcion: "", tipo: "bool", clave: "recepcion" },
            { etiqueta: "Shut de basuras", descripcion: "", tipo: "bool", clave: "shutBasuras" },
            { etiqueta: "Portería", descripcion: "", tipo: "bool", clave: "porteria" },
            { etiqueta: "Parqueadero comunal", descripcion: "", tipo: "bool", clave: "parqueaderoComunal" },
            { etiqueta: "Parqueadero de visitantes", descripcion: "", tipo: "bool", clave: "parqueaderoVisitantes" },
            { etiqueta: "Gimnasio", descripcion: "", tipo: "bool", clave: "gimnasio" },
            { etiqueta: "Piscina", descripcion: "", tipo: "bool", clave: "piscina" },
            { etiqueta: "Zonas Verdes", descripcion: "", tipo: "bool", clave: "zonasVerdes" },
            { etiqueta: "Salón comunal", descripcion: "", tipo: "bool", clave: "salonComunal" },
            { etiqueta: "Guardería", descripcion: "", tipo: "bool", clave: "guarderia" },
            { etiqueta: "BBQ", descripcion: "", tipo: "bool", clave: "bbq" },
            { etiqueta: "Turco", descripcion: "", tipo: "bool", clave: "turco" },
            { etiqueta: "Solarium", descripcion: "", tipo: "bool", clave: "solarium" },
            { etiqueta: "Sauna", descripcion: "", tipo: "bool", clave: "sauna" },
            { etiqueta: "Jacuzzi", descripcion: "", tipo: "bool", clave: "jacuzzi" },
            { etiqueta: "Corrales", descripcion: "", tipo: "bool", clave: "corrales" },
            { etiqueta: "Cafetería", descripcion: "", tipo: "bool", clave: "cafeteria" },
        ]
    },
    {
        clave: "destinacion",
        nombre: "Destinación",
        icono: "fas fa-file-invoice-dollar",
        caracteristicas: [
            { etiqueta: "Control Ambiental", descripcion: "", tipo: "bool", clave: "controlAmbiental" },
            { etiqueta: "Destinación espécifica", descripcion: "", tipo: "bool", clave: "destinacionEspecifica" },
            { etiqueta: "Dotacional", descripcion: "", tipo: "bool", clave: "dotacional" },
            { etiqueta: "Minería", descripcion: "", tipo: "bool", clave: "mineria" },
            { etiqueta: "Para Lotear", descripcion: "", tipo: "bool", clave: "paraLotear" },
        ]
    },
    /* {
        nombre: "General",
        caracteristicas: [
            { etiqueta: "Habitaciones", descripcion: "", tipo: "int", clave: "cuarto" },
            { etiqueta: "Baños", descripcion: "", tipo: "int", clave: "bano" },
            { etiqueta: "Cocinas", descripcion: "", tipo: "int", clave: "cocina" },
            { etiqueta: "Estrato", descripcion: "", tipo: "bool", clave: "estrato" },
            { etiqueta: "Área construida", descripcion: "", tipo: "bool", clave: "areaConstruida" },
            { etiqueta: "Área Total", descripcion: "", tipo: "bool", clave: "areaTotal" },
            { etiqueta: "Parqueadero", descripcion: "", tipo: "int", clave: "parqueadero" },
            { etiqueta: "Cantidad de plantas", descripcion: "", tipo: "bool", clave: "cantPlantas" },
            { etiqueta: "Ubicado en el piso", descripcion: "", tipo: "bool", clave: "piso" },
            { etiqueta: "Precio Administración", descripcion: "", tipo: "bool", clave: "precioAdministración" },
            { etiqueta: "Valor del IVA", descripcion: "", tipo: "bool", clave: "valorIva" },
            { etiqueta: "Niveles", descripcion: "", tipo: "bool", clave: "niveles" },
        ]
    } */
]
