import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FavoriteState, FavoritesService } from '../../favorites/favorites.service';

@Component({
  selector: 'zona-floating-fav-inmuebles',
  templateUrl: './floating-fav-inmuebles.component.html',
  styleUrls: ['./floating-fav-inmuebles.component.scss']
})
export class FloatingFavInmueblesComponent implements OnInit {


  @Input()
  mostrarTexto:Boolean = true;

  likeButtonLiked:Boolean = false;

  nFavoritos:Number = 0;

  constructor(private router:Router,private favoritos:FavoritesService) {
    this.nFavoritos = this.favoritos.totalFavoritos();
  }

  ngOnInit(): void {
    this.favoritos.observarFavoritos().subscribe((objeto) => {

      console.log("float",objeto);
      this.nFavoritos = objeto.total;

      if (objeto.estado === FavoriteState.LIKE){
        this.likeButtonLiked  = true;
        setTimeout(function() {

          this.likeButtonLiked = false;
        }.bind(this), 1000);
      }
    });


  }

  abrirFavoritos()
  {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/busqueda/favoritos"])
    );
    window.open(url, '_blank');
  }

}
