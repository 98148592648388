import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'zona-blog-redirect',
  templateUrl: './blog-redirect.component.html',
  styleUrls: ['./blog-redirect.component.scss']
})
export class BlogRedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) {
    route.params.subscribe(p => {
      const id = `${Md5.hashStr(p.id).slice(0, 24)}-post`
      router.navigate(['revista', 'post', id], { queryParams: { origen: 'redirect' } })
    })
  }

  ngOnInit(): void {
  }

}
