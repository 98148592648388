import { Observable, Subject } from 'rxjs';
import { Traducciones, Lenguaje } from './traducciones';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: "root"
})
export class TraduccionService {

  private KEY_STORAGE:string = "zona@lngActual";
  private lenguajes:Lenguaje[];


  private lenguajeActual:Lenguaje;

  private observadoresLenguajeActual:Subject<Lenguaje>;

  constructor(private traductor:TranslateService)
  {
    this.lenguajes = Traducciones.getLenguajes();
    this.observadoresLenguajeActual = new Subject<Lenguaje>();
    this.lenguajeActual = Traducciones.getLenguajeDefault();
    this.inicializar();
  }

  private inicializar()
  {

    var lenguajeStorage = localStorage.getItem(this.KEY_STORAGE);


    try{
      if (lenguajeStorage) {
        var lenguajeObjectStorage = JSON.parse(lenguajeStorage) as Lenguaje;
        this.lenguajeActual = lenguajeObjectStorage;
      }
    }catch(e){
      console.log(e);
    }




    this.traductor.addLangs(this.lenguajes.map(function (lenguaje: Lenguaje) {
      return lenguaje.codigo
    }));
    this.traductor.setDefaultLang(this.lenguajeActual.codigo);
    this.establecerLenguajeActual(this.lenguajeActual.codigo);
    //const browserLang = this.translate.getBrowserLang();
    //this.translate.use(browserLang.match(/es_CO|es_AR/) ? browserLang : 'es_CO')
  }


  public establecerLenguajeActual(codigoLenguaje:string)
  {
    var lenguaje = this.lenguajes.filter(function(lenguaje:Lenguaje){
        return lenguaje.codigo === codigoLenguaje;
    });

    //console.log(lenguaje);
    this.traductor.use(codigoLenguaje);
    this.lenguajeActual = lenguaje[0];
    localStorage.setItem(this.KEY_STORAGE,JSON.stringify(this.lenguajeActual));
    this.observadoresLenguajeActual.next(this.lenguajeActual);
  }


  public getLenguajes()
  {
    return Traducciones.getLenguajes();
  }

  public getLenguajeActual():Lenguaje
  {
    return this.lenguajeActual;
  }

  public observarLenguajeActual():Observable<Lenguaje>
  {
    return this.observadoresLenguajeActual.asObservable();
  }


  public esCodigoPaisActual(codigoPais:string)
  {
    if(this.lenguajeActual){
      return this.lenguajeActual.codigoPais.toLowerCase() === codigoPais.toLowerCase();
    }
    return Traducciones.getLenguajeDefault().codigoPais.toLowerCase() === codigoPais.toLowerCase();
  }

  public getTraduccion(key:string,interpolacion?:Object)
  {
    return this.traductor.instant(key,interpolacion);
  }

  public getTraduccionSub(key: string, interpolacion?: Object): Observable<string|any>
  {
    return this.traductor.stream(key,interpolacion);
  }


}
