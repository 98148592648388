import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import Auth from '@aws-amplify/auth';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { AnalyticsService } from "src/app/utils/analytics.service";
import { AuthService } from "../auth.service";

@Component({
  selector: "zona-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"]
})
export class SignUpComponent extends AbstractComponent implements OnInit {

  @Output()
  accountCreated = new EventEmitter();

  @Input()
  signupRequest: SignupRequest;
  @Input()
  hideSocialLogin: boolean = false

  view = "form";
  hidePassword = true;

  signupForm: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.email, Validators.required]),
    password: new FormControl("", [Validators.required, Validators.minLength(8), Validators.maxLength(16)]),
    phone: new FormControl("", [Validators.minLength(10), Validators.maxLength(10)]),
    fname: new FormControl("", [Validators.minLength(2), Validators.maxLength(16)]),
    lname: new FormControl("", [Validators.minLength(2), Validators.maxLength(16)])
  });

  confirmForm: FormGroup = new FormGroup({
    email: new FormControl(),
    code: new FormControl('', [Validators.required, Validators.min(3)])
  });

  errorMessage = null;
  confirmErrorMessage = null;
  countryCode = "+57";

  get email() {
    return this.signupForm.get("email").value;
  }

  constructor(private _authService: AuthService, private analytics: AnalyticsService) {
    super();
  }

  ngOnInit() {
    if (this.signupRequest == null) {
      return;
    }
    if (this.signupRequest.accountCreated && !this.signupRequest.emailConfirmed) {
      this.view = 'confirm';
      let email = this.signupRequest.userEmail;
      this.confirmForm.controls['email'].setValue(email);
      this.sendConfirmationMail(email);
    }
  }

  private inputValue(name: string): string {
    return this.signupForm.controls[name].value;
  }


  signUp() {
    if (!this.signupForm.valid) {
      return;
    }
    this.view = "creating";
    let phone = this.inputValue('phone');
    phone = this.isBlank(phone) ? '+570000000000' : this.countryCode + phone;
    this._authService.signUp({
      email: this.inputValue("email"),
      password: this.inputValue("password"),
      firstName: this.inputValue("fname"),
      lastName: this.inputValue('lname'),
      phone: phone
    }).then(data => {
      if (!data.userConfirmed) {
        this.analytics.registerEvent('sign_up', { method: 'email' });
        this.confirmForm.controls['email'].setValue(this.inputValue("email"));
        this.view = "confirm";
      }
    }).catch(error => {
      let code = error.code;
      const message = error.message
      if ("InvalidPasswordException" == code) {
        this.errorMessage = "La contraseña no es lo suficientemente compleja";
      } else if ("UsernameExistsException" == code) {
        this.errorMessage = "Ya existe un usuario con ese correo";
      } else {
        this.errorMessage = error.message || "Ocurrió un error desconocido";
      }
      this.logE(error);
      this.lastError = error;
      this.view = "form";
    });
  }

  sendNotification(msg: string) {
    console.log(msg);
  }

  sendAgain() {
    let email = this.confirmForm.controls['email'].value;
    this.sendConfirmationMail(email);
  }

  sendConfirmationMail(email: string) {
    Auth.resendSignUp(email)
      .then(() => {
        this.sendNotification('Se ha enviado un correo a tu dirección');
      }).catch(e => {
        this.logE(e);
        if ("LimitExceededException" == e.code) {
          this.confirmErrorMessage = "Demasiados intentos, espera un momento.";
        } else {
          this.confirmErrorMessage = e.message || "Error al enviar el correo de confirmación";
        }
        this.sendNotification('Ocurrió un error')
      });
  }

  confirmCode() {
    if (!this.confirmForm.valid) {
      return;
    }
    let code = this.confirmForm.controls['code'].value;
    let email = this.confirmForm.controls['email'].value;

    this._authService.confirmEmail(email, code).then((data: any) => {
      let password = this.inputValue("password");
      this._authService.signIn(email, password).then(() => {
        this.accountCreated.emit();
      }).catch((error: any) => {
        this.logE(error);
        this.confirmErrorMessage = error.message || "Ocurrió un error al autenticar"
      })
    }).catch((error: any) => {
      this.logE(error);
      if ("CodeMismatchException" == error.code) {
        this.confirmErrorMessage = "El código no es correcto"
      }
      this.confirmErrorMessage = error.message || "Error inesperado, intenta de nuevo"
      this.sendNotification(error.message);
    })
  }

  signInWithFacebook() {
    this._authService.socialSignIn(AuthService.FACEBOOK);
  }
  signInWithGoogle() {
    this._authService.socialSignIn(AuthService.GOOGLE);
  }
}

export interface SignupRequest {
  userEmail: string;
  emailConfirmed: boolean;
  accountCreated: boolean;
}
