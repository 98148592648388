import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ProfileComponent } from './profile/profile.component';
import { AvatarComponent } from './profile/avatar/avatar.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { AuthDialogComponent } from './auth-dialog/auth-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CallbackComponent } from './callback/callback.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PhoneConfirmComponent } from './phone-confirm/phone-confirm.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxMaskModule } from 'ngx-mask';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormDialogComponent } from './form-dialog/form-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NewPasswordComponent } from './new-password/new-password.component';


@NgModule({
  declarations: [
    ProfileComponent,
    AvatarComponent,
    SignInComponent,
    SignUpComponent,
    AuthDialogComponent,
    CallbackComponent,
    ForgotPasswordComponent,
    PhoneConfirmComponent,
    FormDialogComponent,
    NewPasswordComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    MatBottomSheetModule,
    MatDialogModule,
    FlexLayoutModule,
    NgOtpInputModule,
    NgxMaskModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    MatSelectModule
  ],
  exports: [ProfileComponent]
})
export class AuthModule { }
