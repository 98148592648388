import { Component, EventEmitter, Input, OnInit, Output, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GeoService, RegionDTO } from 'src/app/busqueda/zona-geo/geo.service';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'zona-region-picker',
  templateUrl: './region-picker.component.html',
  styleUrls: ['./region-picker.component.scss']
})
export class RegionPickerComponent extends AbstractComponent implements OnInit {

  @Input()
  etiqueta: string;
  @Input()
  tiposRegion: string[] = ['CIUDAD', 'LOCALIDAD', 'BARRIO', 'SECTOR', 'MUNICIPIO', 'UBICACION', 'ESTADO']
  @Output()
  cambioSeleccion = new EventEmitter<RegionDTO>();
  @Input()
  habilitado = true

  @Input()
  activarRegionesPorDefecto = true

  mensajeError: string = null
  autocomplete = new FormControl()
  regiones: Observable<RegionDTO[]>
  cargando = false
  regionInput = new Subject<string>();
  _regionSeleccionada: RegionDTO = null
  valorInicial: RegionDTO = null

  teminoSearchDialog = '';
  regionSeleccionadaDialog: RegionDTO[] = []
  esPantallaPeque: boolean = false;

  private regionesPrincipales: RegionDTO[] = []

  @ViewChild('pickerDialog') pickerDialog: TemplateRef<any>;
  constructor(private region: GeoService, private dialog: MatDialog,
    private traductor:TranslateService,
    private breakPointObs: BreakpointObserver) {
    super()
    if(!this.etiqueta){
      this.traductor.stream('RegionPicker.etiqueta').subscribe((result) => {
        this.etiqueta = result;
      });
    }

  }

  @Input()
  set regionSeleccionada(value: RegionDTO) {
    //console.log("region n",value);
    //console.log("region o",this._regionSeleccionada);
    if (this._regionSeleccionada == value) {
      return
    }
    this._regionSeleccionada = value
    this.mensajeError = null
    //  this.recargarRegiones()
    this.cambioSeleccion.emit(value)
  }

  get regionSeleccionada(): RegionDTO {
    return this._regionSeleccionada
  }

  ngOnInit(): void {
    this.breakPointObs.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(size => {
      this.esPantallaPeque = size.matches;
    });

    this.regionInput.subscribe(data => {
      //console.log("dataaa", data);
    });

    if(this.activarRegionesPorDefecto){
        this.region.getRegionesPrincipales().subscribe(r => {
        //console.log("regiones",r);
        this.regionesPrincipales = r;
        this.recargarRegiones()
      })
    }else{
      this.recargarRegiones();
    }
  }

  trackByFn(item: RegionDTO) {
    return item.id;
  }

  setValorInicial(region: RegionDTO) {
    this.valorInicial = region
    this.regionSeleccionada = region
  }

  recargarRegiones() {
    const regiones = [...this.regionesPrincipales];
    console.log("regiones cargadas",regiones);
    if (this.regionSeleccionada) regiones.push(this.regionSeleccionada)
    this.cargarRegiones(regiones)
  }

  private cargarRegiones(def: RegionDTO[] = []) {
    console.log("cargadnod",def);
    this.regiones = concat(of(def), // default items
      this.regionInput.pipe(distinctUntilChanged(), debounceTime(300), tap(() => this.cargando = true),
        switchMap(term => this.region.autocomplete(term, this.tiposRegion).pipe(catchError(e => {
          this.logE(`Error cargando regiones`, e);
          return of([])
        }), tap(() => this.cargando = false)
        ))
      )
    );
  }

  abrirDialog() {
    if (this.esPantallaPeque && this.habilitado) {
      const dialogConfig = {
        autoFocus: true,
        panelClass: 'fullScreenDialog',
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
      }

      this.dialog.open(this.pickerDialog, dialogConfig);
    }


  }

  cerrarDialog() {
    this.dialog.closeAll();
  }

  seletecRegionDiaglog(region) {

    this.regionSeleccionada = region;
    this.cerrarDialog();


  }


}
