import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
//import { regiones } from './regions.data';
import { AbstractService } from 'src/app/utils/abstract.service';
import { ServiceId } from 'src/app/utils/utils';
import { tap, find, map, catchError } from 'rxjs/operators';
import { default_regions } from './default-regions'
import { REGIONES_DEFECTO } from './regions.data'
import { CacheManager } from 'src/app/utils/CacheManager';
import { TranslateService } from '@ngx-translate/core';
import { TraduccionService } from 'src/app/utils/traducciones/traducciones.services';


@Injectable({ 'providedIn': 'root' })
export class GeoService extends AbstractService {

  private tiposRegion = [
    { id: 1, label: "Ciudad" },
    { id: 2, label: "Barrio" },
    { id: 3, label: "Region" },
    { id: 4, label: "Localidad" },
  ];

  private cacheRegion = CacheManager.getCache('regiones')

  private regions: PlaceDTO[] = default_regions;

  constructor(private traductor:TraduccionService) {
    super();
  }

  private cache(regions: PlaceDTO[]) {
    this.regions = regions;
  }

  /**
   * @deprecated use no se deben usar tipos de región
   */
  getRegionType(typeId: number): string {
    let tipo = this.tiposRegion.find(t => t.id == typeId);
    return tipo == null ? null : tipo.label;
  }

  /**
   * @deprecated use buscarRegionPorId
   */
  getRegionById(regionId: number): Observable<PlaceDTO> {
    return this.getAllPlaces().pipe(map(r => r.find(rg => rg.id == regionId)));
  }

  /**
   * @deprecated usar nuevo api
   */
  getChildPlaces(regionType: number, parentRegion: number | PlaceDTO) {
    let id = parentRegion
    if (parentRegion instanceof PlaceDTO) {
      id = parentRegion.id
    }
    this.regions.filter(p => p.parentId == id && p.type == regionType)
  }

  filter(filter: string): PlaceDTO[] {
    if (this.isBlank(filter)) {
      return this.regions;
    }
    filter = filter.toLowerCase();
    return this.regions.filter(place => place.name.toLowerCase().indexOf(filter) === 0);;
  }

  findByName(regionType: number, name: string): PlaceDTO {
    let results = this.regions.filter(p => p.type == regionType && p.name == name);
    return results.length == 1 ? results[0] : results.length < 0 ? null : null
  }

  buscarRegionPorId(id: string): Observable<RegionDTO> {
    //const cached: any = this.cacheRegion.get(id.toLowerCase())
    //if (cached != null) return of(cached);
    //const region = REGIONES_DEFECTO.find(r => r.id.toLowerCase() === id.toLowerCase())
    //if (region != null) return of(region)
    //TODO: si el objeto no está en caché debería buscarlo en el servidor
    return this.apiGet(`regiones`, `/regiones/${id}`)
  }

  getRegionesPrincipales(): Observable<RegionDTO[]> {
    return this.traductor.getTraduccionSub("Generales.regiones");
  }

  ubicarRegion(region: string, lat:number, lng: number): Observable<void> {
    return this.apiPost(`regiones`, '/regiones/ubicar', { region, lat, lng })
  }

  autocomplete(q: string, regionType: Array<string>): Observable<any> {
    if (!q) {
       return this.traductor.getTraduccionSub("Generales.regiones")
      return of(REGIONES_DEFECTO)
    }
    if (q.length == 1) {
      return of([])
    }
    let type = regionType.join(',')
    // const regiones = REGIONES_DEFECTO.filter(r => r.nombre.toLowerCase().includes(q.toLowerCase()))
    // return of(regiones)
    let {codigoPais} = this.traductor.getLenguajeActual();
    return this.apiGet('regiones', '/regiones/autocomplete', { q, type,pais:codigoPais }).pipe(
      tap((regiones: RegionDTO[]) => regiones.forEach(r => this.cacheRegion.put(r.id.toLowerCase(), r))),
      catchError(e => {
        this.logE(`Error autocompletando regiones`, e)
        //const regiones = REGIONES_DEFECTO.filter(r => r.nombre.toLowerCase().includes(q.toLowerCase()))
        const regiones=  this.traductor.getTraduccion("Generales.regiones").filter(r => r.nombre.toLowerCase().includes(q.toLowerCase()));
        return of(regiones)
      }))
  }

  getAllPlaces(): Observable<PlaceDTO[]> {
    this.httpGet(ServiceId.GEO, "regions").subscribe((r: PlaceDTO[]) => this.cache(r));
    return of(this.regions);
  }

}

export class RegionDTO {
  id: string
  nombre?: string
  tipo?: string
  nombrePadre?: string
  centro?: { lat, lng }
  limites: { n, s, e, w }
}

export class PlaceDTO {
  id: number;
  name: string;
  parent?: string;
  relevance?: number;
  parentId?: number;
  type?: number;
  center?: GeoPoint
}

export class PlaceUtil {
  static toUrlPart(place: PlaceDTO): string {
    let name = place.name
    if (name == null) {
      return `${place.id}`
    }
    name = name.replace(/\s/g, "_")
    name = encodeURIComponent(name)
    return `${place.id}-${place.name}`
  }
}

export class GeoPoint {
  public lat: number;
  public lon: number;
}
