import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { NotificationService } from 'src/app/utils/notification.service';
import { AuthService } from '../auth.service';
import { AbstractComponent } from 'src/app/utils/abstract.service';

@Component({
  selector: 'zona-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent extends AbstractComponent {

  isLoading = false;
  @Output()
  unconfirmedUser = new EventEmitter<string>();
  @Output()
  signedIn = new EventEmitter();
  @Output()
  forgotPassword = new EventEmitter();
  @Input()
  hideSocialLogin = false

  @Output()
  newPassword = new EventEmitter<CognitoUser>();

  @Output()
  recoverPassword = new EventEmitter<string>()

  signinForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [Validators.required, Validators.min(6)])
  });

  hide = true;
  errorMessage = null;

  get emailInput() { return this.signinForm.get('email'); }
  get passwordInput() { return this.signinForm.get('password'); }

  constructor(
    public auth: AuthService,
    private _notification: NotificationService,
    private _router: Router,
    //private _loader: LoaderService
  ) {
    super();
  }

  getEmailInputError() {
    if (this.emailInput.hasError('email')) {
      return 'Ingresa una dirección de correo válida.';
    }
    if (this.emailInput.hasError('required')) {
      return 'El correo es requerido.';
    }
  }

  getPasswordInputError() {
    if (this.passwordInput.hasError('required')) {
      return 'La contraseña es requerida.';
    }
  }

  signIn() {
    this.isLoading = true;
    let email = this.emailInput.value;
    this.auth.signIn(email, this.passwordInput.value)
      .then((user: CognitoUser | any) => {
        console.log("user",user);
        this.isLoading = false;
        this.signedIn.emit();
      })
      .catch((error: any) => {
        this.isLoading = false;
        this._notification.show(error.message);
        switch (error.code) {
          case "UserNotConfirmedException":
            this.unconfirmedUser.emit(email);
            break;
          case "UsernameExistsException":
            debugger;
            this._router.navigate(['auth/signin']);
            break;
          case "UserNotFoundException":
            this.errorMessage = "El usuario no existe"
            break;
          case "NotAuthorizedException":
            this.errorMessage = "Usuario o contraseña incorrecta"
            break;
          case 'ZonaNewPasswordException':
            this.newPassword.emit(error.user);
            break;
          case 'PasswordResetRequiredException':
            this.recoverPassword.emit(email);
            break;
          default:
            console.log("error",error);
            this.errorMessage = error.message || "Ocurrió un error inesperado"
        }
      })
  }

  async signInWithFacebook() {
    this.auth.socialSignIn(AuthService.FACEBOOK);
  }

  async signInWithGoogle() {
    this.auth.socialSignIn(AuthService.GOOGLE);
  }
}
