import { Component, OnInit, Input } from '@angular/core';
import { Message } from '../../utils';

@Component({
  selector: 'zona-lock-layer',
  templateUrl: './lock-layer.component.html',
  styleUrls: ['./lock-layer.component.scss']
})
export class LockLayerComponent implements OnInit {

  @Input()
  locked:boolean = false
  @Input()
  message:string = "Por favor espera..."
  
  ngOnInit(): void {
  }
}
