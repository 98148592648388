<div class="container">
    <div class="filters" [class.filters-visible]="mostrarFiltros">
        <div style="text-align: right;" fxShow fxHide.gt-sm>
            <button mat-icon-button (click)="toggleFiltros()">
                <i class="fal fa-times"></i>
            </button>
        </div>
        <zona-filtros-busqueda-avanzada (buscar)="buscar($event)" (cancel)="toggleFiltros()">
        </zona-filtros-busqueda-avanzada>
    </div>
    <div class="list" fxHide fxShow.gt-sm fxLayout="column">
        <div class="filters-results">
            <button mat-icon-button (click)="mostrarEnLista()">
                <i class="fas fa-list mr10"></i>
                Ver resultados en lista
            </button>
        </div>
        <div class="lista-resultados" fxLayout="column" fxLayoutGap="1em" #listaResultados>

            <div fxHide [fxShow]="buscando" fxLayout="column" fxLayoutAlign="center center" class="sin-resultados">
                <img src="/assets/ilustraciones/searching-animate.svg">
                <h3>Buscando inmuebles</h3>
            </div>
            <ng-container *ngIf="cargandoInmueblesVisibles">
                <zona-resultado-inmueble *ngFor="let a of [1,2,3,4,5,6,7]"></zona-resultado-inmueble>
            </ng-container>
            <div fxHide [fxShow]="!buscando && !cargandoInmueblesVisibles && inmueblesVisibles.length == 0" fxLayout="column" fxLayoutAlign="center center" class="sin-resultados">
                <img src="/assets/ilustraciones/house-searching-animate.svg">
                <h3>No hay nada por acá</h3>
                <p>No hemos encontrado ningún inmueble en este sector con los filtros que indicas, puedes moverte a otra
                    ubicación en el mapa o editar los filtros.</p>
                <button mat-flat-button (click)="mostrarFiltros = true" color="primary">Editar los filtros</button>
            </div>
            <ng-container fxHide [fxShow]="!buscando && !cargandoInmueblesVisibles && inmueblesVisibles.length > 0">
                <ng-container *ngFor="let i of inmueblesVisibles; let indx = index">
                    <zona-resultado-inmueble [negocio]="busqueda?.negocio" (ubicarEnMapa)="ubicarEnMapa($event)" [inmueble]="i"></zona-resultado-inmueble>
                </ng-container>
                <zona-paginator (pageChanged)="this.cargarPaginaLista($event)" [pageCount]="totalPaginasLista">
                </zona-paginator>
                <div class="banner" fxLayout="column" *ngIf="!buscando && codigoPais == 'co' && banner">
                    <h2>{{banner.titulo}}</h2>
                    <p class="texto">{{banner.texto}}</p>
                    <a mat-flat-button color="white" target="_blank" [routerLink]="banner.vinculo">{{banner.accion || 'Ver más'}}</a>
                </div>
            </ng-container>
        </div>
        <!-- Lista de resultados del inmueble-->
        <!-- <zona-resultado-inmueble></zona-resultado-inmueble> -->
    </div>
    <div class="map">
        <div class="content-map" fxLayout="column">
            <div class="map-layer">
                <zona-map class="map-component" (mapMoved)="mapMoved($event)" (markerClick)="markerClick($event)" (mapReady)="mapMoved($event)" (mapClick)="mapClick($event)"></zona-map>
            </div>
            <ad-banner-horizontal fxShow [fxHide]="mostrarFiltros" titulo="{{'busqueda.ads.horizontal.titulo' | translate}}"
                descripcion="{{'busqueda.ads.horizontal.texto' | translate}}"
                link="{{'busqueda.ads.horizontal.route' | translate}}" textoLink="{{'busqueda.ads.horizontal.boton' | translate}}"></ad-banner-horizontal>

        </div>

        <div class="overlay-layer-right">

        </div>

        <div class="overlay-layer">

            <div fxLayout>
                <button fxHide fxShow.lt-md *ngIf="!mostrarFiltros" mat-flat-button color="accent" class="elemento" id="filters-button" (click)="toggleFiltros()">
                    <i *ngIf="buscando" class="fad fa-spinner-third fa-spin"></i>
                    <i *ngIf="!buscando" class="far zona-text-md" [class]="!mostrarFiltros ? 'fa-sort-amount-down' : 'fa-times'"></i>
                    <span>{{!mostrarFiltros ? 'Mostrar' : 'Ocultar'}}</span>
                </button>
                <button fxHide *ngIf="!mostrarFiltros" fxShow.gt-sm mat-flat-button color="accent" class="elemento" [class.button-no-margin]="mostrarFiltros" id="filters-button"
                    (click)="toggleFiltros()">
                    <i *ngIf="buscando" class="fad fa-spinner-third fa-spin"></i>
                    <i *ngIf="!buscando" class="far zona-text-md" [class]="!mostrarFiltros ? 'fa-sort-amount-down' : 'fa-times'"></i>
                    <span>{{!mostrarFiltros ? 'Mostrar Filtros' : ''}}</span>
                </button>

                <button *ngIf="!mostrarFiltros" fxShow-lt-md fxHide.gt-sm mat-flat-button class="elemento" color="white" (click)="mostrarEnLista()">
                    <i class="far fa-list-ul"></i>
                    <span>Lista</span>
                </button>
                <button mat-flat-button fxHide fxShow.gt-sm class="elemento" [matMenuTriggerFor]="menu" *ngIf="usuario.hasRole('admin') && busqueda">
                    <i class="fal fa-tools mr5"></i>
                    <span>Administrador</span>
                </button>

                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="ubicarRegion()" [disabled]="!busqueda?.region">
                        <i class="fal fa-location mr5"></i>
                        <span>Reubicar: {{busqueda?.region.nombre}}</span>
                    </button>
                    <!-- <button mat-menu-item>
                        <i class="fal fa-location mr5"></i>
                        <span>{{ editarPosicion? 'Desactivar' : 'Activar'}} edición de posición</span>
                    </button> -->
                </mat-menu>
                <zona-floating-fav-inmuebles fxHide fxShow.gt-sm class="elemento"></zona-floating-fav-inmuebles>


                <zona-floating-fav-inmuebles [mostrarTexto]="false" class="elemento" fxHide fxShow.lt-md></zona-floating-fav-inmuebles>

            </div>
        </div>

    </div>
</div>
