import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegionPickerComponent } from './region-picker/region-picker.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FilterDialogPipe } from './FilterDialogPipe';
import { MatButtonModule } from '@angular/material/button';
import { TraduccionesModule } from '../../../utils/traducciones/traducciones.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [RegionPickerComponent, FilterDialogPipe],
  exports: [RegionPickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatListModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    TraduccionesModule,
    TranslateModule
  ]
})
export class ZonaRegionPickerModule { }
