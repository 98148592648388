import { NgModule } from "@angular/core";
import { BannersHorizontalComponent } from './banners-horizontal/banners-horizontal.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BannerHorizontalComponent } from './banner-horizontal/banner-horizontal.component';
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  declarations: [BannersHorizontalComponent, BannerHorizontalComponent],
  exports: [BannersHorizontalComponent, BannerHorizontalComponent],
  imports: [MatButtonModule, CommonModule, RouterModule, FlexLayoutModule]
})
export class BannersModule {

}
