<ng-container *ngIf="!changeCompleted">
    <p class="zona-text-md"><b>Recuperar contraseña</b></p>
    <small *ngIf="!mailSent">Ingresa el correo de tu cuenta</small>
    <small *ngIf="mailSent" class="spaced">
        Te acabamos de enviar un mensaje al correo <strong>{{mailSentTo}}</strong> con un código para recuperar tu cuenta,
        por favor ingresa el código y tu nueva contraseña a continuación.
    </small>
    <form [formGroup]="forgotPasswordForm" class="zona-form mat-typography">
        <mat-form-field class="full-row" appearance="outline">
            <mat-label>Correo electrónico</mat-label>
            <input matInput placeholder="Email" type="email" formControlName="email" [readonly]="mailSent">
            <mat-hint *ngIf="!forgotPasswordForm.controls['email'].value">Ingresa el correo con el que creaste tu cuenta
            </mat-hint>
            <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('required')">El correo es requerido
            </mat-error>
            <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('email')">Debes ingresar un correo válido
            </mat-error>
        </mat-form-field>
        <div *ngIf="errorMessage != null" class="full-row spaced error-msg">
            {{errorMessage}}
        </div>
        <button mat-raised-button color="primary" class="spaced full-row" (click)="sendCode()"
            *ngIf="!mailSent">Confirmar</button>
        <div class="text-center mt10 w-100 zona-text-sm">
            <span class="mouse-pointer ml5" (click)="regresar.emit()">Regresar</span>
        </div>
    </form>
    <form *ngIf="mailSent" [formGroup]="newPasswordForm" class="zona-form mat-typography" fxLayout="column" autocomplete="off">
        <mat-form-field class="full-row" appearance="outline">
            <mat-label>Código de confirmación</mat-label>
            <!-- readonly onfocus="this.removeAttribute('readonly');" evita que chrome llene este campo -->
            <input matInput placeholder="Código de confirmación" type="text" formControlName="code" readonly onfocus="this.removeAttribute('readonly');" required>
            <mat-hint>Ingresa acá el código que recibiste</mat-hint>
            <mat-error *ngIf="newPasswordForm.controls['code'].hasError('required')">Debes ingresar el código que te
                enviamos</mat-error>
        </mat-form-field>
        <mat-form-field class="full-row" appearance="outline">
            <mat-label>Nueva Contraseña</mat-label>
            <input matInput placeholder="Nueva Contraseña" [type]="hidePassword ? 'password' : 'text'"
                formControlName="newPassword" autocomplete="anything" required>
            <i matSuffix class="fal fa-eye" *ngIf="!hidePassword" (click)="hidePassword = !hidePassword">
            </i>
            <i matSuffix class="fal fa-eye-slash" *ngIf="hidePassword" (click)="hidePassword = !hidePassword">
            </i>
            <mat-error *ngIf="newPasswordForm.controls['newPassword'].hasError('required')">Este valor es requerido
            </mat-error>
            <mat-error *ngIf="newPasswordForm.controls['newPassword'].hasError('minlength')">Por lo menos 8 caracteres
            </mat-error>
        </mat-form-field>

        <div *ngIf="changePasswordErrorMessage != null" class="full-row spaced error-msg">
            {{changePasswordErrorMessage}}
        </div>
        <button mat-raised-button color="primary" class="spaced full-row" (click)="changePassword()"
            *ngIf="mailSent">Cambiar contraseña</button>
    </form>
</ng-container>
<div *ngIf="changeCompleted">
    <h2>Listo</h2>
    <p>Tu contraseña ha sido cambiada.</p>
    <button mat-flat-button color="primary" (click)="passwordChanged.emit()">Terminar</button>
</div>
