import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError, of, from } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ 'providedIn': 'root' })
export class InterceptorService implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.auth.getAccessToken()).pipe(
      mergeMap(token => next.handle(this.cloneRequest(req, token))),
      catchError(err => next.handle(this.cloneRequest(req, "Empty")))
    );
  }

  private cloneRequest(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (!req.url.startsWith(environment.backendServer.propertySearch)) {
      return req;
    }
    token = token || "Empty";
    let headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return req.clone({ headers: headers });
  }
}