<div [formGroup]="formularioFiltros" class="filtros">
    <!-- Ubicación -->
    <zona-region-picker [ngStyle]="{display:'block' , 'margin-bottom.px': -20 }"></zona-region-picker>
    <!-- Tipo inmueble -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{'ZonaSearch.tipoInmueble' | translate}}</mat-label>
        <mat-select formControlName="tipoInmueble">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let i of tiposInmueble" [value]="i.id">{{i.label}}</mat-option>
        </mat-select>
    </mat-form-field>
    <!-- Tipo gestión -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{'ZonaSearch.venta' | translate | titlecase}} o {{'ZonaSearch.arriendo' | translate | titlecase}}</mat-label>
        <mat-select formControlName="tipoNegocio">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let i of tiposNegocio" [value]="i.id">{{i.label | titlecase}}</mat-option>
        </mat-select>
    </mat-form-field>
    <!-- Certificado -->
    <mat-form-field appearance="fill" floatLabel="always" class="full-width">
      <mat-label>Certificado</mat-label>
      <mat-slide-toggle formControlName="certificado">{{formularioFiltros.value.certificado ? 'Con Certificado' : 'Sin Certificado'}}</mat-slide-toggle>
      <textarea matInput hidden></textarea>
    </mat-form-field>

    <!-- Estado -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Nuevo o Usado</mat-label>
        <mat-select formControlName="estado">
            <mat-option [value]=""></mat-option>
            <mat-option value="nuevo">Nuevo</mat-option>
            <mat-option value="usado">Usado</mat-option>
        </mat-select>
    </mat-form-field>
    <!-- Habitaciones -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{'Inmuebles.detalles.caracteristicas.habitaciones' | translate }}</mat-label>
        <mat-select formControlName="habitaciones">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let i of lista(8)" [value]="i">{{i}}</mat-option>
            <mat-option value="-5">Más de 8</mat-option>
        </mat-select>
    </mat-form-field>
    <!-- Baños -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{'Inmuebles.detalles.caracteristicas.baños' | translate }}</mat-label>
        <mat-select formControlName="banos">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let i of lista(5)" [value]="i">{{i}}</mat-option>
            <mat-option value="-5">Más de 5</mat-option>
        </mat-select>
    </mat-form-field>
    <!-- Garajes -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>{{'Inmuebles.detalles.caracteristicas.parqueaderos' | translate }}</mat-label>
        <mat-select formControlName="parqueaderos">
            <mat-option [value]=""></mat-option>
            <mat-option *ngFor="let i of lista(5)" [value]="i">{{i}}</mat-option>
            <mat-option value="-5">Más de 5</mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Precio Desde -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Precio Desde</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input formControlName="precioDesde" mask="separator" matInput placeholder="Precio desde">
    </mat-form-field>


    <!-- Precio Hasta -->
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Precio Hasta</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input formControlName="precioHasta" mask="separator" matInput placeholder="Precio hasta">
    </mat-form-field>

    <!-- Avanzado -->
    <div class="toggle-avanzado" (click)="mostrarAvanzada = !mostrarAvanzada">
        <i class="fas fa-ellipsis-v"></i>
        Avanzada
    </div>
    <div [fxShow]="mostrarAvanzada || caracteristicas.length > 0" class="filtros-avanzados" fxLayout="column"
        fxLayoutAlign="start">
        <mat-checkbox *ngFor="let filtro of filtrosAvanzados" [checked]="filtroMarcado(filtro.clave)"
            (change)="agregarFiltroAvanzado(filtro.clave, $event.checked)">
            {{filtro.etiqueta}}
        </mat-checkbox>
    </div>

    <!-- Acciones -->
    <button class="zona-btn-green btn-search mt20" (click)="buscarClick()">
        <b>Buscar</b> <i class="fas fa-search ml5"></i>
    </button>
    <div class="w100 text-center mt10">
        <button mat-button class="w100" (click)="cancelar()">Cancelar</button>
    </div>
</div>
