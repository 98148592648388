import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LinkService {

  openLink(url: string, newTab: boolean = true, source: string = null): void {
    if (newTab) {
      window.open(url, "_blank");
    } else {
      window.open(url);
    }
  }

}
