import { AfterViewInit, Component, OnInit, ElementRef, ViewChild, EventEmitter, HostListener } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { BottomNavItem } from 'ngx-bottom-nav';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IconService } from './common/icon.service';
import { LinkService } from './utils/link.service';
import { UTMService } from './interop/utm.service';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TraduccionService } from './utils/traducciones/traducciones.services';


declare var gtag

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {


  sideNavOpened = false;
  mobileDevice = false;
  currentViewRequiresSidebar = false
  loaderVisible = true

  @ViewChild("scroll") scroll: ElementRef
  scrollSubscription: Subscription = null

  sideNavMode: 'side' | 'over' = 'side';
  toolBarHeight = 50;
  fullScreen = false;
  hideSearchLinks = false
  private readonly mediaWatcher: Subscription;
  items: BottomNavItem[] = [
    { icon: 'search', label: 'Busqueda', routerLink: '/', exact: true },
    { icon: 'library_books', label: 'Revista', routerLink: 'blog', exact: true },
    // { icon: 'build', label: 'Herramientas', routerLink: 'herramientas', exact: true },
    { icon: 'favorite', label: 'Favoritos', routerLink: '/busqueda/favoritos', exact: true },
    { icon: 'person', label: 'Cuenta', routerLink: '/cuenta/informacion', exact: true },
  ];

  links = {
    "facebook": { url: environment.socialNetworks.facebook, newPage: true },
    "twitter": { url: environment.socialNetworks.twitter, newPage: true },
    "instagram": { url: environment.socialNetworks.instagram, newPage: true }
  }


  private idiomas: string[] = [
    'es_CO',
    'es_AR'
  ]
  constructor(media: MediaObserver, private iconService: IconService,
    private linkService: LinkService,
    private swUpdate: SwUpdate, snackbar: MatSnackBar,
    private utm: UTMService,
    private router: Router,
    private route: ActivatedRoute,
    public traduccionesService: TraduccionService
  ) {
    this.mediaWatcher = media.media$.subscribe((change: MediaChange) => {
      this.mobileDevice = change.mqAlias === 'sm' || change.mqAlias === 'xs'
      this.updateSideBarVisibility()
    });
    this.addRouterListener();
    if (!this.swUpdate.isEnabled) {
      console.warn("swUpdate not enabled")
    }
    this.swUpdate.available.subscribe(evt => {
      const snack = snackbar.open("Hay una nueva versión de Zona Hábitat", "Actualizar")
      snack.onAction().subscribe(() => {
        window.location.reload();
      });
    });

  }

  openLink(key: string) {
    var link = this.links[key];
    this.linkService.openLink(link.url, link.newPage, "topLinks");
  }


  private addRouterListener() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //se evita que se cambie el scroll cuando la ruta tenga key de form contacto
        if (!event.url.includes("ledContact")) {
          this.scrollUp();
        }
      }
    });
  }

  onRouterActivated(componentRef) {

    this.fullScreen = componentRef.fullScreen == true
    this.hideSearchLinks = componentRef.hideSearchLinks == true

    this.currentViewRequiresSidebar = false
    if (componentRef.sideNavOpened != null) {
      this.currentViewRequiresSidebar = componentRef.sideNavOpened
    }
    if (this.scrollSubscription != null) {
      this.scrollSubscription.unsubscribe()
    }
    if (componentRef.scrollEvent && componentRef.scrollEvent instanceof EventEmitter) {
      let evt = componentRef.scrollEvent as EventEmitter<number>
      this.scrollSubscription = evt.subscribe(e => {
        this.scroll.nativeElement.scrollTop = e
      })
    }
    this.updateSideBarVisibility()
    this.loaderVisible = false
  }

  sidenavClicked() {
    this.updateSideBarVisibility();
  }

  updateSideBarVisibility() {
    this.sideNavOpened = !this.mobileDevice && this.currentViewRequiresSidebar;
    this.sideNavMode = this.mobileDevice ? 'over' : 'side';
  }

  onRouterDeactivated(componentRef) {
    this.loaderVisible = true
  }

  ngOnDestroy(): void {
    this.mediaWatcher.unsubscribe();
  }

  ngOnInit() {
    this.iconService.registerIcons();
    this.utm.detectUTM()
  }

  @HostListener("window:scrollUp")
  scrollUp() {
    this.route.queryParams.subscribe((params) => {
      if (!("leadContact" in params)) {
        if (this.scroll) this.scroll.nativeElement.scrollTop = 0
      }
    })

  }

  ngAfterViewInit(): void {
  }

}
