import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthDialogService } from '../../../auth/auth-dialog.service';

export interface DataGuestDialog {
  auth?: Boolean
  delete?: Boolean
}

export interface DataGuestDialogoResult {
  result:Boolean,
  auth: Boolean
}
@Component({
  selector: 'zona-guest-favorite-dialog',
  templateUrl: './guest-favorite-dialog.component.html',
  styleUrls: ['./guest-favorite-dialog.component.scss']
})
export class GuestFavoriteDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<GuestFavoriteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataGuestDialog,
    private authDialog: AuthDialogService
  ) {

  }

  ngOnInit(): void {


  }

  guarSinAuth() {
    this.dialogRef.close({
      result:true,
      auth:false
    });
  }

  abrirAuth() {

    this.authDialog.showAuthDialogWithType((result) => {
      this.dialogRef.close({
        result:true,
        auth:true
      });
    });
  }

  cancelar()
  {
    this.authDialog.showAuthDialogWithType((result) => {
      this.dialogRef.close({
        result:false,
        auth:false
      });
    });
    this.dialogRef.close(this.data);
  }

  get textoDialogo(): string {
    if (this.data?.delete) {
      return 'Estar Segur@ de elimnar este inmueble de favoritos';
    }

    return 'Estas segur@ de agregar este inmueble a tu lista de favoritos. Te invitamos a Iniciar sesión para sincronizarlo en tu cuenta';
  }

}
