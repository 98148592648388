import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractService } from '../utils/abstract.service';

@Injectable({ providedIn: 'root' })
export class UTMService extends AbstractService {

  expirationTime = 1000 * 60 * 60 * 24; //1 day

  constructor(private route: ActivatedRoute) {
    super();
  }

  detectUTM() {
    let params = this.route.snapshot.queryParamMap;
    let source = params.get('utm_source') || 'organic';
    let medium = params.get('utm_medium') || 'organic';
    let campaign = params.get('utm_campaign') || 'none';
    if (!this.sameUTM(source, medium, campaign)) {
      this.saveUTM(source, medium, campaign);
    }
  }

  sameUTM(source: string, medium: string, campaign: string): boolean {
    let utm = this.currentUTM();
    if (utm == null) {
      return false;
    }
    if (utm.source != source || utm.medium != medium || utm.campaign != campaign) {
      return false;
    }
    return true;
  }

  currentUTM(): UTMInfo {
    if (typeof (Storage) === "undefined") return null
    let utm = localStorage.getItem('utm');
    if (utm == null) {
      return null;
    }
    let parsed = JSON.parse(utm);
    let age = Date.now() - parsed.timeDetected;
    if (age > this.expirationTime) {
      //UTM Expired
      localStorage.removeItem('utm');
      return null;
    }
    return parsed;
  }

  saveUTM(source: string, medium: string, campaign: string) {
    if (typeof (Storage) === "undefined") return
    localStorage.setItem('utm', JSON.stringify({ source: source, medium: medium, campaign: campaign, timeDetected: new Date() }))
  } 

}

interface UTMInfo {
  timeDetected: Date;
  source: string;
  medium: string;
  campaign: string;
}
