<div fxLayout="row" fxLayoutGap="1.5em" class="h-100">
    <div  fxHide fxShow.gt-xs  fxFlex.gt-xs="49" class="container-img"></div>
    <div fxFlex="100" fxFlex.gt-xs="49">
        <zona-sign-in *ngIf="state == 'signin'" (recoverPassword)="onRecoverPassword($event)" (newPassword)="onNewPassword($event)" (unconfirmedUser)="onUnconfirmedUser($event)" (signedIn)="onSignedIn()"
            (forgotPassword)="forgotPassword()" [hideSocialLogin]="data.hideSocialLogin" >
        </zona-sign-in>
        <zona-sign-up (accountCreated)="onAccountCreated()" [signupRequest]="signupRequest" *ngIf="state == 'signup'" [hideSocialLogin]="data.hideSocialLogin">
        </zona-sign-up>
        <zona-phone-confirm *ngIf="state === 'phoneConfirm'" (phoneConfirmed)="onPhoneConfirmed()"></zona-phone-confirm>
        <zona-forgot-password [email]="emailRecuperacion" *ngIf="state == 'forgotPassword'" (passwordChanged)="onPasswordChanged()"
            (regresar)="signin()">
        </zona-forgot-password>
        <zona-new-password *ngIf="state === 'newPassword'" [user]="userNewPassword" (signedIn)="onSignedIn()">

        </zona-new-password>
        <div class="spaced actions" *ngIf="state == 'signin' && !data.disableAccountCreation">
            <div class="text-center mt20 w-100 zona-text-sm" *ngIf="!data.disableAccountCreation">
                ¿No tienes una cuenta?
                <span class="indicador mouse-pointer ml5" (click)="signup()">Registrarme</span>
            </div>
            <!-- <button type="button" color="primary" (click)="forgotPassword()" mat-button>Recuperar Contraseña</button> -->
        </div>
        <div class="center spaced mt20" *ngIf="state == 'signup'">
            <div class="text-center mt20 w-100 zona-text-sm">
                ¿Ya tienes una cuenta?
                <span class="indicador mouse-pointer ml5" (click)="signin()">Ingresa con tu cuenta</span>
            </div>
            <div class="center spaced" *ngIf="state == 'forgotPassword'">
                <button type="button" *ngIf="!loggedIn" (click)="signin()" color="primary" mat-button>Volver</button>
                <button type="button" *ngIf="loggedIn" (click)="closeDialog()" color="primary"
                    mat-button>Cerrar</button>
            </div>
        </div>
    </div>
</div>
