export class MathUtil {

    static sum(one: number, another: number, precision: number = 6): number {
        let factor = this.getFactor(precision);
        one *= factor;
        another *= factor;
        return (one + another) / factor;
    }

    static subtract(one: number, another: number, precision: number = 6): number {
        return this.sum(one, -another, precision);
    }

    private static getFactor(precision: number): number {
        return Math.pow(10, precision);
    }
}