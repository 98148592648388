import { Component, OnInit } from '@angular/core';
import { LinkService } from 'src/app/utils/link.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'zona-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  year = (new Date()).getFullYear();
  env = environment.socialNetworks;
  constructor(private linkService: LinkService) {
  }

  ngOnInit(): void {
  }

  onLinkClick() {
  }

}
