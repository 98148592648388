import { Component, OnInit, Output, EventEmitter, OnDestroy, ViewEncapsulation, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon.png';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { BoundingBox, PointDTO } from 'src/app/busqueda/search.dto';
import * as ngeohash from 'ngeohash'

@Component({
  selector: 'zona-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapComponent extends AbstractComponent implements OnDestroy, AfterViewInit {


  @Output()
  markerClick: EventEmitter<MapMarker> = new EventEmitter<MapMarker>();
  @Output()
  mapMoved: EventEmitter<MapLocation> = new EventEmitter<MapLocation>();
  @Output()
  mapReady: EventEmitter<MapLocation> = new EventEmitter<MapLocation>();
  @Output()
  mapClick: EventEmitter<MapLocation> = new EventEmitter<MapLocation>();


  private _map: L.Map;
  get map(): L.Map {
    return this._map
  }
  get bounds(): L.LatLngBounds {
    return this.map.getBounds()
  }

  get zoom(): number {
    return this.map.getZoom()
  }

  moveEventDebounceTime = 1000;
  private moveSubject: Subject<L.LeafletEvent> = new Subject<L.LeafletEvent>();

  // Open Street Map Definition
  // https://maps.wikimedia.org/osm-intl/${z}/${x}/${y}.png
  // https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
  LAYER_OSM = {
    id: 'zonahmap',
    name: 'Results Map',
    enabled: true,
    layer: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //layer: L.tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: 'Zona Hábitat'
    })
  };

  // Values to bind to Leaflet Directive
  layersControlOptions = { position: 'bottomleft' };
  baseLayers = { 'zonalayer': this.LAYER_OSM.layer };
  options = {
    attributionControl: false,
    trackResize: false,
    zoomControl: true,
    zoom: 12,
    minZoom: 11,
    center: L.latLng({ lat: 4.7110, lng: -74.0721 }),
  };

  // Marker cluster stuff
  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterData: L.Marker[] = [];
  markerClusterOptions: L.MarkerClusterGroupOptions = {
    disableClusteringAtZoom: 19,
    iconCreateFunction: (cluster:any) => {
      var markers = cluster.getAllChildMarkers();

                    // create the icon with the "weight" count, instead of marker count
          return L.divIcon({
            html: '<div class="weight-marker weight-marker-cluster"><span>' +  markers.length + '</span></div>',
            className: 'marker-cluster testcluster marker-cluster-small marker-cluster-zona'
          });
    },

  };
  markersVisible = true;

  constructor() {
    super();
    this.moveSubject.pipe(debounceTime(500)).subscribe(v => {
      this.runOnNgZone(() => this.mapMoved.emit(this.getMapLocation()));
    });


  }
  ngAfterViewInit(): void {
    this.relocateZoomControl();
  }

  private relocateZoomControl() {
    let zoom = document.querySelector(".leaflet-control-zoom");
    let parent = document.querySelector(".leaflet-bottom.leaflet-left");
    zoom.parentElement.removeChild(zoom);
    parent.appendChild(zoom);
  }

  ngOnDestroy(): void {
    const layers = this.getMarkers();
    layers.forEach(l => this.markerClusterGroup.removeLayer(l));
  }

  getMarkers(): any[] {
    const layers = [];
    this.markerClusterGroup.eachLayer(l => layers.push(l));
    return layers;
  }

  setMarkersLayerVisible(visible: boolean) {
    if (visible === this.markersVisible) {
      return;
    }
    this.markersVisible = visible;
    this.changeVisibility('leaflet-marker-pane', visible);
    this.changeVisibility('leaflet-shadow-pane', visible);
  }

  private changeVisibility(className: string, visible: boolean) {
    const elements = document.getElementsByClassName(className);
    Array.from(elements).forEach(e => {
      e.setAttribute('style', `opacity: ${visible ? 1 : 0}`);
    });
  }

  getMapLocation(): MapLocation {
    const mapCenter = this.map.getCenter();
    const bounds = this.map.getBounds();
    const box = new BoundingBox(bounds.getNorth(), bounds.getEast(), bounds.getWest(), bounds.getSouth());
    const center = new PointDTO(mapCenter.lat, mapCenter.lng);
    return { center: center, zoom: this.map.getZoom(), bounds: box };
  }

  setMapLocation(latitude: number, longitude: number, zoom: number = null) {
    //if (zoom) this.map.flyTo([latitude, longitude], zoom);
    if(zoom) this.map.setView([latitude, longitude], zoom)
    else this.map.panTo([latitude, longitude]);
  }

  flyTo(latitude: number, longitude: number, zoom: number) {
    this.map.flyTo([latitude, longitude], zoom)
  }

  onMapReady(map: L.Map) {
    this._map = map;
    setTimeout(function () { map.invalidateSize(); }, 500);
    map.addEventListener('zoomend moveend', e => this.moveSubject.next(e));
    this.mapReady.emit(this.getMapLocation());
    map.addEventListener('click', e => this.onClick(e))
  }

  onClick(event) {
    this.mapClick.emit(event)
  }

  invalidateLayout() {
    setTimeout(_ => { this.map.invalidateSize() }, 500);
  }

  /**
   * Retorna la lista de geohashes visibles en el mapa actualmente
   */
  getGeoHashBoxes(precision: number = 9): string[] {
    const bounds = this.map.getBounds()
    let geoHashBoxes: string[] = ngeohash.bboxes(bounds.getSouth(), bounds.getWest(), bounds.getNorth(), bounds.getEast(), precision)
    return geoHashBoxes
  }


  set locked(locked: boolean) {
    this.map['spin'](locked)
  }

  markerClusterReady(group: L.MarkerClusterGroup) {
    this.markerClusterGroup = group;

  }

  clearMarkers() {
    this.markerClusterData = [];
  }

  removeMarker(marker: MapMarker) {
    let component = marker.component
    this.markerClusterGroup.removeLayer(component)
  }

  setMarkers(markers: Array<MapMarker>) {
    this.markerClusterData = markers.map(m => this.toMarker(m))
  }

  addMarker(...markers: MapMarker[]) {
    const old = this.getMarkers();
    this.logD('Adding {0} markers', markers.length);
    markers.forEach(m => this.markerClusterGroup.addLayer(this.toMarker(m)));
    // markers.forEach(m => this.markerClusterData.push(this.toMarker(m)));
    // this.markerClusterData = [...this.markerClusterData]
  }

  addLayer(layer: L.Layer) {
    if (layer == null) return
    this.map.addLayer(layer)
  }

  removeLayer(layer: L.Layer) {
    if (layer == null) return
    this.map.removeLayer(layer)
  }

  private toMarker(marker: MapMarker): L.Marker {
    let layer = L.marker([marker.lat, marker.lng], { title: marker.title, icon: IconFactory.getIcon(marker.color), draggable: marker.draggable })
      .on('click', e => this.onMarkerClick(marker, event));
    marker.component = layer;
    return layer;
  }


  private onMarkerClick(marker: MapMarker, event) {
    this.logD('Marker click on :' + marker.id)
    this.markerClick.emit(marker);
  }

}

export class MarkerIcon {
  static readonly NORMAL = 'normal';
  static readonly SEEN = 'seen';
  static readonly SELECTED = 'selected';
  static readonly FAVORITE = 'fav';
  static readonly FAV_SELECTED = 'fav_selected';
  static readonly FAV_SEEN = 'fav_seen';
  static readonly HIDDEN = 'hidden';
  static readonly HIDDEN_SELECTED = 'hidden_selected';
  static readonly HIDDEN_SEEN = 'hidden_seen';
  static readonly INVALID = 'invalid';
}

export class IconFactory {

  private static iconMap: { [key: string]: L.Icon } = {};


  public static getIcon(name: string): L.Icon {
    if (name == null) { return null; }
    let icon = this.iconMap[name];
    if (icon == null) {
      icon = new L.Icon({
        // iconUrl: `https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
        iconUrl: `/assets/icons/png/marker/${name}.png`,
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
      this.iconMap[name] = icon;
    }
    return icon;
  }
}

export class MapMarker {
  component: L.Marker;
  _color: string;
  _title: string;
  _lat: number
  _lng: number
  draggable: boolean = false
  constructor(
    public readonly id: number, lat: number, lng: number, title: string, public data: any = null, color: string = MarkerIcon.NORMAL) {
    this.lat = lat
    this.lng = lng
    this.color = color;
    this.title = title;
  }

  get lat(): number {
    return this._lat
  }

  get lng(): number {
    return this._lng
  }

  set lat(value: number) {
    this._lat = value
    this.syncComponent()
  }

  set lng(value: number) {
    this._lng = value
    this.syncComponent()
  }

  get title() {
    return this._title;
  }

  set title(title: string) {
    this._title = title;
    this.syncComponent()
  }

  get color() {
    return this._color;
  }

  set color(color: string) {
    this._color = color;
    if (this.component == null) return
    this.component.setIcon(IconFactory.getIcon(this._color));
    // this.syncComponent()
  }

  set location(value: [number, number]) {
    this.lat = value[0]
    this.lng = value[1]
    this.syncComponent()
  }


  private syncComponent() {
    if (this.component == null) return
    this.component.setLatLng([this.lat, this.lng])
    this.component.setIcon(IconFactory.getIcon(this._color));
    if (this.component.getElement() != null) this.component.getElement().title = this.title
    // this.component.setPopupContent(this.title)
  }
}

function spin(state, options) {
  console.log(`Changed!!`)
  if (!this._spinner) {
    const spinner = document.createElement("div")
    spinner.setAttribute("class", "zona-map-spinner")

    const iconContainer = document.createElement("div")
    iconContainer.setAttribute("class", "icon-container")
    spinner.appendChild(iconContainer)


    const icon = document.createElement("i")
    icon.setAttribute("class", "icono fal fa-compass fa-spin")
    iconContainer.appendChild(icon)

    const text = document.createElement("div")
    text.setAttribute('class', "texto")
    text.textContent = "Buscando"
    spinner.appendChild(text)

    this._container.appendChild(spinner)
    this._spinner = spinner
  }

  this._spinner.setAttribute("style", `visibility: ${!!state ? "visible" : "hidden"}`)

}
L.Map.include({ spin })

export class MapLocation {
  center: PointDTO;
  bounds: BoundingBox;
  zoom: number;
}
