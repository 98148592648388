import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { setInterval } from 'timers';

@Component({
  selector: 'zona-vender-floating-button',
  templateUrl: './vender-floating-button.component.html',
  styleUrls: ['./vender-floating-button.component.scss']
})
export class VenderFloatingButtonComponent implements OnInit {

  currentFace = 1
  displayDetail = false;
  timeout = null
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.changeFace();


     setTimeout(() => {
        this.displayDetail = true;

        setTimeout(() => {
          this.displayDetail = false;
        },6000);

      },3000);

  }

  changeFace() {
    this.currentFace = Math.floor(Math.random() * 5)
  }

  openModal() {
    this.router.navigate(['/consignar/inmueble'],{fragment:'form-top'});
  }
}
