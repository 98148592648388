import { Component, OnInit } from '@angular/core';
import { PqrsService, Pqrs } from '../pqrs.service';
import { AbstractComponent } from '../../abstract.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'zona-pqrs-dialog',
  templateUrl: './pqrs-dialog.component.html',
  styleUrls: ['./pqrs-dialog.component.scss']
})
export class PqrsDialogComponent extends AbstractComponent implements OnInit {

  public pqrsService: PqrsService;
  mapeoTipoMensaje = [null, 's', 'q', 'f']
  tiposMensaje = [
    { id: 1, label: "Sugerencia" },
    { id: 2, label: "Queja" },
    { id: 3, label: "Felicitación" }
  ]
  tipoMensaje = null
  mensajeError = null
  mensaje = null
  estado = 0
  constructor(private dialog: MatDialogRef<PqrsDialogComponent>) {
    super();
  }

  ngOnInit(): void {
  }

  enviar() {
    if(this.tipoMensaje == null || this.isBlank(this.mensaje)){
      this.mensajeError = "Por favor completa los campos"
      return;
    }
    if(this.mensaje.length > 500){
      this.mensajeError = "Usa un máximo de 500 caracteres"
      return
    }
    this.estado = 1
    let path = window.location.pathname
    let pqrs: Pqrs = {
      mensaje: this.mensaje,
      tipo: this.mapeoTipoMensaje[parseInt(this.tipoMensaje)],
      ruta: path
    }
    this.mensajeError = null
    this.pqrsService.enviarPqrs(pqrs).subscribe(r => {
      this.estado = 2
    }, e => {
      this.mensajeError = "Ocurrió un error enviando el mensaje"
      this.estado = 3
    })
  }

  cerrar() {
    this.dialog.close()
  }

}
