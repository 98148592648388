<zona-seccion [imagen]="informacionComponente.imagen" color="#FFFFFFA0">
    <div class="content">
        <div class="fondo">
            <h2>{{informacionComponente.titulo}}</h2>
            <p class="zona-text-md mt5">{{informacionComponente.subtitulo}}</p>

            <p class="description">{{informacionComponente.descripcion}}</p>

            <a *ngIf="informacionComponente.accion" mat-flat-button color="white"
                [routerLink]="informacionComponente.accion" class="mt20">{{informacionComponente.nombreAccion}}</a>
        </div>
    </div>
</zona-seccion>
