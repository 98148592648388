<div>
  <ng-select [items]="regiones | async" bindLabel="nombre" [addTag]="false" [multiple]="false" [hideSelected]="false" [disabled]="!habilitado"
    [trackByFn]="trackByFn" [minTermLength]="2" [loading]="cargando" [clearable]="false" [placeholder]="etiqueta"
    typeToSearchText="Ingresa al menos dos letras para buscar" [typeahead]="regionInput" loadingText="Buscando..." class="selector"
    appearance="outline" notFoundText="No hay coincidencias" [(ngModel)]="regionSeleccionada"  (click)="abrirDialog()" appendTo="body" >
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div class="region">
        <div class="label">
          {{item.nombre}}
        </div>
        <div class="description">
          <span class="type">{{item.tipo}}</span>
          <span class="parentName">{{item.nombrePadre}}</span>
        </div>
      </div>
    </ng-template>
  </ng-select>
</div>
<ng-template #pickerDialog>
  <div matDialogTitle>
    <div class="hedaderTitleDialog">
      <span>Selecciona Región</span>
      <button mat-button (click)="cerrarDialog()" color="accent">Cancelar</button>
    </div>
    <div class="headerBodyDialog">
       <input matInput [ngModel]="regionInput | async" (ngModelChange)="regionInput.next($event)"
       [placeholder]="etiqueta" value="">
    </div>

  </div>
  <div mat-dialog-content style="height: 100%;">
    <mat-selection-list #regionsList [multiple]="false" (selectionChange)="seletecRegionDiaglog(regionsList.selectedOptions.selected[0]?.value)">
      <mat-list-option  *ngFor="let region of regiones | async" [value]="region" [selected]="region.nombre === regionSeleccionada?.nombre" >
        <div class="region">
        <div class="label">
          {{region.nombre}}
        </div>
        <div class="description">
          <span class="type">{{region.tipo}}</span>
          <span class="parentName">{{region.nombrePadre}}</span>
        </div>
      </div>
      </mat-list-option>
    </mat-selection-list>
</div>

</ng-template>

<div class="error">{{mensajeError}}</div>
