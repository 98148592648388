<mat-toolbar class="topnav unselectable" fxLayout="row nowrap" fxLayoutAlign="space-between center">
  <button type="button" mat-icon-button fxShow fxHide.gt-sm (click)="toggleSidebar()">
    <i class="fas fa-bars nav-icon"></i>
  </button>
  <div class="nav-brand"
   fxLayout="row"  fxFlex.gt-sm="0" fxLayoutAlign="space-around center" fxLayoutAlign.gt-sm="start">
    <a [routerLink]="['/']">
      <img src="assets/images/logo_zonahabitat.svg" id="zona-logo" alt="Zona Hábitat">
    </a>
  </div>

  <div class="links" fxHide fxShow.gt-sm fxLayout="row nowrap" fxLayoutAlign="end" fxFlex>
    <ng-container *ngIf="traducciones.esCodigoPaisActual('co')">
      <a mat-button [routerLink]="['busqueda/mapa/apartamentos/venta/MPIO_11001-Bogota']" rel="nofollow">Propiedades</a>
      <a mat-button [routerLink]="['servicios/fianzas']">Fianzas</a>
      <a mat-button [routerLink]="['financiacion']">Financiación</a>
      <a mat-button [routerLink]="['seguros']">Seguros</a>
    </ng-container>
     <ng-container *ngIf="!traducciones.esCodigoPaisActual('co')">
        <a mat-button routerLink="{{'NAV.MENU.1.route' |translate }}" rel="nofollow" >{{'NAV.MENU.1.label' | translate}}</a>
        <a mat-button routerLink="{{'NAV.MENU.2.route' |translate }}" rel="nofollow" >{{'NAV.MENU.2.label' | translate}}</a>
        <a mat-button routerLink="{{'NAV.MENU.3.route' |translate }}" rel="nofollow" >{{'NAV.MENU.3.label' | translate}}</a>
        <a mat-button routerLink="{{'NAV.MENU.4.route' |translate }}" rel="nofollow" >{{'NAV.MENU.4.label' | translate}}</a>

     </ng-container>

    <a mat-button *ngIf="traducciones.esCodigoPaisActual('co')" [routerLink]="['servicios/remodelar']">Remodelar</a>
  </div>
  <div *ngIf="!user || user.anonymus">
    <button mat-stroked-button color="accent" class="login-button" (click)="login()">
      <i class="fal fa-user user-icon"></i>
      <span class="mr10 ml10" fxHide fxShow.gt-sm>Iniciar sesión</span>
    </button>
  </div>
  <div *ngIf="!user.anonymus">
    <!-- <span class="login-info" [matMenuTriggerFor]="profile">{{user.given_name}}</span> -->
    <button class="login-btn" mat-button [matMenuTriggerFor]="profile">
      <span class="mr10" fxHide fxShow.gt-sm>{{user.given_name || user.name | truncate:10}}</span>
      <i class="fal fa-user-circle user-icon" *ngIf="user.picture == null"></i>
      <img class="avatar" *ngIf="user.picture != null" [src]="user.picture">
    </button>
    <mat-menu #profile="matMenu" [overlapTrigger]="false">
      <button mat-menu-item [routerLink]="user.hasRole('aliado')? '/aliado/panel-control' :'/cuenta/informacion'" >
        <i class="fal fa-tachometer-alt label-icon"></i>
        <span>Información</span>
      </button>
      <button mat-menu-item routerLink="/busqueda/favoritos">
        <i class="fal fa-heart label-icon"></i>
        <span>Favoritos</span>
      </button>
      <button mat-menu-item (click)="signout()">
        <i class="fal fa-sign-out-alt label-icon"></i>
        <span>Salir</span>
      </button>
    </mat-menu>
  </div>
  <zona-selector-lenguaje style="padding-bottom: 2px; margin-left: 10px;" ></zona-selector-lenguaje>
  <a mat-button class="boton-publicar ml10" fxHide fxShow.gt-xs routerLink="/consignar/inmueble"><i class="fal fa-house icono"></i>VENDER <span fxHide fxShow.gt-md>/ ARRENDAR</span></a>
</mat-toolbar>
