<form *ngIf="view == 'form'" [formGroup]="signupForm" class="zona-form mat-typography">
  <p class="zona-text-md mb10"><b>Crear cuenta</b></p>
  <div *ngIf="!hideSocialLogin">
    <button type="button" mat-stroked-button class="btn-facebook" (click)="signInWithFacebook()">
            <i class="fa fa-facebook-f fa-button-icon"></i>
            Ingresar con Facebook
    </button>
    <button type="button" mat-stroked-button class="btn-google" color="warn" (click)="signInWithGoogle()">
            <i class="fa fa-google fa-button-icon"></i>
            Ingresar con Google
        </button>
    <div class="separator full-row"><span>Ó</span></div>
  </div>
  <div *ngIf="hideSocialLogin">
    <p>Crea una cuenta de Zona Hábitat para disfrutar de todos los privilegios.</p>
  </div>
  <mat-form-field class="full-row" appearance="fill">
    <mat-label>Nombre</mat-label>
    <input matInput placeholder="Nombre" type="text" formControlName="fname" autocomplete="given-name" required>
    <mat-error *ngIf="signupForm.controls['fname'].hasError('minlength')">Por lo menos 3 caracteres</mat-error>
    <mat-error *ngIf="signupForm.controls['fname'].hasError('maxlength')">Máximo 16 caracteres</mat-error>
  </mat-form-field>
  <mat-form-field class="full-row mtn10" appearance="fill">
    <mat-label>Apellido</mat-label>
    <input matInput placeholder="Apellido" type="text" formControlName="lname" autocomplete="family-name" required>
    <mat-error *ngIf="signupForm.controls['lname'].hasError('minlength')">Por lo menos 3 caracteres</mat-error>
    <mat-error *ngIf="signupForm.controls['lname'].hasError('maxlength')">Máximo 16 caracteres</mat-error>
  </mat-form-field>
  <mat-form-field class="full-row mtn10" appearance="fill">
    <mat-label>Celular</mat-label>
    <span matPrefix>{{ countryCode }} &nbsp;</span>
    <input type="tel" mask="000 000-0000" matInput placeholder="Número de celular" formControlName="phone" autocomplete="tel">
  </mat-form-field>
  <mat-form-field class="full-row mtn10" appearance="fill">
    <mat-label>Correo electrónico</mat-label>
    <input matInput placeholder="Correo electrónico" type="email" formControlName="email" autocomplete="email" required>
  </mat-form-field>
  <mat-form-field class="full-row mtn10 spaced" appearance="fill">
    <mat-label>Contraseña</mat-label>
    <input matInput placeholder="Contraseña" [type]="hidePassword ? 'password' : 'text'" formControlName="password" autocomplete="anything" required>
    <i matSuffix (click)="hidePassword = !hidePassword" [ngClass]="hidePassword? 'fal fa-eye' : 'fal fa-eye-slash'"></i>
    <mat-error *ngIf="signupForm.controls['password'].hasError('minlength')">Por lo menos 8 caracteres</mat-error>
  </mat-form-field>
  <div *ngIf="errorMessage != null" class="full-row mtn10 spaced error-msg">
    {{errorMessage}}
  </div>
  <small>Al crear una cuenta en Zona Hábitat estás aceptando nuestra <a routerLink="/politica-privacidad" rel="nofollow" target="_blank">política
      de
      privacidad</a></small>
  <button mat-raised-button color="primary" class="full-row" (click)="signUp()">Crear cuenta</button>
</form>

<form *ngIf="view == 'confirm'" [formGroup]="confirmForm" class="zona-form mat-typography">
  <h2>Confirma tu correo</h2>
  <p>
    Hemos enviado un correo a la dirección que nos proporcionaste, solo queremos asegurarnos que podrás recuperar tu
    cuenta en caso que pierdas el acceso.
  </p>
  <p>
    Por favor ingresa a continuación el código que te enviamos.
  </p>
  <mat-form-field class="full-row" appearance="fill">
    <mat-label>Correo electrónico</mat-label>
    <input matInput placeholder="Email" type="email" formControlName="email" readonly>
  </mat-form-field>
  <mat-form-field class="full-row" appearance="fill">
    <mat-label>Código de confirmación</mat-label>
    <input matInput placeholder="Código de confirmación" type="text" formControlName="code" required>
    <mat-hint *ngIf="!confirmForm.controls['code'].value">Por favor ingresa el código que enviamos a tu correo
    </mat-hint>
  </mat-form-field>
  <div *ngIf="confirmErrorMessage != null" class="full-row spaced error-msg">
    {{confirmErrorMessage}}
  </div>
  <button mat-raised-button color="primary" class="full-row spaced" (click)="confirmCode()">Confirmar</button>
  <p class="spaced">¿No haz recibido un código? <a href="javascript:void(null)" (click)="sendAgain()">Reenviar</a></p>
</form>
