<div>
    <form [formGroup]="formGroup" fxLayout="column">
        <h2>Confirmación de teléfono</h2>
        <p>Para realizar la siguiente operación debes confirmar tu número de teléfono, por favor ingresa tu número de teléfono</p>
        <mat-form-field class="full-row mtn10" appearance="fill">
            <mat-label>Tu número de teléfono</mat-label>
            <span matPrefix>{{ countryCode }} &nbsp;</span>
            <input type="tel" matInput placeholder="Número de celular" formControlName="phone" autocomplete="tel" mask="000 000-0000">
        </mat-form-field>
        <span class="error">{{sendCodeErrorMessage}}</span>
        <button [ngClass]="'mat-' + (codeSent? 'stroked' : 'flat') + '-button'" mat-button color="primary"
            [disabled]="timeToResendSeconds > 0" (click)="sendCode()">
            {{timeToResendSeconds < 1? codeSent? 'Reenviar código' : 'Enviar código' : 'Reenviar en ' + timeToResendSeconds + ' segundos'}}
        </button>
        <div class="confirm" fxLayout="column" *ngIf="codeSent" fxLayout="column" fxLayoutAlign="center stretch">
            <p class="message">Por favor ingresa el código que te enviamos a</p>
            <span class="phone"><i class="fal fa-sms mr20"></i>{{sentToPhone | mask: '(+00) 000 000-0000' }}</span>
            <ng-otp-input fxFlex [config]="otpConfig" (onInputChange)="onOTPChange($event)">
            </ng-otp-input>
            <span class="error">{{confirmErrorMessage}}</span>
            <button mat-flat-button color="primary" class="mt20" [disabled]="otp == null || otp.length != 6"
                (click)="confirmCode()">Confirmar</button>
        </div>
    </form>
</div>
