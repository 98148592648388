<div class="paginator" fxLayoutAlign="center" [class.display-none]="pageList.length < 1">
    <a class="zona-btn-rounded-basic mr10" (click)="previousPage()" [class.zona-btn-disabled]="!prevEnabled">
        <i class="far fa-arrow-left"></i>
    </a>
    <a class="link" *ngFor="let i of pageList" [class.zona-btn-disabled]="locked || i == pageIndex"
        [class.current]="i  == pageIndex" [routerLink]="usePath? linkPrefix + '/' + i : []"
        [queryParams]="usePath? {} : {__pagina: i}" queryParamsHandling="merge" mat-button>{{i}}</a>

    <a class="zona-btn-rounded-basic mr10" (click)="nextPage()" [class.zona-btn-disabled]="!nextEnabled">
        <i class="far fa-arrow-right"></i>
    </a>
</div>
