export const default_regions = [
    {
        "id": 167,
        "parent": "Bogot\u00e1 D.C",
        "name": "Bogot\u00e1, D.C.",
        "type": 1,
        "center": {
            "lat": 4.71099,
            "lon": -74.07209
        }
    },
    {
        "id": 12,
        "parent": "Antioquia",
        "name": "Medell\u00ed\u00adn",
        "type": 1,
        "center": {
            "lat": 6.25028,
            "lon": -75.56365
        }
    },
    {
        "id": 144,
        "parent": "Atl\u00e1ntico",
        "name": "Barranquilla",
        "type": 1,
        "center": {
            "lat": 10.93924,
            "lon": -74.77433
        }
    },
    {
        "id": 168,
        "parent": "Bolivar",
        "name": "Cartagena",
        "type": 1,
        "center": {
            "lat": 10.39795,
            "lon": -75.55951
        }
    },
    {
        "id": 1071,
        "parent": "Valle del Cauca",
        "name": "Cali",
        "type": 1,
        "center": {
            "lat": 3.4475,
            "lon": -76.55041
        }
    },
    {
        "id": 96,
        "parent": "Antioquia",
        "name": "Rionegro",
        "type": 1,
        "center": {
            "lat": 7.12852,
            "lon": -73.12505
        }
    },
    {
        "id": 612,
        "parent": "Cundinamarca",
        "name": "Soacha",
        "type": 1,
        "center": {
            "lat": 4.58698,
            "lon": -74.21053
        }
    },
    {
        "id": 214,
        "parent": "Boyac\u00e1",
        "name": "Tunja",
        "type": 1,
        "center": {
            "lat": 5.54924,
            "lon": -73.35062
        }
    },
    {
        "id": 380,
        "parent": "Casanare",
        "name": "Yopal",
        "type": 1,
        "center": {
            "lat": 5.34793,
            "lon": -72.39242
        }
    },
    {
        "id": 641,
        "parent": "Cundinamarca",
        "name": "Zipaquir\u00e1",
        "type": 1,
        "center": {
            "lat": 5.02735,
            "lon": -74.00969
        }
    }
];
