import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fadeInOnEnterAnimation } from 'angular-animations';
import { Options } from 'ng5-slider';
import { InmuebleService } from 'src/app/inmuebles/inmueble.service';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { EntityReference, URLUtil } from 'src/app/utils/utils';
import { RegionPickerComponent } from '../../zona-geo/zona-region-picker/region-picker/region-picker.component';
import { TraduccionService } from '../../../utils/traducciones/traducciones.services';


export interface Opcion {
  icono: string;
  nombre: string;
  url?: string;
  nofollow?: boolean;
}

@Component({
  selector: 'zona-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [fadeInOnEnterAnimation()]
})

export class SearchComponent extends AbstractComponent implements OnInit, OnDestroy {

  tiposInmueble: Array<EntityReference<string>> = [];
  @ViewChild(RegionPickerComponent)
  regionPicker: RegionPickerComponent;
  comprarInmueble = true;

  tipoInmuebleControl = new FormControl('', [Validators.required])
  filtroHabitaciones = ''
  filtroBanos = ''
  filtroParqueaderos = ''
  filtroPrecioDesde = ''
  filtroPrecioHasta = ''
  filtroCodigo = ''
  avanzadoVisible = false
  filtroEstado = ''
  caracteristicasMarcadas: Set<string> = new Set<string>();

  conCertificado = false;

  caracteristicas = [

  ]

  value: number = 100;
  highValue: number = 3000;
  options: Options = {
    floor: 100,
    ceil: 3000
  };
  masOpciones: Opcion[] = [

  ];


  constructor(private inmuebleService: InmuebleService, private router: Router,
    private traducciones:TraduccionService
    ) {
    super();
  }

  ngOnInit(): void {
    this.inmuebleService.getTiposInmueble().subscribe(i => this.tiposInmueble = i, error => this.handleError(error));

    this.traducciones.getTraduccionSub("HOME.index.ZonaSearch").subscribe(result => {
      this.masOpciones = result;
    });
    this.traducciones.getTraduccionSub("Generales.caracteristicas").subscribe(result => {
      this.caracteristicas = result;
    });

  }

  ngOnDestroy(): void {
  }

  cambiarCaracteristica({ checked }, caracteristica) {
    if (checked) {
      this.caracteristicasMarcadas.add(caracteristica)
    } else {
      this.caracteristicasMarcadas.delete(caracteristica)
    }
  }

  doSearch(evt) {
    this.avanzadoVisible = false
    if (this.filtroCodigo) {
      const url = `/busqueda/inmueble/${this.filtroCodigo}`
      this.router.navigate([url], { queryParams: { origen: `index` } })
      return
    }
    let error = false
    const region = this.regionPicker.regionSeleccionada
    if (region == null) {
      this.regionPicker.mensajeError = "Por favor selecciona el lugar"
      error = true
    }
    const tipoInmueble = this.tipoInmuebleControl.value
    if (!tipoInmueble) {
      this.tipoInmuebleControl.markAsTouched()
      this.tipoInmuebleControl.setErrors({ required: true })
      error = true
    }

    if (error) {
      return;
    }

    const queryParams = { origen: 'index' }
    if (this.filtroHabitaciones) queryParams['f.habitaciones'] = this.filtroHabitaciones
    if (this.filtroBanos) queryParams['f.banos'] = this.filtroBanos
    if (this.filtroParqueaderos) queryParams['f.parqueaderos'] = this.filtroParqueaderos
    if (this.filtroPrecioDesde) queryParams['f.precioDesde'] = this.filtroPrecioDesde
    if (this.filtroPrecioHasta) queryParams['f.precioHasta'] = this.filtroPrecioHasta
    if (this.filtroEstado) queryParams['f.estado'] = this.filtroEstado;
    if(this.conCertificado) queryParams['f.certificado'] = this.conCertificado;

    if (this.caracteristicasMarcadas.size > 0) {
      queryParams['f.caracteristicas'] = Array.from(this.caracteristicasMarcadas).join('-')
    }



    const url = `/busqueda/mapa/${tipoInmueble}s/${this.comprarInmueble ? 'venta' : 'arriendo'}/${region.id}-${URLUtil.encodeName(region.nombre)}`
    this.router.navigate([url], { queryParams })
  }


}
