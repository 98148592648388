<div class="location mat-typography unselectable">
  <div class="button">
    <div class="layout" [class.layout-expanded]="displayDetail">

      <div class="detail" [class.visible]="displayDetail">
        <p class="title">Vende / Arrienda  tu inmueble</p>
        <p class="message">Haz click acá para iniciar el proceso</p>
      </div>
      <span class="text"  *ngIf="!displayDetail" >
          <label>Vender</label>
          <label>Arrendar</label>
      </span>
    </div>
    <div class="background" [class.expanded]="displayDetail" (click)="openModal()"
      (mouseover)="this.displayDetail = true" (mouseout)="this.displayDetail = false"></div>
  </div>
</div>
