import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdministradorSitiosComponent } from './administrador-sitios/administrador-sitios.component';
import { ZonaMapModule } from '../utils/zona-map/zona-map.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [AdministradorSitiosComponent],
  exports: [AdministradorSitiosComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    ZonaMapModule
  ]
})
export class SitiosModule { }
