import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractService } from '../utils/abstract.service';
import { AuthDialogComponent, AuthDialogOptions } from './auth-dialog/auth-dialog.component';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthDialogService extends AbstractService {

  constructor(public dialog: MatDialog, private auth: AuthService) {
    super();
  }

  /**
   * Muestra el dialogo de login o confirmación de númnero telefónico al usuario si así es requerido, si
   * el usuario ya está autenticado y tiene su número de teléfono confirmado, entonces esta dialogo
   * no se muestra y retorna false.
   * @return true si el dialogo se muestra, false de lo contrario
   */
  showAuthDialog(onComplete: (result: boolean) => void = null, options: AuthDialogOptions = {}): boolean {
    if (this.auth.loggedIn) {
      if (!options.requirePhoneConfirmation) return false
      if (this.auth.userInfo.phone_number_verified) return false
    }
    const dialog = this.dialog.open(AuthDialogComponent, { width: '750px', height: '650px', data: options });
    dialog.afterClosed().subscribe((result: boolean) => {
      if (onComplete != null) onComplete(result);
    })
    return true
  }

  showAuthDialogWithType(onComplete: (result: any) => void = null, options: AuthDialogOptions = {}):boolean
  {
    if (this.auth.loggedIn) {
      if (!options.requirePhoneConfirmation) return false
      if (this.auth.userInfo.phone_number_verified) return false
    }
    options.withResultType = true;
    const dialog = this.dialog.open(AuthDialogComponent, { width: '750px', height: '650px', data: options });
    dialog.afterClosed().subscribe((result: boolean) => {
      if (onComplete != null) onComplete(result);
    })
    return true
  }
}
