import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexComponent } from './home/index/index.component';
import { COVID19Component } from './utils/covid19/covid19.component';
import { BusquedaMapaComponent } from './busqueda/zona-panel-busqueda/busqueda-mapa/busqueda-mapa.component';
import { BannersComponent } from './utils/zona-layout/zona-banners/banners/banners.component';
import { BlogRedirectComponent } from './blog/blog-redirect/blog-redirect/blog-redirect.component';
import { NotFountComponent } from './home/not-fount/not-fount.component';
import { ZonaNotFoundHandlerComponentComponent } from './utils/zona-not-found-handler-module/zona-not-found-handler-component/zona-not-found-handler-component.component';

const routes: Routes = [

  { path: 'busqueda', loadChildren: () => import('./busqueda/busqueda.module').then(m => m.BusquedaModule) },
  { path: 'revista', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
  { path: 'blog/post/:id', component: BlogRedirectComponent },
  { path: 'herramientas', loadChildren: () => import('./herramientas/herramientas.module').then(m => m.HerramientasModule) },
  { path: 'consultoria', loadChildren: () => import('./asesoria/asesoria.module').then(m => m.AsesoriaModule) },
  { path: 'cuenta', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'aliado', loadChildren: () => import('./inmobiliarias/inmobiliarias.module').then(m => m.InmobiliariasModule) },
  { path: 'financiacion', loadChildren: () => import('./leads/lead-credito/lead-credito.module').then(m => m.LeadCreditoModule) },
  { path: 'zonabitero', loadChildren: () => import('./leads/zonabiteros/zonabiteros.module').then(m => m.ZonabiterosModule) },
  { path: 'seguros', loadChildren: () => import('./seguros/seguros.module').then(m => m.SegurosModule) },
  { path: 'servicios', loadChildren: () => import('./landings/landings.module').then(m => m.LandingsModule) },
  { path: 'politica-privacidad', loadChildren: () => import('./common/politica-privacidad/politica-privacidad.module').then(m => m.PoliticaPrivacidadModule) },
  { path: 'alianzas', loadChildren: () => import('./alianzas/alianzas.module').then(m => m.AlianzasModule) },
  { path: 'ebooks/venta-inmueble', loadChildren: () => import('./landings/landing-ebook-venta/landing-ebook-venta.module').then(m => m.LandingEbookVentaModule)},
  { path: 'ebooks/venta-arriendo', loadChildren: () => import('./landings/landing-ebook-arriendo-venta/landing-ebook-arriendo-venta.module').then(m => m.LandingEbookArriendoVentaModule)},
  { path: 'ebooks/5-preguntas', loadChildren: () => import('./landings/landing-ebook-servicios/landing-ebook-servicios.module').then(m => m.LandingEbookServiciosModule)},

  //TODO: Organizar las urls de administración
  // { path: 'administrador/sitios', component: AdministradorSitiosComponent },
  { path: 'compras', loadChildren: () => import('./compras/compras.module').then(m => m.ComprasModule)},
  { path: 'inmueble/:id', loadChildren: () => import('./inmuebles/inmueble.module').then(m => m.InmuebleModule) },
  { path: 'property', loadChildren: () => import('./property/property.module').then(m => m.PropertyModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'contacto', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
  { path: 'inmobiliarias', loadChildren: () => import('./inmobiliarias/inmobiliarias.module').then(m => m.InmobiliariasModule) },
  { path: 'consignar', loadChildren: () => import('./consignar-inmuebles/consignar-inmuebles.module').then(m => m.ConsignarInmueblesModule) },
  { path: 'paga-referir', loadChildren: () => import('./paga-referir/paga-referir.module').then(m => m.PagaReferirModule) },
  { path: 'covid194', component: COVID19Component },
  { path: 'busqueda-mapa', component: BusquedaMapaComponent },
  { path: 'banners', component: BannersComponent },
  { path: 'signout', redirectTo: '' },
  { path: '', component: IndexComponent },
  { path: '404', component: NotFountComponent },
  { path: '**', component: ZonaNotFoundHandlerComponentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
