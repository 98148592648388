<ng-container>
  <p class="zona-text-md"><b>Nueva contraseña</b></p>
   <small class="spaced">
       Por motivos de seguridad, debes configurar una nueva contraseña para continuar.
    </small>
  <form [formGroup]="newPasswordForm" fxLayout="column">
    <mat-form-field appearance="fill">
      <mat-label>Nueva Contraseña</mat-label>
      <input matInput placeholder="Nueva Contraseña" [type]="hidePassword ? 'password' : 'text'"
        formControlName="newPassword" autocomplete="anything" required>
      <i matSuffix (click)="hidePassword = !hidePassword" [ngClass]="hide? 'fal fa-eye' : 'fal fa-eye-slash'"></i>
        <mat-error *ngIf="!passwordInput?.valid">
            {{getPasswordInputError()}}
        </mat-error>
    </mat-form-field>

     <div fxLayout="row" fxLayoutAlign="center center" class="text-center w-100" >
      <mat-spinner *ngIf="isLoading" diameter="40"></mat-spinner>
    </div>
    <div *ngIf="changePasswordErrorMessage != null" class="full-row spaced error-msg">
      {{changePasswordErrorMessage}}
    </div>
    <button mat-raised-button color="primary" class="spaced full-row" (click)="changePassword()">Cambiar contraseña</button>
  </form>
</ng-container>
