import { Component, OnInit } from '@angular/core';
import { RestQueryBuilder } from 'src/app/utils/rest.utils';
import { SeoService } from 'src/app/utils/seo.service';
import { TraduccionService } from '../../utils/traducciones/traducciones.services';

@Component({
  selector: 'zona-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  fullScreen = false
  indice = -1
  imagenes = [
    // "https://image.shutterstock.com/z/stock-photo-husband-and-wife-and-their-child-moving-in-new-home-765764875.jpg",
    // "https://image.shutterstock.com/z/stock-photo-happy-young-parents-sit-on-couch-with-little-children-have-fun-using-smartphone-together-smiling-1613073493.jpg",
    // "https://image.shutterstock.com/z/stock-photo-hispanic-family-moving-into-new-home-402629035.jpg",
    // "https://image.shutterstock.com/z/stock-photo-husband-and-wife-and-their-son-and-a-pet-moving-in-new-home-765765574.jpg",
    // "https://image.shutterstock.com/z/stock-photo-cute-little-girl-and-her-beautiful-parents-are-smiling-while-cooking-in-kitchen-at-home-556492381.jpg",
    // "https://image.shutterstock.com/z/stock-photo-couple-little-kids-sitting-on-sofa-making-self-portrait-using-smartphone-husband-holding-gadget-1606546468.jpg",
    // "https://image.shutterstock.com/z/stock-photo-happy-family-with-cardboard-boxes-in-new-house-at-moving-day-669633337.jpg",
    // "https://image.shutterstock.com/z/stock-photo-happy-family-playing-in-courtyard-near-their-house-768790210.jpg",

    "https://unsplash.com/photos/zLT3VqWEgOQ/download?force=true&w=1920&fm=webp",
    "https://image.shutterstock.com/z/stock-photo-open-living-room-with-couch-carpet-lamp-and-bookshelf-600399314.jpg",
    "https://image.shutterstock.com/z/stock-photo-modern-interior-d-design-concept-289196939.jpg",
    "https://image.shutterstock.com/z/stock-photo-pouf-and-gray-armchair-in-spacious-living-room-interior-with-plants-and-sofa-near-wooden-table-793829059.jpg",
    "https://image.shutterstock.com/z/stock-photo-white-room-with-sofa-scandinavian-interior-design-d-illustration-515015212.jpg",
    "https://image.shutterstock.com/z/stock-photo-modern-interior-of-living-room-with-wooden-cabinet-and-armchair-panorama-d-rendering-496612132.jpg",
    "https://image.shutterstock.com/z/stock-photo-white-living-room-with-sofa-and-summer-landscape-in-window-scandinavian-interior-design-d-1704367846.jpg",
    "https://image.shutterstock.com/z/stock-photo-patterned-cushions-on-sofa-next-to-wooden-table-and-plant-in-dark-apartment-interior-real-photo-1173882475.jpg",
    "https://image.shutterstock.com/z/stock-photo-black-lamp-standing-by-a-sofa-and-a-row-of-candles-lying-on-the-floor-in-front-of-a-window-shedding-791407645.jpg",
    "https://image.shutterstock.com/z/stock-photo-patterned-posters-above-desk-with-computer-monitor-in-grey-home-office-interior-with-plants-1077476951.jpg",
  ]
  //imagenActual = "https://images.unsplash.com/photo-1541123603104-512919d6a96c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80&auto=format"
  imagenActual = "/assets/images/index.jpg"

  elementos = [
    {
      titulo: 'Simulador credito',
      descripcion: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam, voluptatum.',
      icono: 'fal icon fa-calculator'
    },
    {
      titulo: 'Revista',
      descripcion: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam, voluptatum.',
      icono: 'fal icon fa-quote-right'
    },
    {
      titulo: 'Recursos',
      descripcion: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam, voluptatum.',
      icono: 'fal icon fa-suitcase'
    },
    {
      titulo: 'Recursos 2',
      descripcion: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam, voluptatum.',
      icono: 'fal icon fa-suitcase'
    }
  ]

  informacionAyudaVenta = {
    titulo: '¿Interesado en vender o arrendar su propiedad fácil, rápido y al precio justo?',
    subtitulo: 'Confía tu propiedad a nuestra experticia y olvídate de todo. Lo venderás en menos tiempo y al precio justo.',
    descripcion: `Contamos con nuestra red de inmobiliarias aliadas que ofrecerán tu propiedad en toda Colombia`,
    nombreAccion: '¡Contáctanos! ',
    imagen: '/assets/images/index/2.webp',
    accion: '/consignar/inmueble'
  }


  informacion = {
    titulo: 'En todo el Pais',
    subtitulo: 'Encuentra el lugar de tus sueños entre más de 100.000 propiedades en toda Colombia.',
  }

  informacionHerramientas = {
    titulo: 'Herramientas',
    subtitulo: 'Encuentra las herramientas más útiles para tus procesos inmobiliarios',
    opciones: [
      {
        link: '/herramientas/simulador',
        icon: 'fa-calculator',
        title: 'Simulador de crédito',
        text: 'Calcula las cuotas de tu crédito hipotecario, conoce las tasas de interés de los principales bancos (Bancolombia, Davivienda, BBVA, Banco Caja Social, Colpatria, Banco de Bogotá)'
      },
      {
        link: '/revista/1',
        icon: 'fa-quote-right',
        title: 'Revista',
        text: 'Entérate de todo lo que está ocurriendo en el sector inmobiliario, beneficios estatales, leasing habitacional y mucho más. ¡Aprende con nosotros todos los términos que necesitas!'
      },
      {
        link: '/herramientas/formatos',
        icon: 'fa-suitcase',
        title: 'Recursos',
        text: 'Encuentra todas las plantillas que necesitas, promesas de compraventa, cartas para retiro de cesantías, contratos de arrendamiento de casas, apartamentos, locales, entre otros.'
      },
      {
        link: '/consultoria',
        icon: 'fa-users-class',
        title: 'Consultoría',
        text: 'Recibe atención personalizada <strong>¡Gratis!</strong> por parte de uno de nuestros asesores especialistas en finca raíz, contáctanos para poder ayudarte. '
      }]
  }

  constructor(seo: SeoService,
    public traduccion: TraduccionService
    ) {
    seo.setSEOInfo(null)
    // this.siguienteImagen()
  }

  siguienteImagen() {
    this.indice = this.indice < this.imagenes.length - 1 ? this.indice + 1 : 0
    this.imagenActual = this.imagenes[this.indice]
  }

  ngOnInit(): void {
    this.traduccion.getTraduccionSub("HOME.AyudaVenta").subscribe(traduccion => {
       this.informacionAyudaVenta = traduccion;
    })

    this.traduccion.getTraduccionSub("HOME.SearchLinks.titulo").subscribe(traduccion => {
      this.informacion = traduccion;
    })

  }

}
