import { HttpClient, HttpParams } from '@angular/common/http';
import { Directive, EventEmitter, NgZone, Output, ElementRef } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import API from '@aws-amplify/api';
import { from, Observable, of } from 'rxjs';
import { map, delay, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { CacheManager } from './CacheManager';
import { SeoService } from './seo.service';
import { ZonaHabitatObject } from './zona-object';


export interface TitleProvider {
    windowTitle: string;
}


@Directive()
export class AbstractComponent extends ZonaHabitatObject implements TitleProvider {

    // private router:Router;
    public windowTitle: string;
    private zone: NgZone;
    @Output()
    public modalDisplay = new EventEmitter<ModalDisplayEvent>();
    lastError = null;

    protected setSeoInfo(title: string, description: string, imageUri: string = null) {
        this.getInjectable(SeoService).setSEOInfo(title, description, imageUri);
    }

    protected displayInfoModal(title: string, message: string) {
        this.modalDisplay.emit({ title: title, message: message, actions: [] });
    }

    protected displayConfirmationModal(title: string, message: string, onAccept: ModalAction) {
        this.modalDisplay.emit({ title: title, message: message, actions: [] });
    }

    protected displayQueryModal(title: string, message: string, ...actions: ModalAction[]) {
        this.modalDisplay.emit({ title: title, message: message, actions: actions });
    }

    protected displayModalError(title: string, message: string, error: any = null) {
        this.modalDisplay.emit({ title: title, message: message, actions: [] });
    }

    protected handleError(e) {

        this.lastError = e;
    }

    scrollTo(el: HTMLElement) {
        el.scrollIntoView({ behavior: 'smooth' });
    }

    setFocus(element: ElementRef) {
        const interval = of({}).pipe(delay(300)).subscribe(x => {
            element.nativeElement.focus()
            interval.unsubscribe();
        });
    }

    getControl(formGroup: FormGroup, path: string | number): FormControl {
        path = `${path}`
        return this.getControlInternal(formGroup, path.split('.')) as FormControl
    }

    controlHasError(formGroup: FormGroup | AbstractControl, path: string, error: string): boolean {

        const control = formGroup instanceof FormGroup ? this.getControl(formGroup, path) as FormControl : formGroup

      control.getError
        let  preJuicio = control !== null && (control.hasError(error) || (control?.errors?.hasOwnProperty(error) && control.errors[error]));


        return preJuicio;
    }

    controlHasInvalid(formGroup: FormGroup | AbstractControl, path: string):Boolean
    {
         const control = formGroup instanceof FormGroup ? this.getControl(formGroup, path) as FormControl : formGroup

         return control !== null && control.invalid;
    }

    private getControlInternal(control: any, path: string[]): FormControl {
        if (path.length == 0 || control == null) return control
        const first = path[0]
        if (isNaN(parseInt(first))) {
            const subcontrol = (control as FormGroup).controls[first]
            return this.getControlInternal(subcontrol, path.slice(1))
        } else {
            const index = parseInt(first)
            const subcontrol = (control as FormArray).controls[index]
            return this.getControlInternal(subcontrol, path.slice(1))
        }
    }

    protected clearError() {
        this.lastError = null;
    }

    protected markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();
            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }
    protected isFormGroupValid(formGroup: FormGroup): boolean {
        this.markFormGroupTouched(formGroup)
        const valid = formGroup.valid;
        if(!valid){
          console.log("Form invalid",formGroup);
        }
        return valid;

    }

    protected getZone(): NgZone {
        if (this.zone == null) {
            this.zone = this.getInjectable(NgZone);
        }
        return this.zone;
    }

    protected runOnNgZone<T>(fn: (...args: any[]) => T, applyThis?: any, applyArgs?: any[]): T {
        return this.getZone().run(fn);
    }

    protected appendErrors(control: FormControl, toAppend: any) {
        let current = control.errors;
        let merged = {}
        if (current != null) {
            Object.keys(current).forEach(k => merged[k] = current[k])
        }
        if (toAppend != null) {
            Object.keys(current).forEach(k => merged[k] = toAppend[k])
        }
        control.setErrors(merged)
    }

    protected removeErrors(control: FormControl, toRemove: string[]) {
        let current = control.errors;
        let merged = {}
        if (current != null) {
            Object.keys(current).forEach(k => merged[k] = current[k])
        }
        toRemove.forEach(k => delete merged[k])
        merged = Object.keys(merged).length == 0 ? null : merged;
        control.setErrors(merged)
    }

    /** protected navigate(commands: any[], extras?: NavigationExtras): Promise<boolean>{
        if(this.router == null) this.router = this.getInjectable(Router);
        return this.router.navigate(commands, extras);
    } **/
}


export class ModalDisplayEvent {
    public title: string;
    public message: string;
    public actions: Array<ModalAction> = [];
}

export class ModalAction {
    public label: string;
    public action: () => void;
}

export class AbstractService extends ZonaHabitatObject {


    private http: HttpClient;
    private authService: AuthService;
    private static apiInitialized = false;

    private initializeApi() {
        if (!AbstractService.apiInitialized) {
            // API.configure(environment.awsmobile)
            AbstractService.apiInitialized = true
        }
    }

    protected getHttp(): HttpClient {
        if (this.http == null) {
            this.http = this.getInjectable(HttpClient);
        }
        return this.http;
    }

    protected getJWTToken(): Observable<string> {
        if (this.authService == null) {
            this.authService = this.getInjectable(AuthService)
        }
        //return from(this.authService.getAccessToken().catch(r => null))
        return of(null)
    }
    /**
     * Completa una URL para el backend.
     * @param path
     */
    protected completeURL(serviceId: string, path: string): string {
        if (serviceId == null) return path;
        if (!path.startsWith('/')) {
            path = '/' + path;
        }
        let host = environment.backendServer['propertySearch'];
        if (host == null) {
            this.logE('Unable to determine service URL: ', serviceId);
        }
        let fullPath = host + path;
        return fullPath;
    }


    protected apiGet<T>(apiId: string, path: string, params: any = {}): Observable<T> {
        this.initializeApi()
        return this.getJWTToken().pipe(switchMap(Token => from(API.get(apiId, path, { queryStringParameters: params, headers: Token ? { Token } : {} }))))
    }

    protected apiGetPromise<T>(apiId: string, path: string, params: any = {}): Promise<T> {
        this.initializeApi()
        return API.get(apiId, path, { queryStringParameters: params })
    }
    /**
     * Obtiene un recurso del servidor y lo convierte en un objeto JSON.
     * @param path la ruta relativa en el servidor, esta ruta se completa usando la url de las configuraciones.
     * @param params una lista de parámetros que agregarán a la url.
     */
    protected httpGet<T>(serviceId: string, path: string, params: any = {}): Observable<T> {
        let httpParams = new HttpParams();
        for (const param in params) {
            httpParams = httpParams.set(param, params[param]);
        }
        let fullUrl = this.completeURL(serviceId, path);
        return this.getHttp().get(fullUrl, { params: httpParams }).pipe(map((response: any) => response));
    }

    /**
     * Envía una solicitud post al backend
     */
    protected apiPost(apiId: string, path: string, data: any): Observable<any> {
        this.initializeApi()
        return this.getJWTToken().pipe(switchMap(Token => from(API.post(apiId, path, { body: data, headers: Token ? { Token } : {} }))))
    }

    /**
    * Envía una solicitud put al backend
    */
    protected apiPut(apiId: string, path: string, data: any): Observable<any> {
        this.initializeApi()
        return this.getJWTToken().pipe(switchMap(Token => from(API.put(apiId, path, { body: data, headers: Token ? { Token } : {} }))))
    }

    /**
    * Envía una delete post al backend
    */
    protected apiDelete(apiId: string, path: string): Observable<any> {
        this.initializeApi()
        return this.getJWTToken().pipe(switchMap(Token => from(API.del(apiId, path, { headers: Token ? { Token } : {} }))))
    }

    /**
     * Envía una solicitud POST al servidor.
     * @param path la ruta relativa en el servidor, esta ruta se completa usando la url de las configuraciones.
     * @param data el objeto que se enviará al servidor.
     */
    protected httpPost<T>(serviceId: string, path: string, data: any): Observable<T> {
        let fullUrl = this.completeURL(serviceId, path);
        return this.getHttp().post(fullUrl, data).pipe(map((response: any) => response));
    }




    /**
     * Envía una solicitud PUT al servidor.
     * @param path la ruta relativa en el servidor, esta ruta se completa usando la url de las configuraciones.
     * @param data el objeto que se enviará al servidor.
     */
    protected httpPut<T>(serviceId: string, path: string, data: any): Observable<T> {
        let fullUrl = this.completeURL(serviceId, path);
        return this.getHttp().post(fullUrl, data).pipe(map((response: any) => response));
    }

    /**
     * Envía una solicitud PATCH al servidor.
     * @param path la ruta relativa en el servidor, esta ruta se completa usando la url de las configuraciones.
     * @param data el objeto que se enviará al servidor.
     */
    protected httpPatch<T>(serviceId: string, path: string, data: any): Observable<T> {
        let fullUrl = this.completeURL(serviceId, path);
        return this.getHttp().patch(fullUrl, data).pipe(map((response: any) => response));
    }

    /**
     * Envía una solicitud DELETE al servidor.
     * @param path la ruta relativa en el servidor, esta ruta se completa usando la url de las configuraciones.
     */
    protected httpDelete<T>(serviceId: string, path: string, ...params: any[]): Observable<T> {
        if (params.length > 0) {
            path = this.format(path, params);
        }
        let fullUrl = this.completeURL(serviceId, path);
        return this.getHttp().delete(fullUrl).pipe(map((response: any) => response));
    }

    protected getFromLocalStorage<T>(key: string, defaultValue: T = null): T {
        const value = localStorage.getItem(key);
        if (value == null || value.length == 0) { return defaultValue; }
        return JSON.parse(value);
    }

    protected saveInLocalStorage(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    protected randomElement<T>(...params: T[]): T {
        return params[Math.floor(Math.random() * (params.length))];
    }


}
