<h1 class="mat-typography" mat-dialog-title>{{ estado == 2? 'Gracias' : 'Tu opinión es muy importante'}}</h1>
<div class="mat-typography" mat-dialog-content>
    <div class="content">
        <zona-lock-layer [locked]="estado == 1">
            <ng-container *ngIf="estado != 2">
                <p>Envíanos un mensaje describiendo tu solicitud, siempre las tenemos en cuenta.</p>
                <mat-radio-group class="opciones" aria-label="Selecciona una opción" [(ngModel)]="tipoMensaje">
                    <mat-radio-button *ngFor="let o of tiposMensaje" [value]="o.id">{{o.label}}</mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="mensaje" appearance="fill">
                    <mat-label>Mensaje</mat-label>
                    <textarea rows="3" placeholder="Tu sugerencia, queja o felicitación..." [(ngModel)]="mensaje" matInput #mensajeInput></textarea>
                    <mat-hint align="end">{{mensajeInput.value.length}}/500</mat-hint>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="estado == 2">
                <p>Tu mensaje ha sido enviado</p>
            </ng-container>
        </zona-lock-layer>
    </div>
</div>
<div class="button-panel mat-typography" mat-dialog-actions>
    <button mat-flat-button color="primary" *ngIf="estado != 2" (click)="enviar()" [disabled]="estado == 1" cdkFocusInitial>Enviar</button>
    <p class="error" *ngIf="mensajeError != null">{{mensajeError}}</p>
    <button mat-button color="primary" *ngIf="estado == 2" (click)="cerrar()">Cerrar</button>
</div>
