
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


function utm() {
  let params = new URLSearchParams(window.location.search)
  let source = params.get('utm_source') || 'organic'
  let medium = params.get('utm_medium') || 'organic'
  let campaign = params.get('utm_campaign') || 'none'
  return { utm_source: source, utm_medium: medium, utm_campaign: campaign }
}
/**
 * AWS Configuration
 */
import { API, Auth } from 'aws-amplify';
// import { AWSKinesisFirehoseProvider } from '@aws-amplify/analytics'
import Storage from '@aws-amplify/storage'

API.configure(environment.awsmobile)
Auth.configure(environment.awsmobile);
// Analytics.addPluggable(new AWSKinesisFirehoseProvider());
// Analytics.configure(environment.awsmobile);
Storage.configure(environment.awsmobile)


// Analytics.autoTrack('session', {
//   enable: true,
//   attributes: () => {
//     return utm()
//   },
//   provider: 'AWSPinpoint'
// });

//End AWS Configuration

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
     platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
   });

import 'hammerjs';
