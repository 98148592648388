import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { AbstractService } from 'src/app/utils/abstract.service';
import { AuthService } from 'src/app/auth/auth.service';
import { InmuebleDTO, InmuebleService } from 'src/app/inmuebles/inmueble.service';
import { RestQueryBuilder } from 'src/app/utils/rest.utils';
import { Page } from 'src/app/utils/utils';
import { AuthDialogService } from '../../auth/auth-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { GuestFavoriteDialogComponent, DataGuestDialog, DataGuestDialogoResult } from './guest-favorite-dialog/guest-favorite-dialog.component';
import { FavoritesSyncService } from './favoritiesSync.service';
import { UserInfo } from '../../auth/auth.service';



@Injectable({ 'providedIn': 'root' })
export class FavoritesService extends AbstractService {

  private favoritos = new ElementReferenceList<string>("like");
  private ocultos = new ElementReferenceList<string>("dislike");

  readonly onListChange = new EventEmitter();


  private observdoresInmuebles: Map<string, BehaviorSubject<any>>;
  private observadoresFavoritos: BehaviorSubject<any>;


  constructor(private auth: AuthService,
    private dialog: MatDialog,
    private inmueble: InmuebleService
  ) {
    super();
    this.favoritos.onChange.subscribe(_ => this.onChange());
    this.ocultos.onChange.subscribe(_ => this.onChange());
    this.observdoresInmuebles = new Map<string, BehaviorSubject<any>>();


    this.auth.userChange.subscribe((user: UserInfo) => this.sincronizarAuthUser(user));

  }

  private onChange() {

    this.onListChange.emit();
    var total = {
      total: this.totalFavoritos(),
      codigos: this.listarFavoritosCodigo()
    };
    if (!this.observadoresFavoritos) {
      this.observadoresFavoritos = new BehaviorSubject<any>(total);
    } else {
      this.observadoresFavoritos.next(total);
    }

    if (this.auth.loggedIn) {
      //si esta logueado sincronizar los favoritos del remoto con el del local
      this.sincronizarAuthRemoteWithLocal().subscribe((response) => {

      });
    }
  }


  agregarFavorito(id: string) {

    if (!this.auth.loggedIn) {
      //logica de sincronizacion
      const dialogFav = this.dialog.open(GuestFavoriteDialogComponent, { data: { delete: false } });
      dialogFav.afterClosed().subscribe((result: DataGuestDialogoResult) => {

        if(result.result){
          //igual si selccionar o no se agrega a favoritos
          //para cuando se loguee se sincronice
          this._agregarFavorito(id);
          if (result.auth) {
            //como se cerro por auth entonces lo que se debe hacer es los
            //favoritos del local toca combinarlos con el remoto entoncs
            //se deja una bandera en el local storage
            localStorage.setItem("zhSynFavs", "1");
          }
        }

      });

      return;
    }

    this._agregarFavorito(id);


  }

  private _agregarFavorito(id: string) {
    this.favoritos.addElement(id);
    this.ocultos.removeElement(id);

    if (!this.observdoresInmuebles.has(id)) {
      this.observdoresInmuebles.set(id, new BehaviorSubject<any>(FavoriteState.LIKE));
    } else {
      this.observdoresInmuebles.get(id).next(FavoriteState.LIKE);
    }



  }

  obtenerEstadoElemento(id: string): number {
    if (this.favoritos.containsElement(id)) {
      return FavoriteState.LIKE;
    }
    if (this.ocultos.containsElement(id)) {
      return FavoriteState.DISLIKE;
    }
    return FavoriteState.NOTHING;
  }

  cambiarEstadoFavorito(id: string): number {
    if (this.isFavorito(id)) {
      this.removerFavorito(id);
      return FavoriteState.NOTHING;
    }
    this.agregarFavorito(id);
    return FavoriteState.LIKE;
  }

  get favoriteCount(): number {
    return this.favoritos.count();
  }

  get hiddenCount(): number {
    return this.ocultos.count();
  }

  totalFavoritos(): number {
    return this.favoritos.count();
  }

  cambiarEstadoOculto(id: string): number {
    if (this.isOculto(id)) {
      this.removerElementoOculto(id);
      return FavoriteState.NOTHING;
    }
    this.ocultarElemento(id);
    return FavoriteState.DISLIKE;
  }

  removerFavorito(id: string) {


    /*
    if (!this.auth.loggedIn) {
      const dialogFav = this.dialog.open(GuestFavoriteDialogComponent, { data: { delete: true } });
      dialogFav.afterClosed().subscribe((result: DataGuestDialog) => {
        this._removerFavorito(id);
      })
      return;
    }

    */
    this._removerFavorito(id);


  }

  private _removerFavorito(id: string) {
    this.favoritos.removeElement(id);
    if (!this.observdoresInmuebles.has(id)) {
      this.observdoresInmuebles.set(id, new BehaviorSubject<any>(FavoriteState.NOTHING));
    } else {
      this.observdoresInmuebles.get(id).next(FavoriteState.NOTHING);
    }

  }


  removerTodosFavoritos(): Observable<boolean> {
    this.favoritos.clear();
    return of(true);
  }

  isFavorito(id: string): boolean {
    return this.favoritos.containsElement(id);
  }


  listarFavoritos(pagina: number, seleccionar: string[], tamPagina: number = 10): Observable<Page<InmuebleDTO>> {
    //TODO: Implementar ordenamiento en favoritos
    //TODO: [Seguridad]: Es posible ver inmuebles inactivos a través de este servicio???

    const codigos = this.listarFavoritosCodigo();
    return this.buscarInmuebles(codigos, pagina, seleccionar, tamPagina)
  }

  public listarFavoritosCodigo() {
    return this.favoritos.listAll().filter(f => typeof (f) == 'string' && f.startsWith("ZH"));
  }

  private buscarInmuebles(codigos: string[], pagina: number, seleccionar: string[], tamPagina: number = 10): Observable<Page<InmuebleDTO>> {

    const result = Page.fromArray(codigos, tamPagina, pagina)
    const query = new RestQueryBuilder().in("codigo", result.content).pageSize(tamPagina).select(...seleccionar).build()
    return this.inmueble.buscar(query).pipe(map(p => {
      const mapaInmuebles = p.content.reduce((c, n) => ({ ...c, [n.codigo]: n }), {})
      return result.mapContent(i => mapaInmuebles[i] || { codigo: i, eliminado: true })
    }))
  }

  ocultarElemento(id: string) {
    this.favoritos.removeElement(id);
    this.ocultos.addElement(id);

    if (!this.observdoresInmuebles.has(id)) {
      this.observdoresInmuebles.set(id, new BehaviorSubject<any>(FavoriteState.DISLIKE));
    } else {
      this.observdoresInmuebles.get(id).next(FavoriteState.DISLIKE);
    }
  }

  removerElementoOculto(id: string) {
    this.ocultos.removeElement(id);
    if (!this.observdoresInmuebles.has(id)) {
      this.observdoresInmuebles.set(id, new BehaviorSubject<any>(FavoriteState.NOTHING));
    } else {
      this.observdoresInmuebles.get(id).next(FavoriteState.NOTHING);
    }
  }

  limpiarElementosOcultos(): Observable<boolean> {
    this.ocultos.clear();
    return of(true);
  }

  isOculto(id: string): boolean {
    return this.ocultos.containsElement(id);
  }


  listarOcultos(pagina: number, seleccionar: string[], tamPagina: number = 10) {
    const ids = this.ocultos.listAll()
    return this.buscarInmuebles(ids, pagina, seleccionar, tamPagina)
  }

  observar(codigoInmu: string): Observable<any> {
    if (this.isFavorito(codigoInmu)) {
      if (!this.observdoresInmuebles.has(codigoInmu)) {
        this.observdoresInmuebles.set(codigoInmu, new BehaviorSubject<any>(FavoriteState.LIKE));
      }
    } else {
      if (!this.observdoresInmuebles.has(codigoInmu)) {
        this.observdoresInmuebles.set(codigoInmu, new BehaviorSubject<any>(FavoriteState.NOTHING));
      }
    }

    return this.observdoresInmuebles.get(codigoInmu).asObservable();
  }

  observarFavoritos(): Observable<any> {
    if (!this.observadoresFavoritos) {
      this.observadoresFavoritos = new BehaviorSubject<any>({
        total: this.totalFavoritos()
      });
    }
    return this.observadoresFavoritos.asObservable();
  }

  /**
   * Se encarga de dejar en el local solo los que viene por el parametro
   * @param codigos
   */
  private sincronizarLocal(codigos: string[]) {
    this.favoritos.sync(codigos);
  }

  private sincronizarAuthUser(user: UserInfo) {
    this.listarFavoritosAuthRemote().subscribe((response) => {


      //favoritos del server
      var favList = response.content.map((fav) => {
        return fav.codigoInmueble;
      });

      //ahora como lo logueo toca sabir si se deben
      //combinar los favoritos o solo dejar los del remotos
      //la logica es que si esta la bandera activada
      // es por que el usuario recien se logueo y puede
      //que tenga favoritos en local que guardo sin loguearse

      var debeCombinar = localStorage.getItem("zhSynFavs");

      if (debeCombinar === "1") {
        var favoritosLocal = this.listarFavoritosCodigo();

        var totalFavoritos = favList.concat(favoritosLocal);
        //se deja un array unico de favoritos
        var unicosFavoritos: string[];
        unicosFavoritos = [...new Set<string>(totalFavoritos)];
        this.sincronizarLocal(unicosFavoritos);
        localStorage.removeItem("zhSynFavs");
      } else {
        this.sincronizarLocal(favList);
      }


    });
  }

  private listarFavoritosAuthRemote(): Observable<any> {
    return this.apiGet('favoritos', '/favoritos');
  }

  private agregaFavoritoAuthRemote(codigoInmueble: string): Observable<any> {
    return this.apiPost('favoritos', '/favoritos', {
      codigoInmueble: codigoInmueble
    });

  }

  private eliminarFavoritoAuthRemote(codigoIn: string): Observable<any> {
    return this.apiDelete('favoritos', '/favoritos/' + codigoIn);
  }

  private sincronizarAuthRemoteWithLocal(): Observable<any> {

    return this.apiPost('favoritos', '/favoritos/sincronizar', {
      inmuebles: this.listarFavoritosCodigo()
    });
  }



}

export class FavoriteState {
  static readonly NOTHING = 0;
  static readonly LIKE = 1;
  static readonly DISLIKE = 2;
  static readonly UNKNOWN = 3;

}
export class ElementReferenceList<T> {
  private elements: Array<T> = [];
  private loadedVersion = -1
  readonly onChange = new EventEmitter();
  readonly addItemEvent = new EventEmitter<T>();
  readonly removeItemEvent = new EventEmitter<T>();
  constructor(private readonly name: string) {
  }

  addElement(value: T) {
    this.load()
    if (this.elements.includes(value)) {
      return;
    }
    this.elements.push(value);
    this.save();
    this.addItemEvent.emit(value);
  }

  clear() {
    this.load()
    if (this.elements.length == 0) return;
    this.elements = [];
    this.save();
  }

  count() {
    this.load()
    return this.elements.length;
  }

  listAll() {
    this.load()
    return [...this.elements];
  }

  removeElement(value: T) {
    this.load()
    let idx = this.elements.indexOf(value);
    if (idx > -1) {
      this.elements.splice(idx, 1);
      this.save();
      this.removeItemEvent.emit(value);
    }
  }

  containsElement(element: T): boolean {
    this.load()
    return this.elements.includes(element);
  }

  save() {
    this.load()
    this.loadedVersion++
    localStorage.setItem(this.name, JSON.stringify(this.elements));
    localStorage.setItem(`${this.name}.version`, `${this.loadedVersion}`);
    this.onChange.emit();
  }

  get storageVersion(): number {
    let version = localStorage.getItem(this.name + ".version")
    if (version == null || isNaN(parseInt(version))) return 0
    return parseInt(version)
  }

  load(): Array<T> {
    if (this.loadedVersion == this.storageVersion) {
      return
    }
    this.elements = [];
    let list = localStorage.getItem(this.name);
    this.loadedVersion = this.storageVersion
    if (list == null) return;
    this.elements = JSON.parse(list);
  }
  sync(v: T[]) {
    this.load();
    this.elements = v;
    this.save();
  }

}
