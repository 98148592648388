import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { AdInmuebleComponent } from './ad-inmueble/ad-inmueble.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [AdInmuebleComponent],
  exports: [AdInmuebleComponent],
  imports: [CommonModule, NgxSkeletonLoaderModule,MatButtonModule,RouterModule]
})
export class AdsInmuebleModule {

}
