import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeccionComponent } from './seccion/seccion.component';



@NgModule({
  declarations: [SeccionComponent],
  exports: [SeccionComponent],
  imports: [
    CommonModule
  ]
})
export class ZonaSeccionModule { }
