import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { CognitoUser } from '@aws-amplify/auth';
import Auth from '@aws-amplify/auth';
import { AuthService } from '../auth.service';

@Component({
  selector: 'zona-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {


  hide = true;
  errorMessage = null;
  isLoading = false;
  @Input()
  user:CognitoUser;

  @Output()
  signedIn = new EventEmitter();

  hidePassword = true;
  changePasswordErrorMessage = null;
  newPasswordForm = new FormGroup({
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
  })

  get passwordInput() { return this.newPasswordForm.get('password'); }
  constructor(private auth:AuthService) { }

  ngOnInit(): void {
  }

  changePassword()
  {
    if (!this.newPasswordForm.valid) {
      return;
    }

    let newPassword = this.newPasswordForm.controls['newPassword'].value;

    this.auth.newPassword(this.user,newPassword).then(user => {
      this.signedIn.emit();
    }).catch(error => {
      console.log(error);
    })


  }

  getPasswordInputError() {
    if (this.passwordInput?.hasError('required')) {
      return 'La contraseña es requerida.';
    }
  }

}
