<div class="filters-container">
    <div class="filters-container">
        <!-- Tipo de gestion -->
        <div class="btn-actions" fxLayout="row nowrap" fxLayoutGap="10px" fxLayoutAlign="center center">
            <div class="btn-wrapper" fxLayout="column">
                <button class="btn" [ngClass]="comprarInmueble ? 'zona-btn-bluedark' : 'zona-btn-basic'"
                    (click)="comprarInmueble = true">{{'ZonaSearch.comprar' | translate}}</button>
                <i class="fas fa-caret-down" *ngIf="comprarInmueble"></i>
            </div>
            <div class="btn-wrapper" fxLayout="column">
                <button [ngClass]="!comprarInmueble ? 'zona-btn-bluedark' : 'zona-btn-basic'"
                    (click)="comprarInmueble = false" class="btn">{{'ZonaSearch.arrendar' | translate}}</button>
                <i class="fas fa-caret-down" *ngIf="!comprarInmueble"></i>
            </div>
        </div>
        <!-- Filtros -->
        <div class="search-container">
            <div class="search-bar">
                <div class="filters" fxLayoutGap fxLayoutGap.gt-sm="10px" fxLayout="row wrap"
                    fxLayoutAlign="space-between start">
                    <!-- Ubicacion -->
                    <zona-region-picker fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="20" class="region rounded">
                    </zona-region-picker>
                    <!-- Tipo inmueble -->

                    <mat-form-field appearance="fill" fxFlex-lt-md="100" fxFlex.gt-md="20"
                        class="tipo-inmueble rounded">
                        <mat-label>{{'ZonaSearch.tipoInmueble' | translate}}</mat-label>
                        <mat-select [formControl]="tipoInmuebleControl">
                            <mat-option *ngFor="let v of tiposInmueble" [value]="v.id">{{v.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="tipoInmuebleControl.hasError('required')">Por favor selecciona el {{'ZonaSearch.tipoInmueble' | translate | lowercase}}</mat-error>
                    </mat-form-field>
                    <!-- Calificacion -->
                    <div fxLayout="column" fxLayoutAlign="center none" class="calificacion-container">
                      <span>Certificado</span>
                      <mat-slide-toggle  [(ngModel)]="conCertificado"></mat-slide-toggle>
                    </div>
                    <!-- Acciones -->
                    <button fxShow fxHide-gt-xs mat-stroked-button fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="15"
                        class="btn-avanzado" (click)="avanzadoVisible = !avanzadoVisible">
                        Avanzado <i class="fas fa-ellipsis-v ml5"></i>
                    </button>
                    <button mat-flat-button fxFlex="100" fxFlex.gt-xs="49" fxFlex.gt-sm="20" class="btn-buscar"
                        color="primary" (click)="doSearch($event)">
                        <b>Buscar</b> <i class="fas fa-search ml10"></i>
                    </button>

                    <!-- Panel de filtros -->
                    <div class="filtros-avanzados" [ngStyle]="{display: avanzadoVisible? 'block': 'none'}">
                        <div fxLayout="row" fxLayoutAlign="end center" class="mb10" fxShow fxHide.gt-xs>
                            <button mat-icon-button (click)="avanzadoVisible = false">
                                <i class="fal fa-times"></i>
                            </button>
                        </div>
                        <h3>Básico</h3>
                        <div class="filtros-numericos" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0" fxLayoutGap.gt-xs="10px">
                            <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs>
                                <mat-label>{{'Inmuebles.detalles.caracteristicas.habitaciones' | translate }}</mat-label>
                                <mat-select [(ngModel)]="filtroHabitaciones">
                                    <mat-option value=""></mat-option>
                                    <mat-option *ngFor="let i of [1,2,3,4,5]" [value]="i">{{i}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs>
                                <mat-label>{{'Inmuebles.detalles.caracteristicas.baños' | translate }}</mat-label>
                                <mat-select [(ngModel)]="filtroBanos">
                                    <mat-option value=""></mat-option>
                                    <mat-option *ngFor="let i of [1,2,3,4,5]" [value]="i">{{i}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs>
                                <mat-label>{{'Inmuebles.detalles.caracteristicas.parqueaderos' | translate }}</mat-label>
                                <mat-select [(ngModel)]="filtroParqueaderos">
                                    <mat-option value=""></mat-option>
                                    <mat-option *ngFor="let i of [1,2,3,4,5]" [value]="i">{{i}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs>
                                <mat-label>Código</mat-label>
                                <input matInput [(ngModel)]="filtroCodigo">
                            </mat-form-field>
                        </div>
                        <h3>Precio</h3>
                        <div fxLayout="row wrap" fxLayoutGap="0"  fxLayoutGap.gt-xs="10px">
                            <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs>
                                <mat-label>Precio desde</mat-label>
                                <span matPrefix>$ &nbsp;</span>
                                <input matInput suffix=" COP" mask="separator" [(ngModel)]="filtroPrecioDesde">
                            </mat-form-field>
                            <div fxHide fxShow.gt-xs fxLayout="row" fxLayoutAlign="center center">
                                <span>—</span>
                            </div>
                            <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs>
                                <mat-label>Precio hasta</mat-label>
                                <span matPrefix>$ &nbsp;</span>
                                <input matInput suffix=" COP" mask="separator" [(ngModel)]="filtroPrecioHasta">
                            </mat-form-field>
                        </div>
                        <h3>Caracteristicas</h3>
                        <div fxLayout="row wrap" fxLayoutGap="20px grid" class="caracteristicas">
                            <mat-checkbox fxFlex="100" fxFlex.gt-xs="25" fxFlex.gt-sm="20"
                                *ngFor="let c of caracteristicas" (change)="cambiarCaracteristica($event, c.clave)">
                                {{c.etiqueta}} </mat-checkbox>
                        </div>
                        <div class="button-panel">
                            <button mat-stroked-button type="button" (click)="avanzadoVisible = false">Ocultar</button>
                            <button mat-flat-button (click)="doSearch($event)" color="primary">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Mas opciones -->
        <p class="texto-extras mb20">¿Qué estás buscando?</p>
        <div fxLayout="row" fxLayoutAlign="center stretch">
            <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px grid" class="servicios">
                <div *ngFor="let opcion of masOpciones" fxFlex="50" fxFlex.gt-sm>
                    <a class="servicio" [routerLink]="opcion.url" [rel]="opcion.nofollow? 'nofollow': ''">
                        <i class="fal mr5" [class]="opcion.icono"></i> <span>{{opcion.nombre}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
