import { Interface } from "readline"

export interface Lenguaje{
  codigo:string,
  codigoPais:string,
  nombre:string,
  nombrePais: string,
  logo:string,
  icono:string,
  tel:any
}
export class Traducciones{



  public static getLenguajeDefault():Lenguaje
  {
    return {
      codigo: 'es_CO',
      codigoPais: "co",
      nombrePais: "Colombia",
      nombre: 'Colombiano',
      logo: '/assets/icons/paises/co.svg',
      icono: '🇨🇴',
      tel: {
          indicativo:'+57'
      }
    }
  }

  public static getLenguajes():Lenguaje[]
  {
    return [
      this.getLenguajeDefault(),
      {
        codigo: 'es_AR',
        codigoPais:"ar",
        nombre: 'Argentino',
        nombrePais: "Argentina",
        logo: '/assets/icons/paises/ar.svg',
        icono: '🇦🇷',
        tel: {
          indicativo:'+54'
        }
      },
      {
        codigo: 'es_CL',
        codigoPais: "cl",
        nombre: 'Chileno',
        nombrePais: "Chile",
        logo: '/assets/icons/paises/cl.svg',
        icono: '🇨🇿',
        tel: {
          indicativo:'+56'
        }
      },
      {
        codigo: 'es_PE',
        codigoPais: "pe",
        nombre: 'Peruano',
        nombrePais: "Perú",
        logo: '/assets/icons/paises/pe.svg',
        icono: '🇵🇫',
        tel: {
          indicativo:'+51'
        }
      },
      {
        codigo: 'es_EC',
        codigoPais: "ec",
        nombre: 'Ecuatoriano',
        nombrePais: "Ecuador",
        logo: '/assets/icons/paises/ec.svg',
        icono: '🇪🇨',
        tel: {
          indicativo:'+593'
        }
      },
      {
        codigo: 'es_MX',
        codigoPais: "mx",
        nombre: 'Mexicano',
        nombrePais: "Mexico",
        logo: '/assets/icons/paises/mx.svg',
        icono: '🇲🇽',
        tel: {
          indicativo:'+52'
        }
      }


    ];
  }


}
