import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { LatLng } from './geohash';
import * as ngeohash from 'ngeohash'
import { FormArray, FormControl, FormGroup } from '@angular/forms';

export class EntityRef {
    public label: string;
    public id: number;
    constructor(id: number, label: string) {
        this.label = label;
        this.id = id;
    }
}

export class EntityReference<T> {
    constructor(public id: T,
        public label: string,
        public description: string = null) {
    }
}

export class ParamUtil {
    static addQueryParams(router: Router, route: ActivatedRoute, current: any, newParams: any) {
        const all = DTOUtil.clone(current);
        for (const key of Object.keys(newParams)) {
            const value = newParams[key];
            if (value == null) {
                delete all[key];
            } else {
                all[key] = newParams[key];
            }
        }
        router.navigate([], { relativeTo: route, queryParams: all });
    }
}

export class PerformanceTimer {
    private readonly start: number;
    constructor(public readonly name: string) {
        this.start = performance.now()
    }
    end() {
        let end = performance.now()
        return end - this.start
    }
}


export class FormUtils {

    static debugFormErrors(form: FormGroup) {
        const errors = {}
        FormUtils.getFormErrors(form, [], errors)
        return errors
    }

    static markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();
            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }

    static isFormGroupValid(formGroup: FormGroup): boolean {
        FormUtils.markFormGroupTouched(formGroup)
        return formGroup.valid
    }

    private static getFormErrors(form: any, path: string[], errors: any) {
        if (form instanceof FormGroup || form instanceof FormArray) {
            const { controls } = form
            for (let key in controls) {
                this.getFormErrors(controls[key], [...path, key], errors)
            }
        } else if (form instanceof FormControl) {
            if (form?.errors && Object.keys(form.errors).length > 0)
                errors[path.join('.')] = form
        }
    }
}

export class DTOUtil {

    public static clone(dto: any): any {
        return JSON.parse(JSON.stringify(dto));
    }

    public static removeProperty(object: any, property: string) {
        if (object == null) return
        let index = property.indexOf('.')
        if (index < 0) {
            delete object[property]
        }
        let value = object[property.substr(0, index)]
        return this.removeProperty(value, property.substr(index + 1))
    }

    public static trim(object: any): any {
        if (object == null) return undefined
        if (typeof object === 'string') {
            object = object.trim()
            return object.length == 0 ? undefined : object
        }
        if (Array.isArray(object)) {
            const array = object.map(e => DTOUtil.trim(e)).filter(e => e)
            return array.length == 0 ? undefined : array
        }
        if (typeof object === 'object') {
            const copy = {}
            for (let key in object) {
                const value = DTOUtil.trim(object[key])
                if (!DTOUtil.isEmpty(value)) {
                    copy[key] = value
                }
            }
            return copy
        }
        return object
    }

    public static isEmpty(value: any) {
        if (value == null) return true
        if (Array.isArray(value) && value.length == 0) return true
        if (typeof value == "string" && value.trim().length == 0) return true
        if (typeof value == 'object' && Object.keys(value).length == 0) return true
        return false
    }

    public static removeProperties(dto: any, ...propiedades: Array<string>) {
        propiedades.forEach(p => this.removeProperty(dto, p))
    }

    public static merge(one: any, another: any): any {
        let merged = DTOUtil.clone(one)
        Object.keys(another).forEach(k => merged[k] = another[k])
        return merged
    }

    public static getPropertySafe(object: any, property: string, defaultValue: any = null): any {
        if (object == null) return defaultValue
        let index = property.indexOf('.')
        if (index < 0) {
            if (typeof object[property] == 'undefined') {
                return defaultValue
            }
            return object[property]
        }
        let value = object[property.substr(0, index)]
        return this.getPropertySafe(value, property.substr(index + 1))
    }

    public static equals(x: any, y: any): boolean {
        if (x === y) { return true; }
        // if both x and y are null or undefined and exactly the same

        if (!(x instanceof Object) || !(y instanceof Object)) { return false; }
        // if they are not strictly equal, they both need to be Objects

        for (let p in x) {
            if (!x.hasOwnProperty(p)) { continue; }
            // other properties were tested using x.constructor === y.constructor

            if (!y.hasOwnProperty(p)) { return false; }
            // allows to compare x[ p ] and y[ p ] when set to undefined

            if (x[p] === y[p]) { continue; }
            // if they have the same strict value or identity then they are equal

            if (typeof (x[p]) !== 'object') { return false; }
            // Numbers, Strings, Functions, Booleans must be strictly equal

            if (!DTOUtil.equals(x[p], y[p])) { return false; }
            // Objects and Arrays must be tested recursively
        }

        for (const p in y) {
            if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) { return false; }
            // allows x[ p ] to be set to undefined
        }
        return true;
    }
}

export interface LabeledValue<T> {
    value: T
    label: string
    detail?: string
}

export class MessageSeverity {
    public static readonly INFO = 1000;
    public static readonly WARN = 2000;
    public static readonly ERROR = 3000;
}

export class LoadingState {
    static readonly UNKNOWN = 'UNKNOWN';
    static readonly LOADING = 'LOADING';
    static readonly LOADED = 'LOADED';
    static readonly ERROR = 'ERROR';
}

export class Message {
    constructor(public readonly message: string,
        public readonly title: string = null,
        public readonly severity: number = MessageSeverity.INFO,
        public readonly icon: string = null) {
    }
}

export class Action {
    constructor(public readonly label: string,
        public readonly action: () => void,
        public readonly icon?: string,
        public readonly color?: string) {
    }
}

export class DeviceUtils {

    public static hasMouse(): boolean {
        return matchMedia('(pointer:fine)').matches;
    }

    public static copyToClipboard(text: string) {
        const el = document.createElement('textarea');
        el.value = text;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }
}

export class StringUtils {
    public static pluralize(text: string): string {
        if (text.endsWith('a') || text.endsWith('o')) {
            return text + "s";
        }
        return text + 'es';
    }

    public static removeAccents(text: string): string {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    public static replaceSpaces(text: string): string {
        return text.replace(/ /g, '_');
    }

    /**
     * Check if two strings are equals ignoring case and removing accents.
     */
    public static equalsNormalize(one: string, another: string): boolean {
        one = one.trim();
        one = this.removeAccents(one);
        another = another.trim();
        another = this.removeAccents(another);
        return this.equalsIgnoreCase(one, another);
    }

    public static equalsIgnoreCase(one: string, another: string): boolean {
        one = one.toLocaleLowerCase();
        another = another.toLocaleLowerCase();
        return one == another;
    }
    public static isUpper(character: string): boolean {
        return character.toUpperCase() == character;
    }

    /**
     * Genera y retorna un string aleatorio de una longitud indicada
     */
    public static random(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}

export class GeoUtils {


    static calcDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
        const R = 6371_000; // m
        let toRad = v => v * Math.PI / 180;
        let dLat = toRad(lat2 - lat1);
        let dLon = toRad(lon2 - lon1);
        lat1 = toRad(lat1);
        lat2 = toRad(lat2);

        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }

    static geoHashEncode(lat: number, lon: number, precision: number = 6): string {
        return ngeohash.encode(lat, lon, precision)
    }

    static geoHashDecode(geohash: string): LatLng {
        const latlng = ngeohash.decode(geohash)
        return { lat: latlng.latitude, lng: latlng.longitude }
    }

    static randomCenter(geohash: string): LatLng {
        const [minlat, minlon, maxlat, maxlon] = ngeohash.decode_bbox(geohash)
        const deltaLon = maxlon - minlon
        const deltaLat = maxlat - minlat
        const lat = minlat + ((deltaLat / 4) + ((deltaLat / 2) * Math.random()))
        const lng = minlon + (deltaLon / 4 + ((deltaLon / 2) * Math.random()))
        return { lat, lng }
    }

    static getRandomLocation(latitude: number, longitude: number, maxRadiusMeters: number, minRadiusMeters: number = 0): LatLng {

        let getRandomCoordinates = function (minRadiusMeters: number, maxRadiusMeters: number, uniform) {
            let radius = maxRadiusMeters - minRadiusMeters
            // Generate two random numbers
            let a = Math.random(),
                b = Math.random();

            // Flip for more uniformity.
            if (uniform) {
                if (b < a) {
                    let c = b;
                    b = a;
                    a = c;
                }
            }

            // It's all triangles.
            return [
                ((b * radius) + minRadiusMeters) * Math.cos(2 * Math.PI * a / b),
                ((b * radius) + minRadiusMeters) * Math.sin(2 * Math.PI * a / b)
            ];
        };

        let randomCoordinates = getRandomCoordinates(minRadiusMeters, maxRadiusMeters, true);

        // Earths radius in meters via WGS 84 model.
        let earth = 6378137;

        // Offsets in meters.
        let northOffset = randomCoordinates[0],
            eastOffset = randomCoordinates[1];

        // Offset coordinates in radians.
        let offsetLatitude = northOffset / earth,
            offsetLongitude = eastOffset / (earth * Math.cos(Math.PI * (latitude / 180)));

        // Offset position in decimal degrees.
        return {
            lat: latitude + (offsetLatitude * (180 / Math.PI)),
            lng: longitude + (offsetLongitude * (180 / Math.PI))
        }
    };
}



export class WordUtils {

    private static readonly COMMON_NAMES = {
        'bogotá': 'Bogotá', 'bogota': 'Bogotá', 'medellin': 'Medellín', 'cali': 'Cali', 'neiva': 'Neiva',
        'bucaramanga': 'Bucaramanga', 'rionegro': 'Rionegro', 'armenia': 'Armenia', 'pereira': 'Pereira',
        'villavicencio': 'Villavicencio'
    };


    public static normalizeMayus(text: string): string {
        let upperCount = 0;
        for (let i = 0; i < text.length; i++) {
            if (StringUtils.isUpper(text.charAt(i))) { upperCount++; }
        }
        if (upperCount > (text.length * 0.3)) {
            text = WordUtils.applyNormalization(text);
        }
        return text;
    }

    private static applyNormalization(text: string): string {
        let normalized = text.toLowerCase();
        normalized = normalized[0].toUpperCase() + normalized.slice(1);
        for (const key in this.COMMON_NAMES) {
            normalized = normalized.replace(key, this.COMMON_NAMES[key]);
        }
        return normalized;
    }

    public static capitalize(text: string): string {
        if (text == null) return null
        if (text.match(/^([a-z]\.)+$/)) { //Siglas - Pej: D.C
            return text.toUpperCase()
        }
        if (text.match(/^([A-Z]\.)+$/)) { //Siglas - Pej: D.C
            return text
        }
        let lower = text.toLocaleLowerCase()
        return lower.charAt(0).toUpperCase() + lower.slice(1)
    }

    public static splitWords(text: string): string[] {
        if (text == null) return []
        return text.split(' ');
    }

    public static capitalizeWords(text: string): string {
        let words = this.splitWords(text);
        return words.map(w => WordUtils.capitalize(w)).join(" ")
    }

    public static truncate(value: string, limit = 25, completeWords = false, ellipsis = '...'): string {
        if (completeWords) {
            limit = value.substr(0, limit).lastIndexOf(' ');
        }
        return value.length > limit ? value.substr(0, limit) + ellipsis : value;
    }
}

export class ServiceId {
    public static readonly PROPERTY_SEARCH = 'propertySearch';
    public static readonly GEO = 'geo';
    public static readonly CONTACT = 'contact';
}

export class Sort {
    sorted = false;
    unsorted = true;
    empty = true;
}

export class FormatUtil {

    public static moneda(value: number | any, digitsInfo: string = '1.0-0', locale: string = 'es-CO'): string {
        let currency = 'COP'
        let numericValue: number = null
        if (value == null) return null
        if (typeof value !== 'number') {
            currency = value?.moneda?.toUpperCase() || 'COP'
            numericValue = value.valor || 0
        } else {
            numericValue = value
        }
        if (numericValue == null) {
            return null
        }
        let symbol = getCurrencySymbol(currency, 'narrow')
        return formatCurrency(numericValue, locale, symbol, currency, digitsInfo) + ` ${currency === 'COP' ? '' : currency}`;

    }
}

export class XORC {
    static salt = 41341235
    static encrypt(str) {
        var result = '';
        for (var i = 0; i < str.length; i++) {
            result += String.fromCharCode(XORC.salt ^ str.charCodeAt(i));
        }
        return result;
    }

    static decrypt(hash) {
        var result = '';
        for (var i = 0; i < hash.length; i++) {
            result += String.fromCharCode(this.salt ^ hash.charCodeAt(i));
        }
        return result;
    }
}


export class UUID {
    static randomUUIDv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    static randIdString(length: number) {
        var result = '';
        var characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}

export interface PageOptions {
    page?: number
    pageSize?: number
    /**
     * Una string en la cual se indican los campos de ordenamiento.
     * Para ordenar de forma descendente, se debe anteponer el signo menos (-).
     * Por ejemplo:
     * sort: '-name,age,birthDate'
     */
    sort?: string
    select?: Array<string>
}

export class PageOptionUtils {
    static toApiPageOptions(pageOptions: PageOptions): any {
        let opts: any = {}
        opts.__select = pageOptions.select != null ? pageOptions.select.join(",") : "__all"
        if (pageOptions.page) opts.__page = pageOptions.page
        if (pageOptions.pageSize) opts.__pageSize = pageOptions.pageSize
        if (pageOptions.sort) opts.__sort = pageOptions.sort
        opts.__countElements = true
        return opts
    }
}

export class ArrayUtil {
    static firstOrDefault<T>(array: Array<T>, defValue: T): T {
        if (array != null && array.length > 0) return array[0]
        return defValue
    }

    static equals(a, b) {
        if (a.length != b.length)
            return false;

        // comapring each element of array
        for (var i = 0; i < a.length; i++)
            if (a[i] != b[i])
                return false;

        return true;
    }

    /** assumes array elements are primitive types
    * check whether 2 arrays are equal sets.
    * @param  {} a1 is an array
    * @param  {} a2 is an array
    */
    static areArraysEqualSets(a1, a2) {
        const superSet = {};
        for (const i of a1) {
            const e = i + typeof i;
            superSet[e] = 1;
        }

        for (const i of a2) {
            const e = i + typeof i;
            if (!superSet[e]) {
                return false;
            }
            superSet[e] = 2;
        }

        for (let e in superSet) {
            if (superSet[e] === 1) {
                return false;
            }
        }

        return true;
    }
}

export class URLUtil {

    static mergeQueryParams(params): string {
        const actual = URLUtil.getParamsAsObject()
        Object.keys(params).forEach((k: string) => {
            const value = params[k]
            if (value == null) delete actual[k]
            else actual[k] = value
        })
        const query = Object.keys(actual).reduce((prev, curr) => `${prev}&${curr}=${encodeURIComponent(actual[curr])}`, "")
        return query
    }
    static getParamsAsObject(): any {
        const { search } = window.location
        if (search.length < 2) return {}
        return search.substring(1, search.length)
            .split("&").map(p => p.split("="))
            .filter(p => p.length == 2)
            .reduce((obj, item) => ({ ...obj, [item[0]]: decodeURIComponent(item[1]) }), {})
    }

    static encodeName(value: string): string {
        value = value.toLowerCase()
        value = value.replace(/ /g, "-")
        value = StringUtils.removeAccents(value)
        value = value.substring(0, 128)
        value = value.replace(/-{2,}/g, '-')
        return encodeURIComponent(value)
    }
}

export class Page<T> {

    content: Array<T> = [];
    totalElements: number = 0;
    number: number = 0;
    pageSize: number = 0;

    static empty<T>(): Page<T> {
        return new Page<T>();
    }

    mapContent<E>(mapper: (T) => E): Page<E> {
        const page = new Page<E>()
        page.pageSize = this.pageSize
        page.number = this.number
        page.totalElements = this.totalElements
        page.content = this.content.map(mapper)
        return page
    }

    static mapResponse(obs: Observable<any>): Observable<Page<any>> {
        return obs.pipe(map(m => Page.fromPage(m)));
    }

    static fromApiResult(result: any): Page<any> {
        return Page.fromPage(result)
    }

    static fromPage(oldPage: Page<any>): Page<any> {
        let page = new Page<any>();
        page.content = oldPage.content;
        page.number = oldPage.number || oldPage['page'];
        page.pageSize = oldPage.pageSize;
        page.totalElements = oldPage.totalElements;
        return page;
    }

    static fromArray<T>(data: Array<T>, pageSize: number, pageNumber: number) {
        const page = new Page<T>()
        page.totalElements = data.length
        let start = pageSize * (pageNumber - 1);
        let end = start + pageSize
        end = end > data.length ? data.length : end
        page.content = data.splice(start, end)
        page.number = pageNumber
        page.pageSize = pageSize
        return page;
    }

    public static randomPage<T>(total: number, pageSize: number, pageNumber: number, generator: (number) => T): Observable<Page<T>> {
        const page = new Page<T>();
        const offset = pageSize * pageNumber;
        page.totalElements = total;
        page.number = pageNumber;
        page.pageSize = pageSize;

        let pageResults = 0;
        if (offset < total) {
            pageResults = (total - offset) > pageSize ? pageSize : (total - offset);
            let id = offset + 1;
            page.content = Array(pageResults).fill(1).map(_ => {
                return generator(id++);
            });
        }

        return of(page).pipe(delay(3000));
    }

    getTotalPages(): number {
        if (this.pageSize < 1) return 0;
        return Math.ceil(this.totalElements / this.pageSize);
    }

    isEmpty(): boolean {
        return !this.content || this.content.length < 1;
    }

    isLast(): boolean {
        return this.number >= (this.getTotalPages() - 1);
    }
}


export class FileUtils {

    public static imageExist(urlFIle) {

        var image = new Image();

        image.src = urlFIle;

        if (!image.complete || (image.complete && image.width === 0)) {
            return false;
        }

        return true;

    }

}
