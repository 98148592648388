<form [formGroup]="signinForm" class="zona-form mat-typography">
    <p class="zona-text-md"><b>Bienvenido</b></p>
    <!-- <p>Ingresa al instante con tus redes sociales</p> -->
    <div class="social-login" *ngIf="!hideSocialLogin">
        <button type="button" mat-stroked-button class="btn-facebook" (click)="signInWithFacebook()">
            <i class="fa fa-facebook-f fa-button-icon"></i>
            Ingresar con Facebook
        </button>
        <button type="button" mat-stroked-button class="btn-google" color="warn" (click)="signInWithGoogle()">
            <i class="fa fa-google fa-button-icon"></i>
            Ingresar con Google
        </button>
        <div class="separator full-row"><span>Ó</span></div>
    </div>
    <div class="mt20 mb20" *ngIf="hideSocialLogin">
        <p>Por favor inicia sesión con tu cuenta de Zona Hábitat.</p>
    </div>
    <!-- <p>Ingresa con tu cuenta de Zona Hábitat</p> -->
    <mat-form-field class="full-row mtn10" appearance="fill">
        <mat-label>Correo electrónico</mat-label>
        <input matInput type="email" formControlName="email" autocomplete="email" required>
        <i matSuffix class="far fa-envelope"></i>
        <!-- <mat-hint *ngIf="!emailInput.value">Ingresa tu correo</mat-hint> -->
        <mat-error *ngIf="!emailInput.valid">
            {{getEmailInputError()}}
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-row mtn5">
        <mat-label>Contraseña</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required>
        <i matSuffix (click)="hide = !hide" [ngClass]="hide? 'fal fa-eye' : 'fal fa-eye-slash'"></i>
        <mat-error *ngIf="!passwordInput.valid">
            {{getPasswordInputError()}}
        </mat-error>
    </mat-form-field>
    <div *ngIf="errorMessage != null" class="full-row spaced error-msg">
        {{errorMessage}}
    </div>
    <div class="w-100 text-right">
        <span style="text-decoration: none;" class="zona-text-sm mouse-pointer" (click)="forgotPassword.emit()">Recuperar
            Contraseña</span>
        <!-- <button type="button" color="primary" mat-button>Recuperar Contraseña</button> -->
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="text-center w-100" >
      <mat-spinner *ngIf="isLoading" diameter="40"></mat-spinner>
    </div>

    <button *ngIf="!isLoading"  class="full-row mtn10"
     mat-flat-button class="full-row spaced"
     color="primary"
     (click)="signIn()"
     [disabled]="!signinForm.valid">Entrar</button>
</form>
