<div class="inmueble oculto" *ngIf="inmueble !== null && estadoFavorito === 2">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mr10">{{inmueble?.titulo}}</span>
        <button mat-stroked-button matTooltip="Mostrar" style="min-width: 7em; width: 7em" color="accent" *ngIf="estadoFavorito === 2" (click)="cambiarOculto($event)">
            <i class="fal fa-eye zona-text-lg"></i> Mostrar
        </button>
    </div>
</div>
<div class="inmueble" *ngIf="inmueble == null" fxLayout="column">
    <ngx-skeleton-loader [theme]="{'border-radius': '8px', height: '260px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ height: '12px', width: '130px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ 'margin-bottom': '5px', 'max-width': '220px'}"></ngx-skeleton-loader>
    <ngx-skeleton-loader [theme]="{ height: '12px', 'max-width': '190px'}"></ngx-skeleton-loader>
    <div>
        <ngx-skeleton-loader [theme]="{ width: '130px', 'margin-right': '20px'}" count="3"></ngx-skeleton-loader>
    </div>
</div>
<div class="inmueble" (click)="abrirDetalleInmueble()" [attr.data-inmueble-tipo]="inmueble?.tipoInmueble"  [attr.data-inmueble-negocio]="inmueble?.venta? 'venta' : 'arriendo'"
    [attr.data-inmueble-ciudad]="inmueble?.ubicacion?.ciudad?.nombre" [attr.data-inmueble-codigo]="inmueble?.codigo"
    fxLayout="column" fxHide [fxShow]="inmueble != null && estadoFavorito != 2">
    <zona-imagen [url]="this.inmueble?.imagenes" class="img-container" alto="250">
        <div class="capa-imagen" fxLayout="column" fxFlexAlign="space-between">
            <div class="header" fxLayout="row">
                <div fxFlex fxLayout="row wrap">
                    <label class="gray-badge mr10" *ngIf="inmueble?.arriendo">
                        {{'ZonaSearch.arriendo' | translate | titlecase}}<span *ngIf="inmueble?.venta == true">: {{inmueble?.precios?.arriendo | zonacurrency}} /mes</span>
                    </label>
                    <label class="gray-badge mr10" *ngIf="inmueble?.venta">
                        {{'ZonaSearch.venta' | translate | titlecase}}<span *ngIf="inmueble?.arriendo == true">: {{inmueble?.precios?.venta | zonacurrency}}</span>
                    </label>
                    <label class="green-badge" *ngIf="inmueble?.destacado == true">Destacado</label>
                </div>
                <button mat-flat-button class="boton-ubicar" *ngIf="inmueble?.ubicacion?.visible" (click)="ubicarInmuebleEnMapa($event)" matTooltip="Ubicar en mapa">
                    <i class="fal fa-location"></i>
                </button>
            </div>
            <div fxFlex="100"></div>
            <div class="footer">
                <div fxLayout="row" class="zona-align-center zona-content-between w-100">
                    <div class="precios" fxLayout="column">
                        <div class="principal">
                            <span>{{precioPrincipal}}</span>
                        </div>
                    </div>
                    <div class="zona-d-flex">
                        <button mat-icon-button style="color: white;" matTooltip="Agregar Favorito" *ngIf="estadoFavorito !== 1" (click)="cambiarFavorito($event)">
                            <i class="fal fa-heart zona-text-lg"></i>
                        </button>
                        <button mat-icon-button class="favorito" matTooltip="Quitar Favorito" *ngIf="estadoFavorito === 1" (click)="cambiarFavorito($event)">
                            <i class="fas fa-heart zona-text-lg"></i>
                        </button>
                        <button mat-icon-button style="color: white;" matTooltip="Ocultar" *ngIf="estadoFavorito !== 2" (click)="cambiarOculto($event)">
                            <i class="fal fa-eye-slash zona-text-lg"></i>
                        </button>
                        <button mat-icon-button style="color: white;" matTooltip="Mostrar" *ngIf="estadoFavorito === 2" (click)="cambiarOculto($event)">
                            <i class="fal fa-eye zona-text-lg"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </zona-imagen>
    <div class="info-container mt15" fxLayout="column" fxFlex>
        <span class="indicador">{{utilsService.firstLetterMayus(inmueble?.tipoInmueble) || '--'}}</span>
        <a [routerLink]="'/busqueda/inmueble/' + inmueble?.codigo" [queryParams]="{origen: 'lista', oferta:inmueble?.arriendo? 'arriendo' : 'venta' }"
            (click)="$event.stopPropagation()" target="_blank">
            <h4 class="mt15"><b>{{inmueble?.titulo || '--'}}</b></h4>
        </a>
        <p class="zona-text-xsm">
            <i class="far fa-map-marker-alt mr5"></i>
            {{obtenerUbicacionFormateada()}}
        </p>
        <div class="caracteristicas" fxflex>
            <span class="mr20">Habitaciones: {{inmueble?.caracteristicas?.habitaciones || '--'}}</span>
            <span class="mr20">Baños: {{inmueble?.caracteristicas?.banos || '--'}}</span>
            <span class="">Área:
                {{inmueble?.caracteristicas?.areaTotal || inmueble?.caracteristicas?.areaConstruida || '--'}}m²</span>
        </div>
    </div>
    <!-- <div class="footer">
        <button mat-button class="zona-text-xsm" color="primary" (click)="detallesInmueble()">VER DETALLES</button>
    </div> -->
</div>
