<div class="container">


    <zona-seccion [titulo]="titulo">
      <div fxLayout="colum wrap" fxLayoutAlign="center center" class="content">

          <img class="imgNotFount" src="/assets/images/not-fount.svg">
          <p>No hemos encontrado ningún inmueble en este sector con los filtros que indicas, puedes moverte a otra
            ubicación en el mapa o editar los filtros.
          </p>
          <button mat-flat-button color="primary" [routerLink]="['/']">Buscar Inmuebless</button>

      </div>
    </zona-seccion>


</div>
