<mat-nav-list class="mat-typography unselectable" #snav style="margin-top: 75px;">
  <div fxShow fxHide.gt-sm>
    <div mat-subheader class="title-menu">Servicios</div>
    <a *ngFor="let i of secciones" (click)="click.emit()" [rel]="i['nofollow']? 'nofollow' : ''" mat-list-item [routerLinkActive]="'active'" [routerLink]="[i.link]">
      <i [ngClass]="i.icon" class="label-icon"></i>{{i.label}}
    </a>
  </div>
  <div fxShow fxHide.gt-sm>
    <div mat-subheader class="title-menu">Más para ver</div>
    <a *ngFor="let i of items" (click)="click.emit()" [rel]="i['nofollow']? 'nofollow' : ''" mat-list-item [routerLinkActive]="'active'" [routerLink]="[i.link]">
      <i [ngClass]="i.icon" class="label-icon"></i>{{i.label}}
    </a>
  </div>
  <div *ngIf="!user.anonymus && !user.hasRole('aliado')">
    <div mat-subheader class="title-menu">Usuario</div>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/cuenta/informacion">
      <i class="fal fa-tachometer-alt label-icon"></i>Información
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/busqueda/favoritos">
      <i class="fal fa-heart label-icon"></i>Favoritos
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/busqueda/ocultos">
      <i class="fal fa-eye-slash label-icon"></i>Ocultos
    </a>
  </div>
  <div *ngIf="user.hasRole('aliado')">
    <div mat-subheader class="title-menu">Aliado</div>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/aliado/panel-control">
      <i class="fal fa-tachometer-alt label-icon"></i>Panel de control
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/aliado/inmuebles">
      <i class="fal fa-house label-icon"></i>Inmuebles
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/aliado/contactos">
      <i class="fal fa-address-card label-icon"></i>Contactos
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/aliado/referidos">
      <i class="fal fa-money-bill-alt label-icon"></i>Referidos
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/aliado/zonacoins">
      <i class="fal fa-coins label-icon"></i>Zona Coins
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/aliado/perfil">
      <i class="fal fa-address-card label-icon"></i>Perfil
    </a>
  </div>
  <div *ngIf="user.hasRole('admin')">
    <div mat-subheader class="title-menu">Administrador</div>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/admin/inmobiliarias">
      <i class="fal fa-city label-icon"></i>Inmobiliarias
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/admin/leads">
      <i class="fal fa-money-bill-alt label-icon"></i>Referidos
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/admin/reportes/descargar">
      <i class="fal fa-chart-line label-icon"></i>Reportes
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/admin/usuarios/administrar">
      <i class="fal fa-user label-icon"></i>Usuarios
    </a>
  </div>
  <div *ngIf="user.hasRole('colaborador') || user.hasRole('admin') ">
    <div mat-subheader class="title-menu">Colaboradores</div>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/admin/paga-referir/dashboard">
      <i class="fas fa-fire-alt label-icon"></i> PAGA REFERIR
    </a>
    <a (click)="click.emit()" mat-list-item [routerLinkActive]="'active'" routerLink="/admin/zonabitero/">
      <i class="fas fa-users  label-icon"></i> Zonabiteros
    </a>
  </div>
  <div class="suggestion" (click)="openPqrs()" fxShow fxHide.gt-sm>
    <p class="title">¿Felicitaciones, Sugerencias, Quejas?</p>
    <p class="description">Haz click acá, Zona Hábitat te escucha</p>
  </div>
</mat-nav-list>
