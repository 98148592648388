import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NgModuleRef } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSidenavModule } from '@angular/material/sidenav'

import { DatePipe, registerLocaleData } from '@angular/common';
import localeCo from '@angular/common/locales/es-CO';
import { BottomNavModule } from 'ngx-bottom-nav';
import { ZonaCommonModule } from './common/zona-common.module';
import { InterceptorService } from './auth/interceptor.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InteropModule } from './interop/interop.module';
import { UTMInterceptorService } from './interop/utminterceptor.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeModule } from './home/home.module';
import { AppInjector } from './utils/app-injector';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { UtilsModule } from './utils/utils.module';
import { ZonaPqrsModule } from './utils/zona-pqrs/zona-pqrs.module';
import { MatIconModule } from '@angular/material/icon';
//import { IvyGalleryModule } from 'angular-gallery';
import { SitiosModule } from './sitios/sitios.module';
import { ZonaLayoutModule } from './utils/zona-layout/zona-layout.module';
import { BlogRedirectModule } from './blog/blog-redirect/blog-redirect.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ZonaNotFoundHandlerModuleModule } from './utils/zona-not-found-handler-module/zona-not-found-handler-module.module';

registerLocaleData(localeCo);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/','.json');
}
//CHILE ARGENTINA MEXICO ECUADOR PERU COLOMBIA

@NgModule({
  declarations: [AppComponent],
  imports: [
    ZonaCommonModule,
    UtilsModule,
    ZonaLayoutModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatSidenavModule,
    BottomNavModule,
    MatSidenavModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    InteropModule,
    ZonaPqrsModule,
    HomeModule,
    FlexLayoutModule,
    MatIconModule,
    BlogRedirectModule,
    ZonaNotFoundHandlerModuleModule,
    SitiosModule, //TODO: Mover a modulo administrativo
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    DatePipe,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UTMInterceptorService,
    multi: true
  },
  {
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { showError: true }
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(appRef: NgModuleRef<AppModule>) {
    AppInjector(appRef.injector);
  }
}
