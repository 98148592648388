import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { TraduccionService } from '../../traducciones.services';
import { Lenguaje, Traducciones } from '../../traducciones';

@Component({
  selector: 'zona-selector-lenguaje',
  templateUrl: './selector-lenguaje.component.html',
  styleUrls: ['./selector-lenguaje.component.scss']

})
export class SelectorLenguajeComponent implements OnInit {

  lenguajes: Lenguaje[];

  lengujeSelect:Lenguaje;
  public lenguajeSelectCode:String;

  constructor(
    public traduccionesService: TraduccionService

  ) {
    this.lenguajes = traduccionesService.getLenguajes();

    traduccionesService.observarLenguajeActual().subscribe((lengueje: Lenguaje) => {
      this.lengujeSelect = lengueje;
      this.lenguajeSelectCode = lengueje.codigo;
    });
   }

  ngOnInit(): void {
    this.lengujeSelect = this.traduccionesService.getLenguajeActual();
    this.lenguajeSelectCode = this.traduccionesService.getLenguajeActual().codigo;
  }


  cambiarLenguaje($event) {
  console.log("lenguaje",$event);

    this.traduccionesService.establecerLenguajeActual($event);
  }

}
