import { formatCurrency } from '@angular/common';
import { WordUtils, UUID } from '../utils/utils';
import { MathUtil } from '../utils/math.util';


/**
 * Identificadores de los features de la propiedad.
 */
export class FeatureId {
    static readonly PRECIO_VENTA = 0
    static readonly PRECIO_ARRIENDO = 1
    static readonly IVA = 2
    static readonly PRECIO_ADMINISTRACION = 3
    static readonly AREA_TOTAL = 4
    static readonly AREA_CONSTRUIDA = 5
    static readonly HABITACIONES = 6
    static readonly BANOS = 7
    static readonly PARQUEADEROS = 8
    static readonly ESTRATO = 8
    static readonly ADDRESS = 9
    static readonly ADDRESS_2 = 10


}

export class FeatureDisplay {
    icon: string;
    shortName: string;
    featureId: number;
}

const VisibleFeatures: FeatureDisplay[] = [
    { icon: "fas fa-door-closed", shortName: "Hab", featureId: FeatureId.HABITACIONES },
    { icon: "fas fa-bath", shortName: "Baños", featureId: FeatureId.BANOS },
    { icon: "fas fa-parking", shortName: "Parq", featureId: FeatureId.PARQUEADEROS }
    // { icon: "flip_to_back", svgIcon: false, shortName: "Área T", featureId: FeatureId.AREA_TOTAL },
    // { icon: "flip_to_front", svgIcon: false, shortName: "Área C", featureId: FeatureId.AREA_CONSTRUIDA },
]

export class PointDTO {

    private distance: number = null;
    constructor(
        public readonly latitude: number,
        public readonly longitude: number) {
    }

    getDistance(): number {
        if (this.distance == null) {
            this.distance = Math.sqrt(Math.pow(this.latitude, 2) + Math.pow(this.longitude, 2));
        }
        return this.distance;
    }
    hashCode(): number {
        let hash = 7;
        hash = 31 * hash + this.latitude
        hash = 31 * hash + this.longitude
        return hash;
    }
}

/**
 * TODO: Ernesto Buscar referencias a esta clase y eliminarlas
 */
export class BoundingBox {

    private _center: PointDTO;
    private _hashCode: number = null;

    constructor(public readonly north: number, public readonly east: number,
        public readonly west: number, public readonly south: number) {
    }

    getCorners(): PointDTO[] {
        return [
            this.northWest, this.northEast, this.southEast, this.southWest
        ]
    }

    get center() {
        if (this._center == null) {
            this._center = new PointDTO(this.north - this.south, this.east - this.west)
        }
        return this._center;
    }

    get northWest(): PointDTO {
        return new PointDTO(this.north, this.west)
    }
    get northEast(): PointDTO {
        return new PointDTO(this.north, this.east)
    }
    get southEast(): PointDTO {
        return new PointDTO(this.south, this.east)
    }
    get southWest(): PointDTO {
        return new PointDTO(this.south, this.west)
    }
    equals(other: BoundingBox): boolean {
        return other.north == this.north && other.west == this.west
            && other.south == this.south && other.east == this.east;
    }

    get hBoxSize(): number {
        return MathUtil.subtract(this.east, this.west);
    }
    get vBoxSize(): number {
        return MathUtil.subtract(this.north, this.south);
    }

    hashCode(): number {
        if (this._hashCode == null) {
            let hash = 7;
            hash = 31 * hash + this.north;
            hash = 31 * hash + this.west;
            hash = 31 * hash + this.east;
            hash = 31 * hash + this.south;
            this._hashCode = hash;
        }
        return this._hashCode;
    }

    contains(point: PointDTO): boolean {
        let lat = point.latitude;
        let lon = point.longitude;
        return this.west < lat && lat < this.east &&
            this.south < lon && lon < this.north;
    }

    merge(to: BoundingBox): BoundingBox {
        let north = this.north > to.north ? this.north : to.north;
        let south = this.south < to.south ? this.south : to.south;
        let west = this.west < to.west ? this.west : to.west;
        let east = this.east > to.east ? this.east : to.east;
        return new BoundingBox(north, east, west, south);
    }

    clone(): BoundingBox {
        return new BoundingBox(this.north, this.east, this.west, this.south);
    }

    moveEast(): BoundingBox {
        let boxSize = this.hBoxSize;
        let east = MathUtil.sum(this.east, boxSize);    
        return new BoundingBox(this.north, east, this.east, this.south);
    }

    moveNorth(): BoundingBox {
        let boxSize = this.vBoxSize;
        let north = MathUtil.sum(this.north, boxSize);
        return new BoundingBox(north, this.east, this.west, this.north);
    }
    toString(): string {
        return `S: ${this.south} N: ${this.north} W: ${this.west} E: ${this.east}`;
    }
}

/**
 * @deprecated
 */
export interface FeatureDTO {
    id: number;
    label: string;
    value: any;
}

/**
 * TODO: Eliminar referencias a esta clase
 * @deprecated
 */
export class PropertyDTO {
    id: number;
    propertyType: number;
    active: boolean;
    code: string;
    title: string;
    description: string;
    latitude: number;
    longitude: number;
    images: string[];
    bizType: number;
    location: string[];
    publisherId: number;
    features: Array<FeatureDTO> = [];
    private bizPrices: string[] = null;
    private displayFeatures: Array<DisplayFeature> = null;
    private allFeatures: Array<DisplayFeature> = null
    /**
       * Verifica si la propiedad tiene el feature dado.
       * @param id el identificador del feature. Ver FeatureId
       */
    hasFeature(id: number): boolean {
        return this.features.find(f => f.id == id) != null;
    }

    /**
     * Obtiene el valor del feature deseado.
     * @param id el identificador del feature. Ver FeatureId
     */
    getFeatureValue(id: number, defValue: string = null): string {
        let feature = this.features.find(f => f.id == id);
        return feature == null ? defValue : feature.value;
    }

    getFeature(id: number): FeatureDTO {
        return this.features.find(f => f.id == id);
    }

    formattedLocation(): string {
        return this.location ? this.location.join(", ") : "";
    }

    getBusinessPrices(): string[] {
        let bizPrices = [];
        if ((this.bizType == BizTypeId.ARRIENDO || this.bizType == BizTypeId.ARRIENDO_VENTA) && this.hasFeature(FeatureId.PRECIO_ARRIENDO)) {
            let value = this.getNumberFeature(FeatureId.PRECIO_ARRIENDO);
            bizPrices.push("Arriendo: " + formatCurrency(value, "es-CO", "$", "COP", "1.0-0"));
        }
        if ((this.bizType == BizTypeId.VENTA || this.bizType == BizTypeId.ARRIENDO_VENTA) && this.hasFeature(FeatureId.PRECIO_VENTA)) {
            let value = this.getNumberFeature(FeatureId.PRECIO_VENTA);
            bizPrices.push("Precio venta: " + formatCurrency(value, "es-CO", "$", "COP", "1.0-0"))
        }
        return bizPrices;
    }

    getSalesPrice(): number {
        if ((this.bizType == BizTypeId.VENTA || this.bizType == BizTypeId.ARRIENDO_VENTA) && this.hasFeature(FeatureId.PRECIO_VENTA)) {
            let value = this.getNumberFeature(FeatureId.PRECIO_VENTA);
            return value;
        }
        return null;
    }

    getAllFeatures(): Array<DisplayFeature> {
        if (this.allFeatures == null) {
            this.allFeatures = this.features.map(f => ({ icon: 'far fa-check-circle', label: f.label, value: f.value, shortLabel: f.label }))
        }
        return this.allFeatures
    }

    getDisplayFeatures(): Array<DisplayFeature> {
        if (this.displayFeatures == null) {
            this.displayFeatures = this.createDisplayFeatures();
        }
        return this.displayFeatures;
    }

    private createDisplayFeatures(): Array<DisplayFeature> {
        let displayFeatures = [];
        for (let k of VisibleFeatures) {
            let feature = this.getFeature(k.featureId);
            if (feature) {
                displayFeatures.push({ icon: k.icon, label: feature.label, shortLabel: k.shortName, value: feature.value })
            }
        }
        return displayFeatures;
    }

    getNumberFeature(id: number): number {
        let feature = this.features.find(f => f.id == id);
        return feature == null ? 0 : feature.value;
    }

    static fromResult(other: PropertyDTO): PropertyDTO {
        let p = new PropertyDTO();
        Object.keys(other).map(k => p[k] = other[k]);
        p.title = WordUtils.normalizeMayus(p.title);
        p.description = WordUtils.normalizeMayus(p.description);
        return p;
    }
}


export interface CaracteristicaInmueble {
    id: number
    icono?: string
    etiqueta: string
    descripcion?: string
}


export interface GrupoCaracteristicaInmueble {
    clave: string
    icono: string
    etiqueta: string
    descripcion: string
    caracteristicasPermitidas: Set<number>
}

export class DisplayFeature {
    icon: string;
    label: string;
    value: string;
    shortLabel: string;
}

export interface MapResult {
    hiddenElementCount: number
    points: MapResultPoint[]
}

export interface MapResultPoint {
    i: number;
    t: number;
    g: number;
    d: string;
}


export class SearchDTO {

    public readonly searchId: string

    constructor(public readonly propertyTypeId: number,
        public readonly bizTypeId: number,
        public readonly idRegion: number,
        public readonly filters = {}, public displayHiddenElements = false) {
        this.searchId = UUID.randIdString(8)
    }

    getRoomFilter() {
        return this.filters['rooms'] || -1
    }
    getBathFilter() {
        return this.filters['baths'] || -1
    }
    getParkingFilter() {
        return this.filters['parking'] || -1
    }
    getPriceStartingAt() {
        return this.filters['priceStartingAt'] || -1
    }
    getPriceEndingAt() {
        return this.filters['priceEndingAt'] || -1
    }

}



export class BizTypeId {
    static readonly ARRIENDO = 1;
    static readonly VENTA = 2;
    static readonly ARRIENDO_VENTA = 5;
}
