<div class="adHorizontal">
  <div class="adContentH" fxLayout="row" [ngStyle]="{'background-color':colorFondo}">
    <div class="adTextH" fxFlex="grow">
        <div class="slidersH">
          <div class="sliderH sliderH1 descripcion" [ngStyle]="{'color':colorFuente ? colorFuente : null}">
            <p>{{descripcion}}</p>
          </div>
          <div class="sliderH  title">
            <p>{{titulo}}
              </p>
            </div>
        </div>
    </div>
    <a mat-flat-button class="boton" fxFlexAlign="center" color="primary" target="_blank" [routerLink]="[link]">{{textoLink}}</a>
  </div>
</div>
