import { Injectable } from '@angular/core';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Hub, ICredentials } from '@aws-amplify/core';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { EventEmitter } from 'events';
import { Observable, Subject } from 'rxjs';
import { AnalyticsService } from '../utils/analytics.service';

export interface NewUser {
  email: string,
  phone: string,
  password: string,
  firstName: string,
  lastName: string
};

export interface ZonaNewPasswordException {
  user?: CognitoUser;
  code?: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _loggedIn = false;
  public get loggedIn(): boolean {
    return this._loggedIn;
  }

  private setLoggedIn(value: boolean) {
    let old = this._loggedIn
    if (old != value) {
      this._loggedIn = value
    }
  }

  private _authState: Subject<UserInfo | any> = new Subject<CognitoUser | any>();
  authState: Observable<UserInfo | any> = this._authState.asObservable();

  public static SIGN_IN = 'signIn';
  public static SIGN_OUT = 'signOut';
  public static FACEBOOK = CognitoHostedUIIdentityProvider.Facebook;
  public static GOOGLE = CognitoHostedUIIdentityProvider.Google;

  public readonly userCreatedEvent = new EventEmitter();

  private userSubject = new Subject<UserInfo>();
  public readonly userChange = this.userSubject.asObservable();
  private _userInfo = UserInfo.ANONYMUS;
  private userId: string = null
  private initialized = false


  get userInfo(): UserInfo {
    return this._userInfo;
  }


  private initialize() {
    if (!this.initialized) {
      return
    }
    // Auth.configure(environment.awsmobile);
    Hub.listen('auth', (data) => {
      const { channel, payload } = data;
      if (channel === 'auth') {
        let event = payload.event;
        if ("signIn_failure" == event) {
          console.error(payload.message);
        }
        if ("cognitoHostedUI" == event) { // ||signIn  "cognitoHostedUI" == event){
          setTimeout(_ => this.checkUser(), 300);
        }
      }
    });
    Auth.currentAuthenticatedUser().then(e => this.updateUser(e));
    Auth.currentSession().then(e => console.log(e))
    this.initialized = true
  }


  public checkUser() {
    this.initialize()
    Auth.currentAuthenticatedUser().then(e => this.updateUser(e));
  }

  getAccessToken(): Promise<string> {
    this.initialize()
    return Auth.currentSession().then(e => e.getAccessToken().getJwtToken())
  }

  /**
   * Obtiene el id de usuario, este id se genera independientemente si el usuario ha
   * iniciado sesión o no y se usa para llevar analiticas. Si el usuario se autentica
   * se obtendrá el sub, de lo contrario, se usara un id generado.
   */
  getUserId(): string {
    this.initialize()
    return this.userInfo.anonymus ? 'anon' : this.userInfo.sub
  }

  private async updateUser(user: CognitoUser) {
    this.initialize()
    if (user == null) {
      this._userInfo = UserInfo.ANONYMUS;
      this.setLoggedIn(false)
      this.userSubject.next(this._userInfo);
      return;
    }
    return new Promise((resolve, reject) => {
      user.getSession((e: any) => {
        if (e) return reject(e)
        user.getUserAttributes((e, a) => {
          let userInfo = UserInfo.ANONYMUS;
          if (e) {
            reject(e)
          } else {
            userInfo = new UserInfo(user, a);
          }
          if (userInfo == this._userInfo) {
            resolve(undefined)
            return;
          }
          this._userInfo = userInfo;
          this.setLoggedIn(true)
          this.userSubject.next(this._userInfo);
          resolve(undefined)
        });
      })
    });
  }

  async updateUserPhone(phone): Promise<void> {
    if (this.userInfo.phone_number !== phone) {
      await Auth.updateUserAttributes(this.userInfo.cognitoUser, { "phone_number": phone })
    }
  }

  async sendConfirmationSMS(phone): Promise<void> {
    if (this.userInfo.phone_number !== phone) {
      await Auth.updateUserAttributes(this.userInfo.cognitoUser, { "phone_number": phone })
    }
    return Auth.verifyUserAttribute(this.userInfo.cognitoUser, 'phone_number')
  }

  async confirmPhoneNumber(smsCode: string) {
    const result = await Auth.verifyUserAttributeSubmit(this.userInfo.cognitoUser, "phone_number", smsCode)
    if (result === "SUCCESS") {
      this.userInfo.phone_number_verified = true
    }
    return result
  }

  async signUp(user: NewUser): Promise<SignupResult> {
    this.initialize()
    const email = user.email.toLowerCase()
    const result = await Auth.signUp({
      "username": email,
      "password": user.password,
      "attributes": {
        "email": email,
        "given_name": user.firstName,
        "family_name": user.lastName,
        "phone_number": user.phone
      }
    })

    const { user: cognitoUser } = result
    this.userCreatedEvent.emit(email);
    if (result.userConfirmed) {
      await this.updateUser(cognitoUser)
    }
    return result
  }




  async obtenerInformacionUsuario(): Promise<InformacionUsuario> {
    if (!this.loggedIn) {
      return null
    }
    const i = this.userInfo
    return {
      nombre: i.given_name, apellido: i.family_name, genero: i.gender, email: i.email,
      fechaNacimiento: i.birth_date, telefono: i.phone_number
    }
  }

  public getInformacionUsuario(): InformacionUsuario {
    if (!this.loggedIn) {
      return null
    }
    const i = this.userInfo
    return {
      nombre: i.given_name, apellido: i.family_name, genero: i.gender, email: i.email,
      fechaNacimiento: i.birth_date, telefono: i.phone_number
    }
  }


  private formatearTelefono(telefono: string): string {
    telefono = telefono.replace(/\D/g, '')
    if (telefono.startsWith("+")) {
      return telefono
    }
    if (telefono.length == 10) return "+57" + telefono
    if (telefono.length == 7) return "+571" + telefono
    return undefined
  }

  async actualizarInformacionUsuario(informacion: InformacionUsuario) {
    if (!this.loggedIn) {
      throw new Error(`El usuario no está autenticado`)
    }
    const attributes: any = {}
    let telefono = this.formatearTelefono(informacion.telefono)
    if (informacion.nombre) attributes.given_name = informacion.nombre
    if (informacion.apellido) attributes.family_name = informacion.apellido
    if (telefono) attributes.phone_number = telefono
    if (informacion.genero) attributes.gender = informacion.genero
    if (informacion.fechaNacimiento) attributes.birthdate = informacion.fechaNacimiento
    await Auth.updateUserAttributes(this.userInfo.cognitoUser, attributes)
  }

  async confirmEmail(email: string, confirmationCode: string): Promise<any> {
    email = email.toLowerCase()
    const data = await Auth.confirmSignUp(email, confirmationCode)
    if (data !== 'SUCCESS') {
      throw new Error("Error de validación de código")
    }
    return data
  }

  async signIn(username: string, password: string): Promise<CognitoUser | any> {
    this.initialize()
    const user: any = await Auth.signIn(username, password)
    const { challengeName } = user
    if (challengeName === "NEW_PASSWORD_REQUIRED" || challengeName === "NEW_PASSWORD") {
      return new Promise<ZonaNewPasswordException>((resolve, reject) => {
        reject({
          user: user,
          code: 'ZonaNewPasswordException'
        })
      });
    }
    await this.updateUser(user);
    this.setLoggedIn(true);
    return user;
  }

  async newPassword(user: CognitoUser, newPassword: string): Promise<CognitoUser | any> {
    this.initialize();
    const userC: any = await Auth.completeNewPassword(user, newPassword);
    await this.updateUser(userC);
    this.setLoggedIn(true);
    return userC;
  }

  async signOut(): Promise<void> {
    this.initialize()
    //return this.doLoginAsAnon();
    await Auth.signOut();
    await this.updateUser(null)
    this.setLoggedIn(false)
  }

  socialSignIn(provider: CognitoHostedUIIdentityProvider): Promise<ICredentials> {
    this.initialize()
    let state = `${window.location.pathname}${window.location.search}`
    if (typeof (Storage) !== "undefined") {
      localStorage.setItem("zh.prelogin.state", state);
    }
    return Auth.federatedSignIn({
      'provider': provider,
      //'customState': state
    });
  }

}

export interface InformacionUsuario {
  nombre?: string
  apellido?: string
  telefono?: string
  genero?: string
  fechaNacimiento?: string
  email?: string;
}


export interface InformacionUsuario {

}

export interface SignupResult {
  user: CognitoUser;
  userConfirmed: boolean;
  userSub: string;
  codeDeliveryDetails: CodeDeliveryDetails;
}

export interface CodeDeliveryDetails {
  AttributeName: string;
  DeliveryMedium: string;
  Destination: string;
}

export class UserInfo {

  public static readonly ANONYMUS = new UserInfo(null, null);

  picture: string;
  sub: string;
  given_name: string;
  family_name: string;
  email: string;
  email_verified: boolean = false;
  phone_number: string;
  phone_number_verified: boolean = false;
  roles: string[];
  name?: string
  gender: string
  birth_date: string;
  payload: any;
  username: string;
  newUser: boolean = false

  get anonymus(): boolean {
    return this.cognitoUser == null || this.email == "zonanon@yopmail.com";
  }

  hasRole(name: string): boolean {
    if (this.payload == null) return false;
    let groups: string[] = this.payload['cognito:groups']
    if (groups == null) {
      return false;
    }
    return groups.indexOf(name) > -1;
  }

  constructor(public readonly cognitoUser: CognitoUser,
    private readonly attributes: CognitoUserAttribute[], newUser: boolean = false) {
    if (cognitoUser == null || attributes == null) {
      return;
    }
    this.newUser = newUser;
    this.username = cognitoUser.getUsername()
    this.payload = cognitoUser.getSignInUserSession().getAccessToken().payload;

    for (let attribute of attributes) {
      let key = attribute.getName();
      if (key == 'sub') this.sub = attribute.getValue();
      if (key == 'given_name') this.given_name = attribute.getValue();
      if (key == 'name') this.name = attribute.getValue();
      if (key == 'family_name') this.family_name = attribute.getValue();
      if (key == 'phone_number') this.phone_number = attribute.getValue();
      if (key == 'email') this.email = attribute.getValue();
      if (key == 'gender') this.gender = attribute.getValue();
      if (key == 'birthdate') this.birth_date = attribute.getValue();
      if (key == 'email_verified') this.email_verified = attribute.getValue() === "true";
      if (key == 'phone_number_verified') this.phone_number_verified = attribute.getValue() === "true";
      if (key == 'picture') {
        let value = attribute.getValue();
        if (value.startsWith("{")) {
          let parsed = JSON.parse(value);
          if (parsed['data'] != null && parsed['data']['url'] != null) {
            //Facebook Graph https://developers.facebook.com/docs/graph-api/reference/v7.0/user/picture
            this.picture = parsed['data']['url'];
          }
        } else {
          this.picture = attribute.getValue();
        }
      }
    }
  }
}
