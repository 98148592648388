import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UTMService } from './utm.service';

@Injectable({ providedIn: 'root' })
export class UTMInterceptorService implements HttpInterceptor {

  constructor(private utmService: UTMService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let utm = this.utmService.currentUTM();
    let processed = req;
    if (utm != null) {
      let formatted = `${utm.source}::${utm.medium}::${utm.campaign}`;
      let headers = req.headers.append('utm', formatted);
      processed = req.clone({headers: headers})
    }
    return next.handle(processed);
  }
}
