import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagenComponent } from './imagen/imagen.component';



@NgModule({
  declarations: [ImagenComponent],
  exports: [ImagenComponent],
  imports: [
    CommonModule
  ]
})
export class ZonaImagenModule { }
