import { Component, EventEmitter, OnInit } from '@angular/core';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { PqrsService } from 'src/app/utils/zona-pqrs/pqrs.service';
import { AuthService, UserInfo } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent extends AbstractComponent implements OnInit {
  showMenu = false;

  items = [
    { label: 'Revista', link: '/revista/1', icon: 'fal fa-book' },
    { label: 'Herramientas', link: '/herramientas', icon: 'fal fa-toolbox' },
    { label: 'Consultoría', link: 'consultoria', icon: 'fal fa-question' },
    { label: 'Aliados', link: '/aliado/login', icon: 'fal fa-handshake' }
  ]

  secciones = [
    { label: 'Buscar Propiedades', link: '/busqueda/mapa/apartamentos/arriendo/MPIO_11001-Bogota', nofollow: true, icon: 'fal fa-home' },
    { label: 'Fianzas', link: '/servicios/fianzas', icon: 'fal fa-file-signature' },
    { label: 'Financiación', link: 'financiacion', icon: 'fal fa-calculator' },
    { label: 'Seguros de Hogar', link: '/seguros', icon: 'fal fa-shield-check' },
    { label: 'Remodelar', link: '/servicios/remodelar', icon: 'fal fa-paint-brush-alt' }
  ]




  click = new EventEmitter()
  user: UserInfo = UserInfo.ANONYMUS;


  constructor(private pqrs: PqrsService, private auth: AuthService) {
    super();
    this.user = auth.userInfo;
    auth.userChange.subscribe(u => {
      this.user = u
    })
  }

  ngOnInit() { }

  openPqrs() {
    this.pqrs.abrirDialogoPqrs();
  }
}
