import { kMaxLength } from 'buffer'

export interface RestQuery {

}

export class RestQueryBuilder {
    private filter: RestQuery = {}

    eq(property: string, value: any): RestQueryBuilder {
        return this.addFilter('eq', property, value)
    }
    ne(property: string, value: any): RestQueryBuilder {
        return this.addFilter('ne', property, value)
    }
    lt(property: string, value: number): RestQueryBuilder {
        return this.addFilter('lt', property, value)
    }
    lte(property: string, value: number): RestQueryBuilder {
        return this.addFilter('lte', property, value)
    }
    gt(property: string, value: number): RestQueryBuilder {
        return this.addFilter('gt', property, value)
    }
    gte(property: string, value: number): RestQueryBuilder {
        return this.addFilter('gte', property, value)
    }
    exists(property: string, value: boolean): RestQueryBuilder {
        return this.addFilter('exists', property, value)
    }
    startsWith(property: string, value: string): RestQueryBuilder {
        return this.addFilter('sw', property, value)
    }
    startsWithIgnoreCase(property: string, value: string): RestQueryBuilder {
        return this.addFilter('isw', property, value)
    }
    startsWithIn(property: string, ...values: string[]): RestQueryBuilder {
        return this.addFilter('swin', property, this.arrayToValue(values))
    }
    startsWithInIgnoreCase(property: string, ...values: string[]): RestQueryBuilder {
        return this.addFilter('iswin', property, this.arrayToValue(values))
    }
    contains(property: string, value: string): RestQueryBuilder {
        return this.addFilter('co', property, value)
    }
    containsIn(property: string, ...values: string[]): RestQueryBuilder {
        return this.addFilter('coin', property, this.arrayToValue(values))
    }
    containsIgnoreCase(property: string, value: string): RestQueryBuilder {
        return this.addFilter('ico', property, value)
    }
    containsInIgnoreCase(property: string, ...values: string[]): RestQueryBuilder {
        return this.addFilter('icoin', property, this.arrayToValue(values))
    }
    regExp(property: string, regexp: string): RestQueryBuilder {
        return this.addFilter('re', property, regexp)
    }
    regExpIn(property: string, ...regexp: string[]): RestQueryBuilder {
        return this.addFilter('rein', property, this.arrayToValue(regexp))
    }
    regExpIgnoreCase(property: string, regexp: string): RestQueryBuilder {
        return this.addFilter('ire', property, regexp)
    }
    regExpInIgnoreCase(property: string, ...regexp: string[]): RestQueryBuilder {
        return this.addFilter('irein', property, this.arrayToValue(regexp))
    }
    //TODO implement: all,   iswin, coin, icoin, rein, irein
    in(property: string, ...options: any[]): RestQueryBuilder {
        return this.addFilter('in', property, this.arrayToValue(options))
    }

    notIn(property: string, ...options: any[]): RestQueryBuilder {
        return this.addFilter('nin', property, this.arrayToValue(options))
    }


    /**
     * Permite aplicar ordenamiento a la consulta
     * @example
     * query.sortBy('-age') //<- sort by age desc
     * query.sortBy('age', 'name') //<- sort by age and then by name
     */
    sortBy(...properties: string[]): RestQueryBuilder {
        this.filter['__sort'] = properties.join(',')
        return this
    }

    pageSize(size: number): RestQueryBuilder {
        this.filter['__pageSize'] = size
        return this
    }

    pageNumber(number: number): RestQueryBuilder {
        this.filter['__page'] = number
        return this
    }

    countElements(count: boolean): RestQueryBuilder {
        this.filter['__countElements'] = count
        return this
    }

    select(...properties: string[]): RestQueryBuilder {
        this.filter['__select'] = this.arrayToValue(properties)
        return this
    }

    selectAll(): RestQueryBuilder {
        return this.select('__all')
    }

    /**
     * Remueve todos los filtros aplicados a la propiedad dada
     */
    removeFilters(propertyName: string) {
        Object.keys(this.filter).forEach( k => {
            if(k.startsWith(`${propertyName}__`)){
                delete this.filter[k]
            }
        })
    }

    private addFilter(operator: string, property: string, value: any): RestQueryBuilder {
        this.filter[`${property}__${operator}`] = this.toValue(value)
        return this
    }

    private arrayToValue(array: any[]): string {
        return array.map(t => this.toValue(t)).join(',')
    }

    private toValue(value: any): string {
        return `${value}`
    }

    build(): RestQuery {
        return this.filter
    }
}
