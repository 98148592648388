import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AbstractService } from '../utils/abstract.service';

@Injectable({'providedIn':'root'})
export class EventDispatcherService extends AbstractService {

  private events = new Map<string, Subject<any>>();

  subscribeTo(eventName: string): Observable<any> {
    return this.getSubject(eventName);
  }

  private getSubject(eventName: string) {
    let subject = this.events.get(eventName);
    if (subject == null) {
      subject = new Subject<any>();
      this.events.set(eventName, subject);
    }
    return subject;
  }

  sendEvent(eventName: string, args: any = {}) {
    this.logD(`Sending event: ${eventName}`);
    this.getSubject(eventName).next(args);
  }

}
