import { Component, OnInit, Output, EventEmitter, Inject, Type } from '@angular/core';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { AuthService } from '../auth.service';
import { SignupRequest } from '../sign-up/sign-up.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CognitoUser } from '@aws-amplify/auth';
import { Router } from '@angular/router';



@Component({
  selector: 'zona-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss']
})
export class AuthDialogComponent extends AbstractComponent implements OnInit {

  @Output()
  closeRequested = new EventEmitter();
  loggedIn = false;
  signupRequest: SignupRequest;
  emailRecuperacion: string

  state = 'signin';
  constructor(private dialogRef: MatDialogRef<AuthDialogComponent>, private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: AuthDialogOptions, private snack: MatSnackBar, private router: Router) {
    super();
    if (auth.loggedIn && data.requirePhoneConfirmation) {
      this.state = 'phoneConfirm'
    }
  }

  userNewPassword: CognitoUser;
  ngOnInit(): void {
  }

  onAccountCreated() {
    if (this.data && this.data.requirePhoneConfirmation) {
      this.state = 'phoneConfirm'
    } else {
      this.snack.open("Bienvenido a Zona Hábitat", null, { duration: 2000 })

      //SI viene data y el data requerido es devolucion con tipo de resultado
      if (this.data && this.data.withResultType) {
        this.dialogRef.close({
          status: true,
          type: TypeResultAuthDialog.REGISTER
        });
      } else {
        this.dialogRef.close(true);
      }


    }
  }

  onPasswordChanged() {
    this.loggedIn = true;
    this.dialogRef.close(true)
  }

  signin() {
    this.state = 'signin';
  }

  signup() {
    this.state = 'signup';
  }
  closeDialog() {
    this.dialogRef.close(false);
  }

  forgotPassword() {
    this.state = 'forgotPassword';
  }

  isUserLogged() {
    return this.auth.loggedIn
  }

  isPhoneConfirmed() {
    return this.auth.loggedIn && this.auth.userInfo.phone_number_verified
  }

  onSignedIn() {
    if (this.data && this.data.requirePhoneConfirmation && !this.isPhoneConfirmed()) {
      this.state = 'phoneConfirm'
    } else {
      this.snack.open("Bienvenido a Zona Hábitat", null, { duration: 2000 })
      //SI viene data y el data requerido es devolucion con tipo de resultado
      if (this.data && this.data.withResultType) {
        this.dialogRef.close({
          status: true,
          type: TypeResultAuthDialog.LOGIN
        });
      } else {
        this.dialogRef.close(true);
      }

      if (this.auth.userInfo.hasRole("aliado")) {
        this.router.navigate(["/aliado/panel-control"]);
      }

    }
  }

  onPhoneConfirmed() {
    this.snack.open("Gracias, tu número ha sido confirmado", null, { duration: 2000 })
    if (this.data && this.data.withResultType) {
      this.dialogRef.close({
        status: true,
        type: TypeResultAuthDialog.PHONECONFIRM
      });
    } else {
      this.dialogRef.close(true);
    }

  }

  onUnconfirmedUser(userEmail) {
    this.state = 'signup';
    this.signupRequest = {
      accountCreated: true,
      emailConfirmed: false,
      userEmail: userEmail
    }
  }

  onRecoverPassword(email: string) {
    this.state = "forgotPassword"
    this.emailRecuperacion = email
  }

  onNewPassword(user: CognitoUser) {
    this.userNewPassword = user;
    this.state = 'newPassword';
  }
}

export interface AuthDialogOptions {
  requirePhoneConfirmation?: boolean
  hideSocialLogin?: boolean
  disableAccountCreation?: boolean,
  withResultType?: boolean
}

export enum TypeResultAuthDialog {
  REGISTER = "REGISTER",
  LOGIN = "LOGIN",
  LOGINSOCIAL = "LOGINSOCIAL",
  PHONECONFIRM = "PHONECONFIRM"
}
export interface ResultAuthDialog {
  status: boolean,
  type: string
}
