import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { SelectorLenguajeComponent } from './components/selector-lenguaje/selector-lenguaje.component';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SelectorLenguajeComponent],
  exports: [SelectorLenguajeComponent],
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
     MatSelectModule,
     NgSelectModule
    ]
})
export class TraduccionesModule {

}
