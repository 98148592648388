import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PqrsDialogComponent } from './pqrs-dialog/pqrs-dialog.component';
import { PqrsFloatingButtonComponent } from './pqrs-floating-button/pqrs-floating-button.component';
import { ZonaLockLayerModule } from '../zona-lock-layer/zona-lock-layer.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    PqrsDialogComponent,
    PqrsFloatingButtonComponent
  ],
  exports: [PqrsFloatingButtonComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    ZonaLockLayerModule
  ]
})
export class ZonaPqrsModule { }
