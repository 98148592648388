import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialog } from './dialogs/confirm.dialog';
import { ErrorComponent } from './error/error.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SocialNetworksComponent } from './social-networks/social-networks.component';
import { TruncatePipe } from './truncate.pipe';
import { VarDirective } from './var.directive';
import { FullScreenMessageComponent } from './full-screen-message/full-screen-message.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationService } from './notification.service';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { COVID19Component } from './covid19/covid19.component';
import { ZonaCurrencyPipe } from './zona-currency.pipe';
import { RouterModule } from '@angular/router';
import { NumeroATextoPipe } from './numero-a-texto.directive';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { VersionUpdateDialogComponent } from './version-check/version-update-dialog/version-update-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { VisibilityChangeDirective } from './directives/visibility-change.directive';
import { MessageDialog } from './dialogs/message.dialog';
import { ErrorDialog } from './dialogs/error.dialog';
import { HelpDialog } from './dialogs/help.dialog';
import { CalculadoraCreditoComponent } from './calculadora-credito/calculadora-credito.component';
import { MatSliderModule } from '@angular/material/slider';
import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  declarations: [
    ErrorComponent,
    PageNotFoundComponent,
    VarDirective,
    TruncatePipe,
    NumeroATextoPipe,
    SocialNetworksComponent,
    ConfirmDialog,
    MessageDialog,
    ErrorDialog,
    HelpDialog,
    FullScreenMessageComponent,
    BlockCopyPasteDirective,
    COVID19Component,
    ZonaCurrencyPipe,
    VersionUpdateDialogComponent,
    VisibilityChangeDirective,
    CalculadoraCreditoComponent
  ],
  providers: [
    NotificationService
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    FlexLayoutModule,
    RouterModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatRadioModule,
    MatListModule,
    MatInputModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    DeviceDetectorModule,
    NgxMaskModule,
  ],
  exports: [
    ErrorComponent,
    VarDirective,
    TruncatePipe,
    NumeroATextoPipe,
    SocialNetworksComponent,
    FullScreenMessageComponent,
    BlockCopyPasteDirective,
    ZonaCurrencyPipe,
    VisibilityChangeDirective,
    ConfirmDialog,
    MessageDialog,
    ErrorDialog,
    CalculadoraCreditoComponent
  ],
  entryComponents: [ConfirmDialog]
})
export class UtilsModule { }
