import { Component, OnInit } from '@angular/core';
import { PqrsService } from '../pqrs.service';
import { interval } from 'rxjs';

@Component({
  selector: 'zona-pqrs-floating-button',
  templateUrl: './pqrs-floating-button.component.html',
  styleUrls: ['./pqrs-floating-button.component.scss']
})
export class PqrsFloatingButtonComponent implements OnInit {

  currentFace = 1
  displayDetail = false;
  timeout = null
  constructor(private pqrs: PqrsService) { }

  ngOnInit(): void {
    this.changeFace();
  }

  changeFace() {
    this.currentFace = Math.floor(Math.random() * 5)
  }

  openModal() {
    this.pqrs.abrirDialogoPqrs()
  }

}
