import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { AbstractService } from '../utils/abstract.service';
import { GRUPOS_CARACTERISTICA } from './tipos-caracteristica.json';
import { delay, tap, map } from 'rxjs/operators';
import { DTOUtil, UUID, Page, EntityRef, EntityReference, WordUtils } from '../utils/utils';
import { RestQuery, RestQueryBuilder } from '../utils/rest.utils';
import { BusquedaDTO } from '../busqueda/busqueda-avanzada/filtros/filtros.component';
import { CacheManager } from '../utils/CacheManager';
import { AnalyticsService } from '../utils/analytics.service';
import { TraduccionService } from '../utils/traducciones/traducciones.services';
import { a } from '@aws-amplify/ui';
import { TitleCasePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class InmuebleService extends AbstractService {

  cache = CacheManager.getCache('inmuebles')

  /**
   * @deprecated No se usarán más referencias con id numérico TODO: Eliminar
   */
  tiposGestion: Array<EntityRef> = [
    new EntityRef(1, "Arriendo"), new EntityRef(2, "Venta")
  ];

  private tiposNegocio: Array<EntityReference<string>> = [
    new EntityReference("arriendo", "Arriendo"), new EntityReference("venta", "Venta")
  ];

  private subjectDestacados: Subject<any> = new Subject<any>();
  private destacados: any = undefined;
  private cargandoDestacados: boolean = false


  tiposInmueble: Array<EntityRef> = [
    new EntityRef(2, "Apartamento"),
    new EntityRef(1, "Casa"),
    new EntityRef(4, "Local"),
    new EntityRef(9, "Apartaestudio"),
    new EntityRef(8, "Habitación"),
    new EntityRef(7, "Oficina"),
    new EntityRef(6, "Finca"),
    new EntityRef(5, "Lote"),
    new EntityRef(3, "Bodega"),
    new EntityRef(12, "Casa Campestre"),
    new EntityRef(11, "Consultorio"),
    new EntityRef(10, "Edificio"),
    new EntityRef(13, "Parqueadero")
  ];
  tiposInmuebleTraducidos: Array<EntityRef>;

  tiposInmueble2: Array<EntityReference<string>> = [
    new EntityReference("apartamento", "Apartamento"),
    new EntityReference("casa", "Casa"),
    new EntityReference("local", "Local"),
    new EntityReference("apartaestudio", "Apartaestudio"),
    new EntityReference("habitacion", "Habitación"),
    new EntityReference("oficina", "Oficina"),
    new EntityReference("finca", "Finca"),
    new EntityReference("lote", "Lote"),
    new EntityReference("bodega", "Bodega"),
    new EntityReference("casa_campestre", "Casa Campestre"),
    new EntityReference("consultorio", "Consultorio"),
    new EntityReference("edificio", "Edificio"),
    new EntityReference("parqueadero", "Parqueadero")
  ];

  tiposInmuebleTraducidos2: Array<EntityReference<string>>;

  constructor(private analytics: AnalyticsService, private translate: TraduccionService) {
    super()
    this.cache.averageEntryCount = 200;
  }
  /**
   * @deprecated usar getTiposNegocio
   */
  getTiposGestion(): Observable<Array<EntityRef>> {
    return of(this.tiposGestion);
  }

  getTiposNegocio(): Observable<Array<EntityReference<string>>> {

    return this.translate.getTraduccionSub("ZonaSearch.tiposNegocio")
      .pipe((map((tipos: Array<any>) => tipos.map(t => new EntityReference(t.id, t.nombre)))));
    return of(this.tiposNegocio)
  }

  editarPosicionInmueble(codigo: string, lat: number, lng: number): Observable<any> {
    return this.apiPost('inmueblesSeguro', `/inmuebles/${codigo}/posicion`, { lat, lng })
  }

  eliminarInmueble(codigo: string, motivo: string): Observable<void> {
    return this.apiPut('inmueblesSeguro', `/inmuebles/${codigo}/eliminar`, { motivo })
  }

  /**
   * @deprecated
   */
  getTiposInmuebleId(): Observable<Array<EntityRef>> {
    return this.translate.getTraduccionSub("Generales.tiposInmueble")
      .pipe(
        map((x: Array<any>) => x.map(t => new EntityRef(t.id, t.nombre)))
      );
    return of(this.tiposInmueble);
  }

  getTiposInmueble(): Observable<Array<EntityReference<string>>> {


    return this.translate.getTraduccionSub("Generales.tiposInmueble2")
      .pipe(map((x: Array<any>) => x.map(t => new EntityReference<string>(t.id, t.nombre))));
  }

  crear(inmueble: InmuebleDTO): Observable<string> {
    return this.apiPost('property', "/property", inmueble);
  }


  inmueblesDestacados = new Map<string, CacheableResult<any>>();
  getInmueblesDestacados(pais: string = 'CO'): Observable<any> {
    const paisUpper = pais.toUpperCase()
    let valor = this.inmueblesDestacados.get(paisUpper)
    if(!valor){
      valor = new CacheableResult<any>(this.apiGet('inmuebles', `/inmuebles/destacados?pais=${pais}`))
      this.inmueblesDestacados.set(paisUpper, valor)
    }
    return valor.getValue()
  }

  visualizacion(codigoInmueble: string): Observable<void> {
    const request: Visualizacion = {
      dispositivo: this.analytics.deviceId,
      sesion: this.analytics.sessionId,
      usuario: this.analytics.userId,
      codigoInmueble
    }
    return this.apiPost('inmuebles', `/inmuebles/${codigoInmueble}/visualizacion`, request)
  }

  buscar(query: RestQuery): Observable<Page<InmuebleDTO>> {
    return this.apiGet('inmuebles', `/inmuebles`, query).pipe(map((p: any) => Page.fromPage(p)))
  }

  /**
   * Busca la metainformación a cerca de una carácteristica.
   * @returns la información o null
   */
  buscarCaracteristica(claveCaracteristica: string): TipoCaracteristica {
    for (const grupo of GRUPOS_CARACTERISTICA) {
      for (const caracteristica of grupo.caracteristicas) {
        if (caracteristica.clave == claveCaracteristica) {
          return caracteristica
        }
      }
    }
    return null
  }

  buscarMapa(geohashes: string[], busqueda: BusquedaDTO): Observable<Array<InmuebleDTO>> {
    const consulta = busqueda.crearConsulta()
    consulta.removeFilters("ubicacion.regiones.ciudad.id")
    consulta.removeFilters("ubicacion.regiones.barrio.id")
    consulta.removeFilters("ubicacion.regiones.localidad.id")
    consulta.removeFilters("ubicacion.regiones.sector.id")
    consulta.removeFilters("ubicacion.regiones.municipio.id")
    consulta.in("ubicacion.visible.geohash", geohashes)
    return this.apiGet('inmuebles', `/inmuebles/mapa`, consulta.build())
      .pipe(map((r: Page<any>) => {
        return r.content.map(r => ({
          codigo: r.codigo, creacion: new Date(r.ct),
          relevancia: r.rel, ubicacion: { visible: { lat: r.lat, lng: r.lng, geohash: r.gh } }
        }))
      }))
  }

  buscarMapaAgrupado(geohashes: string[], busqueda: BusquedaDTO): Observable<ResultadoConsultaAgrupada[]> {
    const params = {
      geohash__in: geohashes.join(','),
      tipoInmueble__eq: busqueda.tipoInmueble,
      operacion__eq: busqueda.negocio
    }
    if (busqueda.caracteristicas.length > 0) params['caracteristicas__in'] = busqueda.caracteristicas.join(',')

    if (busqueda?.filtros?.estado) params['nuevo__eq'] = 'nuevo' == busqueda.filtros.estado;

    if(busqueda?.filtros?.certificado){
      params['inventario.calificacion__exists'] = true;
    }

    const { habitaciones, banos, parqueaderos, precioDesde, precioHasta } = busqueda?.filtros || {}
    if (habitaciones) {
      if (habitaciones > 0) params['habitaciones__eq'] = habitaciones
      else params['habitaciones__gt'] = -habitaciones
    }
    if (banos) {
      if (banos > 0) params['banos__eq'] = banos
      else params['banos__gt'] = -banos
    }
    if (parqueaderos) {
      if (parqueaderos > 0) params['parqueaderos__eq'] = parqueaderos
      else params['parqueaderos__gt'] = -parqueaderos
    }
    if (precioDesde) params['precio__gt'] = precioDesde
    if (precioHasta) params['precio__lt'] = precioHasta


    return this.apiGet('inmuebles', `/inmuebles/mapa/grupos`, params).pipe(map((r: any[]) => r.map(p => ({ geohash: p.l, cantidad: p.c }))))
  }

  guardar(inmueble: InmuebleDTO): Observable<InmuebleDTO> {
    let observable: Observable<InmuebleDTO>;
    let cloned: InmuebleDTO = DTOUtil.clone(inmueble)
    DTOUtil.removeProperties(cloned, "id", "idRemoto", "origen", "creacion", "actualizacion", "inmobiliaria", "usuarioActualiza")
    if (inmueble.id == null) {
      observable = this.apiPost('inmueblesSeguro', `/inmuebles`, cloned)
    } else {
      observable = this.apiPut('inmueblesSeguro', `/inmuebles/${inmueble.codigo}`, cloned)
    }
    observable = observable.pipe(tap(r => console.log(r)))
    return observable
  }

  obtener(codigo: string, seguro: boolean = false): Observable<InmuebleDTO> {
    const api = seguro ? 'inmueblesSeguro' : 'inmuebles'
    return this.apiGet(api, `/inmuebles/${codigo}`)
  }

  obtenerVistaPrevia(codigos: string[]): Observable<Map<string, InmuebleDTO>> {
    const result = new Map<string, InmuebleDTO>()
    codigos.map(i => this.cache.get(i)).filter(i => i != null).forEach((i: InmuebleDTO) => result.set(i.codigo, i))
    const toFetch = codigos.filter(i => !result.has(i))
    if (toFetch.length === 0) {
      return of(result)
    }
    const query = new RestQueryBuilder().in("codigo", toFetch).select("codigo", "titulo", "imagenes", "caracteristicas.areaTotal",
      "caracteristicas.areaConstruida", "tipoInmueble", "venta", "arriendo", "ubicacion.regiones", "ubicacion.visible",
      "caracteristicas.habitaciones", "caracteristicas.parqueaderos", "caracteristicas.banos", "precios.venta", "precios.arriendo")
    return this.buscar(query.build()).pipe(map(r => {
      r.content.forEach(i => {
        this.cache.put(i.codigo, i)
        result.set(i.codigo, i)
      })
      return result
    }))
  }

  obtenerCaracteristicas(): Observable<Array<GrupoCaracteristica>> {
    return of(GRUPOS_CARACTERISTICA)
  }
}

export class CacheableResult<T> {

  private value: T = undefined;
  private subject: Subject<T> = new Subject<T>();
  private loading:boolean = false

  constructor(private loader:Observable<T>){
  }

  public getValue(): Observable<T> {
    if (this.value) return of(this.value)
    if(!this.loading){
      this.loading = true
      this.loader.subscribe(r => {
        this.loading = false
        this.setValue(r)
      })
    }
    return this.subject;
  }

  private setValue(value: T) {
    if (this.value) return
    this.value = value
    this.subject.next(value)
    this.subject.complete()
  }

}


export interface ResultadoConsultaAgrupada {
  geohash: string
  cantidad: number
}

export interface Visualizacion {
  codigoInmueble: string
  usuario: string
  sesion: string
  dispositivo: string
}

export class GrupoCaracteristica {
  clave: string
  nombre: string
  icono?: string
  caracteristicas: TipoCaracteristica[]
}

export class TipoCaracteristica {
  clave: string
  tipo: string
  etiqueta: string
  descripcion?: string
  opciones?: []
  min?: number
  max?: number
  icono?: string
  tipoInmueble?: number[]
}

export class OpcionTipoCaracteristica {
  etiqueta: string
  clave: string
}

export class ReferenciaRegion {
  id: string
  nombre: string
}

export interface DireccionDTO {
  principal: string
  complemento: string
}

export class UbicacionDTO {
  original?: PuntoDTO
  visible?: PuntoDTO
  direccion?: DireccionDTO
  regiones?: any
}

export class PuntoDTO {
  lat: number
  lng: number
  precision?: number
  geohash?: string
}

export interface PrecioDTO {
  valor: number
  moneda: string
}

interface PreciosInmueble {
  [key: string]: PrecioDTO
}

interface CaracteristicasInmueble {
  [key: string]: number
}

interface OrigenInmueble {
  integrador: string
  id: string
  codigo: string
}


export interface EstadoDTO {
  nuevo?: boolean
  antiguedad?: number
}

export interface InmuebleDTO {
  id?: string
  codigo?: string
  origen?: OrigenInmueble
  codigoVisible?: string
  versionEsquema?: number
  recorridoVirtual?: string
  idRemoto?: number
  activo?: boolean
  creacion?: Date
  actualizacion?: Date
  relevancia?: number

  tipoInmueble?: string
  titulo?: string
  descripcion?: string

  inmobiliaria?: string

  venta?: boolean
  arriendo?: boolean

  precios?: PreciosInmueble
  estado?: EstadoDTO

  ubicacion?: UbicacionDTO
  caracteristicas?: CaracteristicasInmueble
  imagenes?: string[],
  video?:string,
  inventario?:any,
  aliados?:any

}

export class InmuebleUtil {
  static setCaracteristica(inmueble: InmuebleDTO, clave: string, valor: number | boolean) {
    if (valor == null || valor == 0 || valor == false) {
      if (inmueble.caracteristicas != null) {
        delete inmueble.caracteristicas[clave]
      }
      return
    }
    if (inmueble.caracteristicas == null) inmueble.caracteristicas = {}
    valor = typeof valor == 'boolean' ? 1 : valor
    inmueble.caracteristicas[clave] = valor
  }

  static formatearUbicacion(i: InmuebleDTO): string {
    if (i == null || i.ubicacion == null || i.ubicacion.regiones == null) return ""
    const regiones: any = i.ubicacion.regiones
    const { barrio, ciudad, departamento, localidad, sector } = regiones
    if (barrio != null && sector != null && ciudad != null) return `${WordUtils.capitalizeWords(barrio.nombre)}, ${WordUtils.capitalizeWords(sector.nombre)}, ${WordUtils.capitalizeWords(ciudad.nombre)}`
    if (barrio != null && ciudad != null) return `${WordUtils.capitalizeWords(barrio.nombre)}, ${WordUtils.capitalizeWords(ciudad.nombre)}`
    if (localidad != null && ciudad != null) return `${WordUtils.capitalizeWords(localidad.nombre)}, ${WordUtils.capitalizeWords(ciudad.nombre)}`
    if (sector != null && ciudad != null) return `${WordUtils.capitalizeWords(sector.nombre)}, ${WordUtils.capitalizeWords(ciudad.nombre)}`
    if (ciudad != null && departamento != null) return `${WordUtils.capitalizeWords(ciudad.nombre)}, ${WordUtils.capitalizeWords(departamento.nombre)}`
    return "No establecido"
  }
}
