import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InmuebleService } from 'src/app/inmuebles/inmueble.service';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { QueryParams } from 'src/app/utils/query-params.service';
import { RestQueryBuilder } from 'src/app/utils/rest.utils';
import { SeoService } from 'src/app/utils/seo.service';
import { TraduccionService } from 'src/app/utils/traducciones/traducciones.services';
import { DTOUtil, EntityReference, URLUtil, WordUtils } from 'src/app/utils/utils';
import { GeoService, PlaceDTO, RegionDTO } from '../../zona-geo/geo.service';
import { RegionPickerComponent } from '../../zona-geo/zona-region-picker/region-picker/region-picker.component';

@Component({
  selector: 'zona-filtros-busqueda-avanzada',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosBusquedaAvanzadaComponent extends AbstractComponent implements OnInit, AfterViewInit {

  @Output() buscar = new EventEmitter<BusquedaDTO>()
  @Output() cancel = new EventEmitter<void>();
  @Input() tipoBusqueda: string = "mapa"

  @ViewChild(RegionPickerComponent)
  regionPicker: RegionPickerComponent

  private inicializado = false;

  public conCertificado = false;

  static DEFAULT_REGION: RegionDTO = {
    id: 'MPIO_11001', nombre: 'BOGOTÁ D.C.', tipo: 'CIUDAD', nombrePadre: 'BOGOTÁ, D.C.', limites: { n: 4.8368, s: 3.7306, e: -73.9861, w: -74.4509 },
  }

  DEFAULT_REGION: RegionDTO = {
    id: 'MPIO_11001', nombre: 'BOGOTÁ D.C.', tipo: 'CIUDAD', nombrePadre: 'BOGOTÁ, D.C.', limites: { n: 4.8368, s: 3.7306, e: -73.9861, w: -74.4509 },
  }

  static regionRegex = /^([A-Za-z0-9_]+)-(\D+)/i
  regionRegex = /^([A-Za-z0-9_]+)-(\D+)/i

  filtrosAvanzados = [];
  /**
   * old sin traduccion
  filtrosAvanzados = [
    { etiqueta: "Pent House", clave: "pentHouse" },
    { etiqueta: "Ascensor", clave: "ascensor" },
    { etiqueta: "Camaras de vigilancia", clave: "camarasVigilancia" },
    { etiqueta: "Recepción", clave: "recepcion" },
    { etiqueta: "BBQ", clave: "bbq" },
    { etiqueta: "Shut de basuras", clave: "shutBasuras" },
    { etiqueta: "Portería", clave: "porteria" },
    { etiqueta: "Parqueadero comunal", clave: "parqueaderoComunal" },
    { etiqueta: "Parqueadero de visitantes", clave: "parqueaderoVisitantes" },
    { etiqueta: "Salón comunal", clave: "salonComunal" },
    { etiqueta: "Guardería", clave: "guarderia" },
    { etiqueta: "Cocina Americana", clave: "cocinaAmericana" },

    { etiqueta: "Zonas Verdes", clave: "zonasVerdes" },
    { etiqueta: "Transporte", clave: "transporte" },
    { etiqueta: "Centros comerciales", clave: "centroComercial" },
    { etiqueta: "Parque Infantil", clave: "parqueInfantil" },
    { etiqueta: "Vista panorámica", clave: "vistaPanoramica" },

    { etiqueta: "Piscina", clave: "piscina" },
    { etiqueta: "Sauna", clave: "sauna" },
    { etiqueta: "Baño cabinado", clave: "banoCabinado" },
    { etiqueta: "Tina", clave: "tina" },
    { etiqueta: "Baño de servicio", descripcion: "", tipo: "int", clave: "banoServicio" },
    { etiqueta: "Vestier", clave: "vestier" },
    { etiqueta: "Gimnasio", clave: "gimnasio" },
    { etiqueta: "Piscina", clave: "piscina" },
    { etiqueta: "Turco", clave: "turco" },
    { etiqueta: "Sauna", clave: "sauna" },
    { etiqueta: "Jacuzzi", clave: "jacuzzi" },
    { etiqueta: "Cocina integral", clave: "cocinaIntegral" },
  ]

  */
  caracteristicas = []

  tiposInmueble: Array<EntityReference<string>> = [];
  tiposNegocio: Array<EntityReference<string>> = [];
  formularioFiltros: FormGroup = new FormGroup({
    tipoNegocio: new FormControl('arriendo', [Validators.required]),
    tipoInmueble: new FormControl('apartamentos', [Validators.required]),
    estado: new FormControl(''),
    banos: new FormControl(''),
    habitaciones: new FormControl(''),
    parqueaderos: new FormControl(''),
    precioDesde: new FormControl(''),
    precioHasta: new FormControl(''),
    certificado: new FormControl(false)
  })
  ubicacionSeleccionada: PlaceDTO = null;

  _busquedaActual: BusquedaDTO = null
  mostrarAvanzada = false

  set busquedaActual(value: BusquedaDTO) {
    if (!DTOUtil.equals(this._busquedaActual, value) || this._busquedaActual == null) {
      this._busquedaActual = value

      this.regionPicker.setValorInicial(this.busquedaActual.region)
      this.actualizarURL()
      this.actualizarSEO()
      this.buscar.emit(value)
    }
  }

  get busquedaActual(): BusquedaDTO {
    return this._busquedaActual
  }


  constructor(private servicioInmueble: InmuebleService, private seo: SeoService, private route: ActivatedRoute,
    private geo: GeoService, private router: Router,
    private traductor:TraduccionService
    ) {
    super()

    this.traductor.getTraduccionSub("Generales.caracteristicas").subscribe(caracterististicas => {
      this.filtrosAvanzados = caracterististicas;
    });
    route.queryParams.subscribe(p => this.procesarParametros(p))
  }

  ngAfterViewInit(): void {
    this.inicializado = true
    //this.enviarEventoInicial()
  }

  private enviarEventoInicial() {
    if (this.busquedaActual != null && this.inicializado) {
      this.regionPicker.setValorInicial(this.busquedaActual.region)
      this.actualizarSEO()
      this.buscar.emit(this.busquedaActual)
    }
  }

  private buscarRegion(region: string): Observable<RegionDTO> {
    const matcher = region.match(this.regionRegex)
    if (!matcher) {
      return of(this.DEFAULT_REGION)
    }
    const id = matcher[1]
    return this.geo.buscarRegionPorId(id).pipe(catchError(e => {
      this.logE(`Error leyendo region`, e)
      return of(this.DEFAULT_REGION)
    }))
  }

  public actualizar(url: string){
    const params = this.route.snapshot.queryParams
    this.procesarParametros(params, url)
  }

  private procesarParametros(params: Params, url?: string) {
    const [filtros, caracteristicas] = this.cargarFiltros(new QueryParams(params))


    this.parsearDesdeRuta(url).subscribe(p => {
      const [tipo, negocio, region] = p
      const busqueda = new BusquedaDTO(tipo, negocio, region)
      busqueda.filtros = filtros
      busqueda.caracteristicas = [...caracteristicas]
      busqueda.actualizarTitulo()

      this.busquedaActual = busqueda;



      this.formularioFiltros.controls['tipoNegocio'].setValue(negocio)
      this.formularioFiltros.controls['tipoInmueble'].setValue(tipo)
      this.formularioFiltros.controls['estado'].setValue(filtros.estado ? `${filtros.estado}` : "")
      this.formularioFiltros.controls['banos'].setValue(filtros.banos ? `${filtros.banos}` : "")
      this.formularioFiltros.controls['parqueaderos'].setValue(filtros.parqueaderos ? `${filtros.parqueaderos}` : "")
      this.formularioFiltros.controls['habitaciones'].setValue(filtros.habitaciones ? `${filtros.habitaciones}` : "")
      this.formularioFiltros.controls['precioDesde'].setValue(filtros.precioDesde ? `${filtros.precioDesde}` : "")
      this.formularioFiltros.controls['precioHasta'].setValue(filtros.precioHasta ? `${filtros.precioHasta}` : "")
      this.formularioFiltros.controls['certificado'].setValue(filtros.certificado && filtros.certificado === true ? true : false );
      this.caracteristicas = [...caracteristicas]
    })

  }


  private parsearDesdeRuta(url?: string): Observable<any[]> {
    const location = url || window.location.pathname
    let parts: string[] = location.split('/').filter(s => s.length > 0);
    let tipo = "apartamento"
    let negocio = "arriendo"
    let region = this.DEFAULT_REGION


    if (parts.length > 2) {
      tipo = parts[2].toLowerCase();
      tipo = tipo.endsWith("s") ? tipo.substring(0, tipo.length - 1) : tipo
    }

    if (parts.length > 3) {
      negocio = parts[3].toLowerCase();
    }

    if (parts.length > 4) {
      let regionStr = parts[4].toLocaleLowerCase();
      return this.buscarRegion(regionStr).pipe(map(r => [tipo, negocio, r]))
    }
    return of([tipo, negocio, region])
  }


  agregarFiltroAvanzado(clave: string, activo: boolean) {
    const idx = this.caracteristicas.indexOf(clave)
    if (activo && idx < 0) {
      this.caracteristicas.push(clave)
      return
    }
    if (!activo && idx > -1) {
      this.caracteristicas.splice(idx, 1)
    }
  }

  filtroMarcado(clave: string): boolean {
    return this.caracteristicas.includes(clave)
  }

  lista(cantidad: number): string[] {
    const lista = []
    for (let i = 0; i < cantidad; i++) lista.push((i + 1).toString())
    return lista
  }

  /**
   * Sen encarga de establecer los filotros que estan en la url en un array de filtros
   * @param params
   * @returns
   */
  private cargarFiltros(params: QueryParams): any {
    let filters: any = {};
    let priceStartingAt = params.getInteger("f.precioDesde");
    if (priceStartingAt) {
      filters['precioDesde'] = parseInt(priceStartingAt.toString());
    }
    let priceEndingAt = params.getInteger("f.precioHasta");
    if (priceEndingAt) {
      filters['precioHasta'] = parseInt(priceEndingAt.toString());
    }
    let bathFilter = params.getString("f.banos");
    if (bathFilter && bathFilter.match(/^[1-5]*$/g)) {
      filters['banos'] = parseInt(bathFilter);
    }
    let roomsFilter = params.getString("f.habitaciones");
    if (roomsFilter && roomsFilter.match(/^[1-5]*$/g)) {
      filters['habitaciones'] = parseInt(roomsFilter);
    }
    let parkingFilter = params.getString("f.parqueaderos");
    if (parkingFilter && parkingFilter.match(/^[1-5]*$/g)) {
      filters['parqueaderos'] = parseInt(parkingFilter);
    }
    let filtroEstado = params.getString("f.estado");
    if (filtroEstado && filtroEstado.match(/^nuevo|usado$/)) {
      filters['estado'] = filtroEstado;
    }
    let filtroCertificado = params.getString("f.certificado");
    if (filtroCertificado && filtroCertificado.match(/^true|false$/)) {
      filters['certificado'] = filtroCertificado === 'true' ? true : false;
    }
    const caracteristicasStr = params.getString("f.caracteristicas")
    let caracteristicas = []
    if (caracteristicasStr != null && caracteristicasStr.length > 0) {
      caracteristicas = caracteristicasStr.split("-").map(c => c.trim())
    }
    return [filters, caracteristicas];
  }

  ngOnInit(): void {
    this.servicioInmueble.getTiposNegocio()
      .subscribe(tipoGestion =>{
        this.tiposNegocio = tipoGestion;


      }, error => this.logE(`Error cargando tipos de negocio`, error));
    this.servicioInmueble.getTiposInmueble()
      .subscribe(tipoInmueble => this.tiposInmueble = tipoInmueble, error => this.logE(`Error cargando tipos de inmueble`, error));
  }


  seleccionUbicacion(ubicaciones: PlaceDTO[]) {
    const ubicacion = ubicaciones.length > 0 ? ubicaciones[0] : null;
    this.ubicacionSeleccionada = ubicacion;
  }

  /**
   * @deprecated TODO: Esta es responsabilidad de cada uno de los componentes de búsqueda
   */
  buscarClick() {
    this.actualizarParametros();
  }

  private actualizarParametros() {

    if (!this.isFormGroupValid(this.formularioFiltros)) {
      return
    }
    const regionSeleccionda = this.regionPicker.regionSeleccionada;

    if (regionSeleccionda == null) return

    const { tipoNegocio, tipoInmueble } = this.formularioFiltros.value
    const busqueda = new BusquedaDTO(tipoInmueble, tipoNegocio, regionSeleccionda)
    busqueda.caracteristicas = [...this.caracteristicas]
    const filtros = this.formularioFiltros.value;


    const ignorados = ["tipoNegocio", "tipoInmueble", "estado"]
    Object.keys(filtros).forEach(k => {
      if (ignorados.includes(k)) return
      const value = filtros[k]
      if (value == null || "" === value) return
      busqueda.filtros[k] = parseInt(value)
    })
    busqueda.filtros.estado = filtros.estado;
    busqueda.filtros.certificado = filtros.certificado;
    if (DTOUtil.equals(busqueda, this.busquedaActual)) {
      this.cancel.emit()
      return
    }

    busqueda.actualizarTitulo()

      this.busquedaActual = busqueda


  }

  private actualizarURL() {
    const { negocio, tipoInmueble, filtros, region, caracteristicas } = this._busquedaActual

    const ubicacion = `${region.id}-${URLUtil.encodeName(region.nombre)}`
    let url = `/busqueda/${this.tipoBusqueda}/${tipoInmueble}s/${negocio}/${ubicacion}`
    const queryParams = {}
    Object.keys(filtros).forEach(k => queryParams[`f.${k}`] = filtros[k])
    if (caracteristicas.length > 0) {
      queryParams['f.caracteristicas'] = caracteristicas.join('-')
    }


    this.router.navigate([url], { queryParams })
  }

  private actualizarSEO() {
    const { titulo } = this.busquedaActual || {}
    this.seo.setSEOInfo(titulo, titulo)
  }

  reiniciar() {
    this.procesarParametros(this.route.snapshot.queryParams)
  }

  cancelar() {
    this.limpiarFiltros()
    this.cancel.emit()
  }

  limpiarFiltros(): void {
    this.procesarParametros(this.route.snapshot.queryParams)
  }

}

export interface FiltroInmuebles {
  habitaciones?: number
  banos?: number
  parqueaderos?: number
  precioDesde?: number
  precioHasta?: number
  estado?: string,
  certificado?: boolean,
  codigo?:string,
  codigo_integrador?:string
}

export class BusquedaDTO {
  /**
   * Conjunto de filtros con valor: Habitaciones, Baños, Parqueaderos, PrecioDesde y Precio Hasta
   */
  public filtros: FiltroInmuebles = {}
  /**
   * Título amigable para mostrar al usuario
   */
  public titulo: string;
  /**
   * Lista de caracteristicas seleccionadas por el usuario.
   * Pej: jaccuzi, piscina...
   */
  public caracteristicas: string[] = []
  constructor(public tipoInmueble: string, public negocio: string, public region: RegionDTO) {
  }

  actualizarTitulo() {
    const lugar = WordUtils.capitalizeWords(this.region.nombre.toLowerCase())
    let titulo = `${WordUtils.capitalize(this.tipoInmueble)}s en ${WordUtils.capitalize(this.negocio)} en ${lugar}`
    this.titulo = titulo
  }

  crearConsulta(): RestQueryBuilder {
    let query = new RestQueryBuilder().eq('activo', true)
      .countElements(true)
      .select('titulo', 'relevancia', 'codigo', 'caracteristicas.habitaciones', 'precios', 'caracteristicas.banos',
        'caracteristicas.areaConstruida', 'ubicacion.regiones', 'imagenes', 'tipoInmueble', 'arriendo', 'venta')

    const region = this.region
    const tipo = region?.tipo.toLowerCase();

    if(this.tipoInmueble) {
      query.eq('tipoInmueble', this.tipoInmueble);
    }

    if(this.negocio){
      query.eq(this.negocio, true);
    }

    if (tipo === "ciudad") {
      query.eq("ubicacion.regiones.ciudad.id", region.id)
    } else if (tipo === "barrio") {
      query.eq("ubicacion.regiones.barrio.id", region.id)
    } else if (tipo === "localidad") {
      query.eq("ubicacion.regiones.localidad.id", region.id)
    } else if (tipo === "sector") {
      query.eq("ubicacion.regiones.sector.id", region.id)
    } else if (tipo === "municipio") {
      query.eq("ubicacion.regiones.municipio.id", region.id)
    }else if (tipo === "ubicacion") {
      query.eq("ubicacion.regiones.ubicacion.id", region.id)
    }

    const { filtros } = this
    if (filtros.banos != null) {
      query.eq('caracteristicas.banos', filtros.banos)
    }
    if (filtros.habitaciones != null) {
      query.eq('caracteristicas.habitaciones', filtros.habitaciones)
    }
    if (filtros.parqueaderos != null) {
      query.eq('caracteristicas.parqueaderos', filtros.parqueaderos)
    }
    if (filtros.estado) {
      query.eq('nuevo', filtros.estado === 'nuevo')
    }
    if (filtros.certificado) {
      query.exists('inventario.calificacion', true);
    }

     if (filtros.codigo) {
       query.regExpIn("codigo", `.*${filtros.codigo}.*`);
    }

    if (filtros.codigo_integrador) {
       query.regExpIn("origen.id", `.*${filtros.codigo_integrador}.*`);
    }

    for (let c of this.caracteristicas) {
      query.eq(`caracteristicas.${c}`, 1)
    }

    const negocio = this.negocio
    if (filtros.precioDesde != null) {
      query.gte(`precios.${negocio}.valor`, filtros.precioDesde)
    }
    if (filtros.precioHasta != null) {
      query.lte(`precios.${negocio}.valor`, filtros.precioHasta)
    }
    return query
  }
}
