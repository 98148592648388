import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zona-seccion',
  templateUrl: './seccion.component.html',
  styleUrls: ['./seccion.component.scss']
})
export class SeccionComponent implements OnInit {
  @Input()
  titulo: string
  @Input()
  imagen: string
  @Input()
  subtitulo: string
  @Input()
  color: string = "transparent"
  @Input()
  maxWidth: string = "1170px"

  constructor() { }

  ngOnInit(): void {
  }
}
