import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'zona-phone-confirm',
  templateUrl: './phone-confirm.component.html',
  styleUrls: ['./phone-confirm.component.scss']
})
export class PhoneConfirmComponent extends AbstractComponent implements OnInit, OnDestroy {


  @Output()
  phoneConfirmed = new EventEmitter()

  localStorageKey = "30ee766d-ffee-4d0c-a953-4df2970f91ec"

  countryCode = "+57";
  TIMEOUT_SECONDS = 90
  timeToResendSeconds = 0
  nextCodeAfterMillis: number = null
  timeoutSubscription: Subscription;
  codeSent = false
  sentToPhone = null

  sendCodeErrorMessage = ""
  confirmErrorMessage = ""

  otp: string = null
  otpConfig = {
    allowNumbersOnly: true,
    length: 6,
    containerStyles: { 'display': 'flex', 'justify-content': 'space-between' },
    inputStyles: { 'width.px': 40, 'height.px': 40, 'font-size.px': 30 }
  }

  formGroup = new FormGroup({
    phone: new FormControl('', [Validators.required]),
    code: new FormControl('')
  })
  constructor(private auth: AuthService) {
    super()
    const userInfo = auth.userInfo
    this.updateUser(userInfo)
    auth.userChange.subscribe(u => this.updateUser(u))
  }

  updateUser(userInfo) {
    let phone = userInfo.phone_number
    if (phone && phone.startsWith(this.countryCode)) {
      phone = phone.substring(this.countryCode.length)
    }
    if (phone === "0000000000") {
      phone = ""
    }
    this.getControl(this.formGroup, 'phone').setValue(phone)
    this.loadCodeStatus()
  }



  sendCode() {
    const phone = this.getControl(this.formGroup, 'phone')
    const code = this.getControl(this.formGroup, 'code')
    phone.disable()
    const phoneNumber = this.countryCode + phone.value

    this.auth.sendConfirmationSMS(phoneNumber).then(() => {
      this.sentToPhone = phoneNumber
      this.nextCodeAfterMillis = new Date().getTime() + (1000 * this.TIMEOUT_SECONDS)
      this.codeSent = true
      this.saveCodeSendTime(phoneNumber, this.nextCodeAfterMillis)
      this.startResendTimer()
      phone.disable()
      code.enable()
    }, e => {
      phone.enable()
      this.sendCodeErrorMessage = "Ocurrió un error al enviar el código"
      this.logE(e)
    })
  }

  codeSendState() {
    this.codeSent = true
  }

  startResendTimer() {
    this.timeToResendSeconds = Math.floor((this.nextCodeAfterMillis - new Date().getTime()) / 1000)
    if (this.timeoutSubscription != null) this.timeoutSubscription.unsubscribe()
    this.timeoutSubscription = interval(1000).subscribe(x => {
      this.timeToResendSeconds--
      if (this.timeToResendSeconds < 1) {
        this.timeoutSubscription.unsubscribe()
        this.timeoutSubscription = null
        this.getControl(this.formGroup, 'phone').enable()
      }
    })
  }

  private saveCodeSendTime(number, nextCodeAfter) {
    localStorage.setItem(this.localStorageKey, JSON.stringify({ number, nextCodeAfter }))
  }

  private loadCodeStatus() {
    try {
      const data = localStorage.getItem(this.localStorageKey)
      const state = JSON.parse(data)
      if (typeof (state.nextCodeAfter) !== 'number' || state.nextCodeAfter < new Date().getTime()) {
        return
      }
      this.nextCodeAfterMillis = state.nextCodeAfter
      this.sentToPhone = state.number
      this.codeSent = true
      this.getControl(this.formGroup, 'phone').disable()
      this.startResendTimer()
    } catch (e) {
      this.logW("Error cargando estado de localstorage")
    }
  }

  onOTPChange(otp: string) {
    this.otp = otp
  }

  confirmCode() {
    this.auth.confirmPhoneNumber(this.otp).then(e => {
      this.phoneConfirmed.emit()
    }, e => {
      if (e.code === "CodeMismatchException") {
        this.confirmErrorMessage = "El código ingresado no coincide"
        return
      }
      this.logE(`Error validando el código de confirmación`, e)
      this.confirmErrorMessage = "Ocurrió un error al validar el código"
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.timeoutSubscription != null) {
      this.timeoutSubscription.unsubscribe()
      this.timeoutSubscription = null
    }
  }
}
