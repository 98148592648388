import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { Ng5SliderModule } from 'ng5-slider';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UtilsModule } from 'src/app/utils/utils.module';
import { ZonaImagenModule } from 'src/app/utils/zona-imagen/zona-imagen.module';
import { ZonaLayoutModule } from 'src/app/utils/zona-layout/zona-layout.module';
import { ZonaMapModule } from 'src/app/utils/zona-map/zona-map.module';
import { ZonaPaginatorModule } from 'src/app/utils/zona-paginator/zona-paginator.module';
import { FiltrosBusquedaAvanzadaComponent } from '../busqueda-avanzada/filtros/filtros.component';
import { ZonaGeoModule } from '../zona-geo/zona-geo.module';
import { ZonaRegionPickerModule } from '../zona-geo/zona-region-picker/zona-region-picker.module';
import { BusquedaMapaComponent } from './busqueda-mapa/busqueda-mapa.component';
import { PanelBusquedaComponent } from './panel-busqueda/panel-busqueda.component';
import { SearchComponent } from './search/search.component';
import { BannersModule } from '../../utils/banners/banners.module';
import { AdsInmuebleModule } from '../../utils/adsInmueble/AdsInmueble.module';
import { FloatingFavInmueblesComponent } from './floating-fav-inmuebles/floating-fav-inmuebles.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { TarjetaInmuebleModule } from '../tarjeta-inmueble/tarjeta-inmueble.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    SearchComponent,
    PanelBusquedaComponent,
    BusquedaMapaComponent,
    FiltrosBusquedaAvanzadaComponent,
    FloatingFavInmueblesComponent
  ],
  exports: [
    SearchComponent,
    PanelBusquedaComponent,
    MatProgressSpinnerModule,
    FiltrosBusquedaAvanzadaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UtilsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    ZonaGeoModule,
    FlexLayoutModule,
    Ng5SliderModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(options),
    ZonaMapModule,
    ZonaLayoutModule,
    ZonaRegionPickerModule,
    ZonaImagenModule,
    NgxSkeletonLoaderModule,
    ZonaPaginatorModule,
    MatMenuModule,
    BannersModule,
    AdsInmuebleModule,
    MatSnackBarModule,
    TranslateModule,
    TarjetaInmuebleModule,
    MatSlideToggleModule
  ]
})
export class ZonaPanelBusquedaModule { }
