import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InmuebleComponent } from './inmueble/inmueble.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ZonaImagenModule } from 'src/app/utils/zona-imagen/zona-imagen.module';
import { UtilsModule } from 'src/app/utils/utils.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [InmuebleComponent],
  exports: [InmuebleComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    ZonaImagenModule,
    UtilsModule,
    NgxSkeletonLoaderModule,
    MatButtonModule,
    TranslateModule
  ]
})
export class TarjetaInmuebleModule { }
