<div class="container">
    <div class="content">
        <ng-content></ng-content>
    </div>
    <div class="lock visible" *ngIf="locked">
        <div class="message-container">
            <span>{{message}}</span>
            <!-- <span class="message">{{message}}</span> -->
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                style="margin:auto;display:block;" width="200px" height="200px" viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid">
                <g transform="translate(80,50)">
                    <g transform="rotate(0)">
                        <circle cx="0" cy="0" r="4" fill="#00ae1d" fill-opacity="1">
                            <animateTransform attributeName="transform" type="scale" begin="-1.4344262295081966s"
                                values="1.5 1.5;1 1" keyTimes="0;1" dur="1.639344262295082s" repeatCount="indefinite">
                            </animateTransform>
                            <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.639344262295082s"
                                repeatCount="indefinite" values="1;0" begin="-1.4344262295081966s"></animate>
                        </circle>
                    </g>
                </g>
                <g transform="translate(71.21320343559643,71.21320343559643)">
                    <g transform="rotate(45)">
                        <circle cx="0" cy="0" r="4" fill="#00ae1d" fill-opacity="0.875">
                            <animateTransform attributeName="transform" type="scale" begin="-1.2295081967213115s"
                                values="1.5 1.5;1 1" keyTimes="0;1" dur="1.639344262295082s" repeatCount="indefinite">
                            </animateTransform>
                            <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.639344262295082s"
                                repeatCount="indefinite" values="1;0" begin="-1.2295081967213115s"></animate>
                        </circle>
                    </g>
                </g>
                <g transform="translate(50,80)">
                    <g transform="rotate(90)">
                        <circle cx="0" cy="0" r="4" fill="#00ae1d" fill-opacity="0.75">
                            <animateTransform attributeName="transform" type="scale" begin="-1.0245901639344261s"
                                values="1.5 1.5;1 1" keyTimes="0;1" dur="1.639344262295082s" repeatCount="indefinite">
                            </animateTransform>
                            <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.639344262295082s"
                                repeatCount="indefinite" values="1;0" begin="-1.0245901639344261s"></animate>
                        </circle>
                    </g>
                </g>
                <g transform="translate(28.786796564403577,71.21320343559643)">
                    <g transform="rotate(135)">
                        <circle cx="0" cy="0" r="4" fill="#00ae1d" fill-opacity="0.625">
                            <animateTransform attributeName="transform" type="scale" begin="-0.819672131147541s"
                                values="1.5 1.5;1 1" keyTimes="0;1" dur="1.639344262295082s" repeatCount="indefinite">
                            </animateTransform>
                            <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.639344262295082s"
                                repeatCount="indefinite" values="1;0" begin="-0.819672131147541s"></animate>
                        </circle>
                    </g>
                </g>
                <g transform="translate(20,50.00000000000001)">
                    <g transform="rotate(180)">
                        <circle cx="0" cy="0" r="4" fill="#00ae1d" fill-opacity="0.5">
                            <animateTransform attributeName="transform" type="scale" begin="-0.6147540983606558s"
                                values="1.5 1.5;1 1" keyTimes="0;1" dur="1.639344262295082s" repeatCount="indefinite">
                            </animateTransform>
                            <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.639344262295082s"
                                repeatCount="indefinite" values="1;0" begin="-0.6147540983606558s"></animate>
                        </circle>
                    </g>
                </g>
                <g transform="translate(28.78679656440357,28.786796564403577)">
                    <g transform="rotate(225)">
                        <circle cx="0" cy="0" r="4" fill="#00ae1d" fill-opacity="0.375">
                            <animateTransform attributeName="transform" type="scale" begin="-0.4098360655737705s"
                                values="1.5 1.5;1 1" keyTimes="0;1" dur="1.639344262295082s" repeatCount="indefinite">
                            </animateTransform>
                            <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.639344262295082s"
                                repeatCount="indefinite" values="1;0" begin="-0.4098360655737705s"></animate>
                        </circle>
                    </g>
                </g>
                <g transform="translate(49.99999999999999,20)">
                    <g transform="rotate(270)">
                        <circle cx="0" cy="0" r="4" fill="#00ae1d" fill-opacity="0.25">
                            <animateTransform attributeName="transform" type="scale" begin="-0.20491803278688525s"
                                values="1.5 1.5;1 1" keyTimes="0;1" dur="1.639344262295082s" repeatCount="indefinite">
                            </animateTransform>
                            <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.639344262295082s"
                                repeatCount="indefinite" values="1;0" begin="-0.20491803278688525s"></animate>
                        </circle>
                    </g>
                </g>
                <g transform="translate(71.21320343559643,28.78679656440357)">
                    <g transform="rotate(315)">
                        <circle cx="0" cy="0" r="4" fill="#00ae1d" fill-opacity="0.125">
                            <animateTransform attributeName="transform" type="scale" begin="0s" values="1.5 1.5;1 1"
                                keyTimes="0;1" dur="1.639344262295082s" repeatCount="indefinite"></animateTransform>
                            <animate attributeName="fill-opacity" keyTimes="0;1" dur="1.639344262295082s"
                                repeatCount="indefinite" values="1;0" begin="0s"></animate>
                        </circle>
                    </g>
                </g>
            </svg>
            <!-- <span class="zona">Zona Hábitat</span> -->
        </div>
    </div>
</div>
