import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import Auth from '@aws-amplify/auth';
import { AuthService } from '../auth.service';

@Component({
  selector: 'zona-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends AbstractComponent implements OnInit {

  errorMessage = null;
  changePasswordErrorMessage = null;
  mailSent = false;
  hidePassword = true;
  @Output()
  passwordChanged = new EventEmitter();
  @Output()
  regresar = new EventEmitter();
  changeCompleted = false;
  mailSentTo = null
  @Input()
  email: string = null

  constructor(private auth: AuthService) {
    super();
  }

  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  newPasswordForm = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.minLength(4)]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8)])
  })

  ngOnInit(): void {
    if (this.email) {
      this.forgotPasswordForm.controls['email'].setValue(this.email)
      this.sendCode()
    }
  }

  sendCode() {
    if (!this.forgotPasswordForm.valid) {
      return;
    }
    let email = this.forgotPasswordForm.controls['email'].value;
    this.errorMessage = null;
    Auth.forgotPassword(email).then(r => {
      this.mailSent = true;
      this.mailSentTo = r?.CodeDeliveryDetails?.Destination
    }).catch(e => {
      this.logE(e);
      if ("UserNotFoundException" == e.code) {
        this.errorMessage = "El usuario no existe.";
      } else {
        this.errorMessage = e.message || "Ocurrió un error inesperado, intenta de nuevo.";
      }
    });
  }

  changePassword() {
    if (!this.newPasswordForm.valid) {
      return;
    }
    let email = this.forgotPasswordForm.controls['email'].value;
    let code = this.newPasswordForm.controls['code'].value;
    let newPassword = this.newPasswordForm.controls['newPassword'].value;
    Auth.forgotPasswordSubmit(email, code, newPassword).then(r => {
      this.auth.signIn(email, newPassword).then(e => {
        this.changeCompleted = true;
        this.passwordChanged.emit();
      }).catch(e => {
        this.logE(e);
        this.changePasswordErrorMessage = e.message || "Tu contraseña se modificó, pero no pudimos autenticarte."
      })
    }).catch(e => {
      this.logE(e);
      if ("CodeMismatchException" == e.code) {
        this.changePasswordErrorMessage = "El código no es válido."
      } else {
        this.changePasswordErrorMessage = e.message || "Ocurrió un error inesperado, intenta de nuevo.";
      }
    })
  }

}
