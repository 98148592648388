<div class="container">
    <div fxLayout="column" fxLayoutAlign="start stretch">
        <!-- Header -->
        <div class="search" [ngStyle]="{'background-image': 'url(' + ('HOME.index.imagen' | translate) + ')'}">
            <div class="darkener">
                <h2 class="welcome">{{'HOME.index.welcome' | translate}}</h2>
                <p class="subtitle">{{'HOME.index.subtitulo' | translate}}</p>
                <zona-search></zona-search>
            </div>
        </div>

        <!-- Contenido -->
        <div fxLayout="column">

            <!--

            <zona-seccion [fxHide]="!enVenta.cargando && enVenta.lista.length == 0" [titulo]="'Más recientes en '+ ('ZonaSearch.venta' | translate | titlecase)"
                [subtitulo]="'Estas son las propiedades en '+ ('ZonaSearch.venta' | translate | titlecase) + ' más recientes de toda '+ ('FindPropiedades.pais' | translate)"
                maxWidth="1400px">
                <zona-inmuebles-destacados nombreLista="venta" #enVenta></zona-inmuebles-destacados>
            </zona-seccion>
            <zona-seccion [fxHide]="!enArriendo.cargando && enArriendo.lista.length == 0" [titulo]="'Más recientes en ' + ('ZonaSearch.arriendo' | translate | titlecase)"
                [subtitulo]="'Estas son las propiedades en ' +('ZonaSearch.arriendo' | translate | titlecase) +  ' más recientes de toda ' + ('FindPropiedades.pais' | translate)"
                maxWidth="1400px" color="white">
                <zona-inmuebles-destacados nombreLista="arriendo" #enArriendo></zona-inmuebles-destacados>
            </zona-seccion>
            <zona-seccion [fxHide]="!economicos.cargando && economicos.lista.length == 0" [titulo]="'Más económicos en ' + ('ZonaSearch.arriendo' | translate | titlecase)"
                [subtitulo]="'Estas son las propiedades en ' +('ZonaSearch.arriendo' | translate | titlecase)+ ' más económicas de toda ' + ('FindPropiedades.pais' | translate)"
                maxWidth="1400px">
                <zona-inmuebles-destacados nombreLista="economicos" #economicos></zona-inmuebles-destacados>
            </zona-seccion>
          -->
            <!-- Seccion busqueda de propiedades -->
            <section class="margin-content" style="width: 100%;">
                <zona-find-properties></zona-find-properties>
            </section>
            <!-- <section class="tools" *ngIf="traduccion.esCodigoPaisActual('co')">
                <zona-tools [informacion]="informacionHerramientas"></zona-tools>
            </section> -->
            <!-- Seccion ayuda venta  -->
            <zona-ayuda-venta [informacionComponente]="informacionAyudaVenta"></zona-ayuda-venta>
        </div>
        <!-- <zona-search-links [informacion]="informacion"></zona-search-links> -->
    </div>
</div>
