import { Pipe, PipeTransform } from '@angular/core';
import { FormatUtil } from './utils';
@Pipe({
    name: 'zonacurrency',
})
export class ZonaCurrencyPipe implements PipeTransform {


    transform(value: any | any, digitsInfo: string = '1.0-0', locale: string = 'es-CO'): string | null {
        return FormatUtil.moneda(value, digitsInfo, locale)
    }
}
