import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ad-banner-horizontal',
  templateUrl: './banner-horizontal.component.html',
  styleUrls: ['./banner-horizontal.component.scss']
})
export class BannerHorizontalComponent implements OnInit {

  @Input()
  titulo:string;

  @Input()
  descripcion:string

  @Input()
  link:string

  @Input()
  textoLink:string

  @Input()
  colorFondo = null;

  @Input()
  colorFuente = null;

  constructor() { }

  ngOnInit(): void {
  }

}
