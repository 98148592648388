import { ZonaHabitatObject } from './zona-object';

export class Cache {
    averageEntryCount = 100
    private estimatedCount = -1

    constructor(public readonly region: string) {
        this.estimatedCount = this.entryCount
    }

    visitEntries(callback: (k) => void) {
        for (var i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (key.startsWith(this.region + "@")) {
                callback(key)
            }
        }
    }

    get keys(): string[] {
        const keys = []
        this.visitEntries(key => keys.push(key))
        return keys
    }

    get entryCount(): number {
        let count = 0
        this.visitEntries(_ => count++)
        return count
    }

    clear() {
        let keys = this.keys
        keys.forEach(key => localStorage.removeItem(key))
    }

    put(key: string, value: any, options?: CacheOptions) {
        const fullKey = `${this.region}@${key}`
        const minutes = options?.expiresAfterMinutes || 30
        const expires = Date.now() + (1000 * 60 * minutes)
        const entry = { value, expires }
        const json = JSON.stringify(entry)
        localStorage.setItem(fullKey, json)
        this.estimatedCount++
        this.cleanup()
    }

    get<T>(key: string, defValue: T = null): T {
        const fullKey = `${this.region}@${key}`
        const entry = localStorage.getItem(fullKey)
        if (entry == null) return defValue
        const { value, expires } = JSON.parse(entry)
        if (expires < Date.now()) {
            localStorage.removeItem(fullKey)
            return null
        }
        return value
    }

    private cleanup() {
        const maxEntries = Math.floor(this.averageEntryCount * 1.5)
        if (this.estimatedCount < maxEntries) return
        const keys = this.keys
        this.estimatedCount = keys.length
        if (this.estimatedCount < this.averageEntryCount) return
        const toRemove = keys.slice(this.averageEntryCount)
        toRemove.forEach(k => localStorage.removeItem(k))
    }
}

export interface CacheOptions {
    expiresAfterMinutes: number
}


/**
 *  TODO: Limpiar caches cuando el caché se pasa de tamaño
 */
export class CacheManager extends ZonaHabitatObject {

    public static readonly Instance = new CacheManager();

    private static regions = new Map<string, Cache>();

    private constructor() {
        super();
    }

    static getCache(regionName: string) {
        let region = this.regions.get(regionName)
        if (region == null) {
            region = new Cache(regionName)
            this.regions.set(regionName, region)
        }
        return region
    }


    static clear() {
        Array.from(this.regions.values()).forEach(c => c.clear())
    }

    private isStorageAvailable(): boolean {
        let storage = typeof (Storage) !== 'undefined';
        if (!storage) {
            this.logW("No local storage avaiable")
        }
        return storage;
    }
}
