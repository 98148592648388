<zona-seccion titulo="{{'Encuentre '+('FindPropiedades.inmueble' | translate) +' en estas ciudades'}}"
    subtitulo="Y en todas las demás ciudades de {{'FindPropiedades.pais' | translate}}.">
    <div class="mt15" style="padding: 3em;">
        <div class="row-one" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
            <div class="card-one mt20" [style.background-image]="'url( ' + ('FindPropiedades.ciudades.1.imagen' |translate ) + ')'">
                <div class="details">
                    <a routerLink="{{'FindPropiedades.ciudades.1.route' |translate }}" rel="nofollow"
                        [queryParams]="{'origen': 'index'}" class="zona-text-b-600 zona-btn-basic pt5 pb5">{{'FindPropiedades.ciudades.1.nombre' |translate }} </a>
                    <!-- <p class="zona-text-b">24 Properties</p> -->
                </div>
            </div>
            <div class="card-two mt20" [style.background-image]="'url( ' + ('FindPropiedades.ciudades.2.imagen' |translate ) + ')'">
                <div class="details">
                     <a routerLink="{{'FindPropiedades.ciudades.2.route' |translate }}" rel="nofollow"
                        [queryParams]="{'origen': 'index'}" class="zona-text-b-600 zona-btn-basic pt5 pb5">{{'FindPropiedades.ciudades.2.nombre' |translate }} </a>
                    <!-- <p class="zona-text-b">18 Properties</p> -->
                </div>
            </div>
        </div>
        <div class="row-two" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
            <div class="card" [style.background-image]="'url( ' + ('FindPropiedades.ciudades.3.imagen' |translate ) + ')'">
                <div class="details">
                     <a routerLink="{{'FindPropiedades.ciudades.3.route' |translate }}" rel="nofollow"
                        [queryParams]="{'origen': 'index'}" class="zona-text-b-600 zona-btn-basic pt5 pb5">{{'FindPropiedades.ciudades.3.nombre' |translate }} </a>
                    <!-- <p class="zona-text-b">89 Properties</p> -->
                </div>
            </div>
            <div class="card" [style.background-image]="'url( ' + ('FindPropiedades.ciudades.4.imagen' |translate ) + ')'">
                <div class="details">
                    <a routerLink="{{'FindPropiedades.ciudades.4.route' |translate }}" rel="nofollow" 
                        [queryParams]="{'origen': 'index'}" class="zona-text-b-600 zona-btn-basic pt5 pb5">{{'FindPropiedades.ciudades.4.nombre' |translate }} </a>
                    <!-- <p class="zona-text-b">47 Properties</p> -->
                </div>
            </div>
            <div class="card" [style.background-image]="'url( ' + ('FindPropiedades.ciudades.5.imagen' |translate ) + ')'">
                <div class="details">
                     <a routerLink="{{'FindPropiedades.ciudades.5.route' |translate }}" rel="nofollow"
                        [queryParams]="{'origen': 'index'}" class="zona-text-b-600 zona-btn-basic pt5 pb5">{{'FindPropiedades.ciudades.5.nombre' |translate }} </a>
                    <!-- <p class="zona-text-b">47 Properties</p> -->
                </div>
            </div>
        </div>
    </div>
</zona-seccion>
