import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'zona-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent extends AbstractComponent implements OnInit, AfterViewInit{

  defaultRedirect = "/";

  constructor(private router: Router, public readonly auth: AuthService) {
    super();
    this.doRedirect();
  }
  ngAfterViewInit(): void {


   this.auth
  }

  doRedirect() {
    if (typeof (Storage) !== "undefined") {
      let state = localStorage.getItem("zh.prelogin.state");
      if (state != null) {
        localStorage.removeItem("zh.prelogin.state");
        this.auth.userChange.subscribe((user) => {
          if(!user.anonymus){
            this.router.navigateByUrl(state);
          }
        });
        return;
      }
    }
    this.router.navigateByUrl(this.defaultRedirect, { replaceUrl: true });
  }

  ngOnInit(): void {



  }


}
