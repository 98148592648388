import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { catchError, timeout } from 'rxjs/operators';

@Component({
  selector: 'zona-zona-not-found-handler-component',
  templateUrl: './zona-not-found-handler-component.component.html',
  styleUrls: ['./zona-not-found-handler-component.component.scss']
})
export class ZonaNotFoundHandlerComponentComponent implements OnInit {

  constructor(updates: SwUpdate, router: Router) {

    updates.available.pipe(timeout(1000 * 60 * 2), catchError(e => router.navigate(["/404"]))).subscribe(r => {
      location.reload();
    })
  }

  ngOnInit(): void {
  }

}
