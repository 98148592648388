import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InmuebleService, InmuebleUtil } from 'src/app/inmuebles/inmueble.service';
import { AbstractComponent } from 'src/app/utils/abstract.service';
import { AnalyticsService } from 'src/app/utils/analytics.service';
import { TraduccionService } from 'src/app/utils/traducciones/traducciones.services';
import { FormatUtil } from 'src/app/utils/utils';
import { UtilsService } from '../../../utils/utils.service';
import { FavoritesService, FavoriteState } from '../../favorites/favorites.service';

@Component({
  selector: 'zona-resultado-inmueble',
  templateUrl: './inmueble.component.html',
  styleUrls: ['./inmueble.component.scss']
})
export class InmuebleComponent extends AbstractComponent implements OnInit {

  @Input() inmueble: any;
  @Input() codigoInmueble: string;
  @Input() negocio: string = 'venta';
  @Output() ubicarEnMapa = new EventEmitter()
  precioPrincipal: string = null
  negocioPrincipal = 'venta'

  estadoFavorito: number = FavoriteState.UNKNOWN

  constructor(public utilsService: UtilsService, private favoritos: FavoritesService, private analytics: AnalyticsService,
    private inmuebleService: InmuebleService) {
    super()
  }

  ngOnInit(): void {
    if (this.inmueble != null) {
      this.favoritos.observar(this.inmueble.codigo).subscribe((estado) => {
        this.estadoFavorito = estado;
      })

      const { venta, arriendo } = this.inmueble
      let { venta: precioVenta, arriendo: precioArriendo } = this.inmueble.precios || {}
      precioVenta = FormatUtil.moneda(precioVenta)
      precioArriendo = FormatUtil.moneda(precioArriendo)

      this.negocioPrincipal = null
      this.precioPrincipal = null

      if (venta != arriendo) {
        this.negocioPrincipal = arriendo ? 'arriendo' : 'venta'
        this.precioPrincipal = arriendo ? precioArriendo : precioVenta
      } else if (venta && arriendo) {
        const esArriendo = this.negocio === 'arriendo'
        this.precioPrincipal = esArriendo ? precioArriendo : precioVenta
        this.negocioPrincipal = esArriendo ? 'arriendo' : 'venta'
      }
    }
    if (this.inmueble == null && this.codigoInmueble != null) {
      this.inmuebleService.obtenerVistaPrevia([this.codigoInmueble]).subscribe(i => {
        this.inmueble = i.get(this.codigoInmueble)
        this.estadoFavorito = this.favoritos.obtenerEstadoElemento(this.inmueble.codigo)
      }, e => this.handleError(e))
    }
  }

  actualizarEstadoFavorito() {

  }

  abrirDetalleInmueble(): void {
    const { codigo, venta, arriendo } = this.inmueble || {}
    if (codigo) {
      let negocio = this.negocio
      if (venta != arriendo) {
        negocio = venta ? 'venta' : 'arriendo'
      }
      this.analytics.registerEvent('select_item', { codigo })
      window.open(`/busqueda/inmueble/${this.inmueble.codigo}?origen=lista&oferta=${negocio}`, '_blank');
    }
  }

  obtenerUbicacionFormateada(): string {
    return InmuebleUtil.formatearUbicacion(this.inmueble)
  }

  ubicarInmuebleEnMapa(event: MouseEvent) {
    event.stopPropagation()
    this.ubicarEnMapa.emit(this.inmueble)
  }

  cambiarOculto(event: MouseEvent) {
    event.stopPropagation()
    this.estadoFavorito = this.favoritos.cambiarEstadoOculto(this.inmueble.codigo)
  }

  cambiarFavorito(event: MouseEvent) {
    event.stopPropagation()
    this.favoritos.cambiarEstadoFavorito(this.inmueble.codigo)
  }
}
