import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({ 'providedIn': 'root' })
export class SeoService {

    defaultDescription = "La mejor opción ✅ Busca entre más de 100.000 casas, apartamentos, " +
        "apartaestudios y locales, en venta o arriendo, en Bogotá, Medellín, Cali y toda Colombia. " +
        "Encuentra en Zona Hábitat tu hogar a tu manera, a tu medida.";
    defaultImageUri = "https://zonahabitat.com/assets/images/ZonaHabitat_1920x1080.png"

    public static SpecialChar = {
        Chevron: "▷"
    }

    public static Icon = {
        Star: "⭐️",
        Home: "🏠",
        Medal: "🥇",
        Heart: "❤️",
        Victory: "✌️",
        Ligthning: "⚡️",
        Forbidden: "⛔️",
        Shine: "✨",
        CheckMark: "✅",
        Glass: "🔎"
    }

    constructor(private meta: Meta, private titleService: Title) {
    }

    private set description(description: string) {
        let desc = description || this.defaultDescription
        this.meta.updateTag({ name: "description", content: desc })
        this.meta.updateTag({ name: "og:description", content: desc })
        this.meta.updateTag({ name: "twitter:description", content: desc })
    }

    private set title(titleText: string) {
        let title = titleText == null ? "▷ Zona Hábitat" : `${titleText} | Zona Hábitat`
        this.titleService.setTitle(title)
        this.meta.updateTag({ name: "title", content: title })
        this.meta.updateTag({ name: "og:title", content: title })
        this.meta.updateTag({ name: "twitter:title", content: title })
    }

    private set image(imageUri: string) {
        let image = imageUri || this.defaultImageUri
        this.meta.updateTag({ name: "og:image", content: image })
        this.meta.updateTag({ name: "twitter:image", content: image })
    }

    setSEOInfo(title: string, description: string = null, imageUri: string = null, keyWords: string[] = []) {
        this.title = title
        this.description = description
        this.image = imageUri
    }
}
