import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LinkService } from '../../utils/link.service';
import { environment } from 'src/environments/environment';
import { EventDispatcherService } from '../event-dispatcher.service';
import { AbstractComponent } from '../../utils/abstract.service';
import { UserInfo, AuthService } from 'src/app/auth/auth.service';
import { AuthDialogService } from 'src/app/auth/auth-dialog.service';
import { AnalyticsService } from 'src/app/utils/analytics.service';
import { TraduccionService } from '../../utils/traducciones/traducciones.services';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})

export class TopNavComponent extends AbstractComponent implements OnInit {
  @Output() sideNavToggled = new EventEmitter<void>();
  @Input() color: string;
  user: UserInfo = UserInfo.ANONYMUS;
  displaySocialNetworks = true;
  links = {
    "zonabitero": { url: "https://zonabitero.com", newPage: true },
    "partner": { url: "https://www.zona-habitat.com/zh/", newPage: false },
    "facebook": { url: environment.socialNetworks.facebook, newPage: true },
    "twitter": { url: environment.socialNetworks.twitter, newPage: true },
    "instagram": { url: environment.socialNetworks.instagram, newPage: true }
  }

  constructor(private readonly router: Router,
    public readonly auth: AuthService,
    private authDialog: AuthDialogService,
    eventService: EventDispatcherService,
    private analytics: AnalyticsService,
    private linkService: LinkService,
    public traducciones:TraduccionService
    ) {
    super();
    this.user = auth.userInfo;
    auth.userChange.subscribe(u =>{

      this.runOnNgZone(() => this.user = u)});
    eventService.subscribeTo("displaySocialNetworkLinks").subscribe(e => this.displaySocialNetworks = e)
  }

  ngOnInit(): void {
    this.auth.checkUser()
  }

  login() {
    this.analytics.registerEvent('login-click', { 'source': 'topNav' })
    this.authDialog.showAuthDialog();
  }

  isLogged() {
    return this.user.anonymus;
  }

  signout() {
    this.auth.signOut().then(_ => this.router.navigate(["/"]));
  }

  toggleSidebar() {
    this.sideNavToggled.emit();
  }

  openLink(key: string) {
    var link = this.links[key];
    this.linkService.openLink(link.url, link.newPage, "topLinks");
  }


}
