import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZonaLayoutTituloComponent } from './zona-layout-titulo/zona-layout-titulo.component';
import { ZonaTarjetaComponent } from './zona-tarjeta/zona-tarjeta.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ZonaLayoutLandingComponent } from './zona-layout-landing/zona-layout-landing.component';
import { LayoutDashboardComponent } from './layout-dashboard/layout-dashboard.component';
import { PagedCardListComponent } from './paged-card-list/paged-card-list.component';
import { ZonaPaginatorModule } from '../zona-paginator/zona-paginator.module';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SeccionTituloComponent } from './seccion-titulo/seccion-titulo.component';
import { ZonaPanelCaracteristicasImagenComponent } from './zona-panel-caracteristicas-imagen/zona-panel-caracteristicas-imagen.component';
import { ImagePanelComponent } from './image-panel/image-panel.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MatExpansionModule } from '@angular/material/expansion';
//import { MatCarouselModule } from '@ngmodule/material-carousel';
import { SeccionCentradaComponent } from './seccion-centrada/seccion-centrada.component';
import { ZonaSeccionModule } from './zona-seccion/zona-seccion.module';
import { TranslateModule } from '@ngx-translate/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
@NgModule({
  declarations: [ZonaLayoutTituloComponent, ZonaTarjetaComponent,
    ZonaLayoutLandingComponent,
    LayoutDashboardComponent, PagedCardListComponent, SeccionTituloComponent,
    ZonaPanelCaracteristicasImagenComponent, ImagePanelComponent, SeccionCentradaComponent],
  exports: [ZonaLayoutTituloComponent, ZonaTarjetaComponent,
    ZonaLayoutLandingComponent,
    LayoutDashboardComponent, PagedCardListComponent, SeccionTituloComponent, SeccionCentradaComponent,
    ZonaPanelCaracteristicasImagenComponent, ImagePanelComponent],
  imports: [
    CommonModule, FlexLayoutModule, ZonaPaginatorModule, MatButtonModule, MatTooltipModule, NgxSkeletonLoaderModule,
    IvyCarouselModule, MatExpansionModule,
    //MatCarouselModule,
    ZonaSeccionModule,TranslateModule,
    MatProgressSpinnerModule
  ]
})
export class ZonaLayoutModule { }
