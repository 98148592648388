import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zona-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {

  informacion: any = {
    titulo: '¿Cómo Funciona?',
    subtitulo: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
    marcas: [
      { path: 'assets/images/house/1.jpg' },
      { path: 'assets/images/house/2.jpg' },
      { path: 'assets/images/house/3.jpg' },
      { path: 'assets/images/house/4.jpg' },
      { path: 'assets/images/house/5.jpg' },
      { path: 'assets/images/house/6.jpg' },
      { path: 'assets/images/house/1.jpg' },
      { path: 'assets/images/house/2.jpg' },
      { path: 'assets/images/house/3.jpg' },
      { path: 'assets/images/house/4.jpg' },
      { path: 'assets/images/house/5.jpg' },
      { path: 'assets/images/house/6.jpg' },
    ]
  }

  informacionFaq: any = {
    titulo: '¿Cómo Funciona?',
    subtitulo: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
    preguntas: [
      { pregunta: 'Lorem ipsum dolor sit amet consectetur 1', respuesta: 'Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur 1', nombreAccion: 'Lorem 1', accion: 'test' },
      { pregunta: 'Lorem ipsum dolor sit amet consectetur 2', respuesta: 'Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur 2', nombreAccion: 'Lorem 2', accion: 'test' },
      { pregunta: 'Lorem ipsum dolor sit amet consectetur 3', respuesta: 'Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur 3', nombreAccion: 'Lorem 3', accion: 'test' },
      { pregunta: 'Lorem ipsum dolor sit amet consectetur 4', respuesta: 'Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur 4', nombreAccion: 'Lorem 4', accion: 'test' },
      { pregunta: 'Lorem ipsum dolor sit amet consectetur 5', respuesta: 'Lorem ipsum dolor sit amet consectetur 5', nombreAccion: '', accion: '' },
    ]
  }

  informacionTestimonios: any = {
    titulo: 'Testimonios',
    subtitulo: 'Lorem ipsum dolor sit amet consectetus.',
    testimonios: [
      { imagen: 'assets/images/house/1.jpg', nombre: 'Ernesto Colina', ocupacion: 'Ingeniero de sistemas', opinion: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.' },
      { imagen: 'assets/images/house/2.jpg', nombre: 'Dickinson', ocupacion: 'Ingeniero de sistemas', opinion: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.' },
      { imagen: 'assets/images/house/3.jpg', nombre: 'Juancho', ocupacion: 'Ingeniero de sistemas', opinion: 'Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.' },
    ]
  }

  informacionAyudaVenta = {
    titulo: 'Te ayudamos a vender tu propiedad',
    subtitulo: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus, temporibus!',
    descripcion: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur quaerat veniam sit. 
    Facere ratione fuga necessitatibus ea nulla, ab adipisci esse vero itaque soluta perferendis.`,
    nombreAccion: 'Consigna tu propiedad',
    imagen: '../../../assets/images/index/2.jpg',
    accion: '/consignar/inmueble'
  }

  constructor() { }

  ngOnInit(): void {
  }

}
