import { Injectable } from '@angular/core';
import Analytics from '@aws-amplify/analytics';
import { AuthService, UserInfo } from '../auth/auth.service';
import { AbstractService } from './abstract.service';
import { DTOUtil, UUID } from './utils';


declare const dataLayer: any[]

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

  private _deviceId = null
  private _sessionId = null
  private _userId = null
  private userInfo: UserInfo = UserInfo.ANONYMUS

  private static UserIdKey = "16cb7a72-f435-4433-a194-53a6932c608d"
  private static DeviceIdKey = "17040b9c-c5a1-404b-b05a-dbadf328d150"

  constructor(private auth: AuthService) {
    this.updateUserId()
    this.loadDeviceId()
    this._sessionId = UUID.randomUUIDv4()
    auth.userChange.subscribe(e => this.updateUserId())
  }


  get userId(): string {
    return this._userId
  }

  get sessionId(): string {
    return this._sessionId
  }

  get deviceId(): string {
    return this._deviceId
  }

  registerEvent(eventId: string, params?: { [key: string]: number | string | boolean }, metrics?: { [key: string]: number }) {
    // const convertedParams = params;
    // if (params != null) {
    //   Object.keys(params).forEach(k => convertedParams[k] = String(params[k]));
    // }
    // Analytics.record(eventId, convertedParams, metrics).then(e => this.logObject).catch(e => this.printError(e));
    dataLayer.push({ "event": eventId, ...params })
  }

  streamEvent(stream: string, data: any, opts: StreamFieldOptions = {}) {
    let extra: any = {}
    if (opts.addTimestamp == null || opts.addTimestamp) {
      extra.timestamp = new Date()
    }
    if (opts.addDeviceId == null || opts.addDeviceId) {
      extra.deviceId = this.deviceId
    }
    if (opts.addSessionId == null || opts.addSessionId) {
      extra.sessionId = this.sessionId
    }
    if (opts.addUserId == null || opts.addUserId) {
      extra.userId = this.auth.userInfo.anonymus ? 'anon' : this.auth.userInfo.sub
    }
    let event = DTOUtil.merge(data, extra)
    Analytics.record({ data: event, streamName: stream }, 'AWSKinesisFirehose');
  }


  private registerLogin() {
    let loginMethod = 'onsite'
    if (this.userInfo.username.startsWith('Google_')) loginMethod = 'google';
    if (this.userInfo.username.startsWith('Facebook_')) loginMethod = 'facebook';
    this.registerEvent('login', { loginMethod })
    if (this.userInfo.newUser) {
      this.registerEvent('sign_up', { loginMethod });
    }
  }

  private updateUserId() {
    const oldUser = this.userInfo
    this.userInfo = this.auth.userInfo
    if (oldUser.anonymus && !this.userInfo.anonymus) {
      this.registerLogin();
    }
    if (this.userInfo.anonymus) {
      let stored = localStorage.getItem(AnalyticsService.UserIdKey)
      if (!stored) {
        stored = `anon_${UUID.randIdString(19)}`
        localStorage.setItem(AnalyticsService.UserIdKey, stored)
      }
      this._userId = stored
    } else {
      this._userId = this.userInfo.sub
    }
  }


  /**
 * Obtienen un número único que identifica el dispositivo actual
 */
  private loadDeviceId() {
    this._deviceId = localStorage.getItem(AnalyticsService.DeviceIdKey)
    if (!this._deviceId) {
      this._deviceId = UUID.randomUUIDv4()
      localStorage.setItem(AnalyticsService.DeviceIdKey, this._deviceId)
    }
  }
}

export interface StreamFieldOptions {
  addDeviceId?: boolean
  addUserId?: boolean
  addTimestamp?: boolean
  addSessionId?: boolean
}
