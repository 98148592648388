import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AbstractComponent } from '../../abstract.service';

@Component({
  selector: 'zona-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent extends AbstractComponent implements OnInit, AfterViewInit {

  @Output()
  pageChanged = new EventEmitter<number>()
  @Input()
  visiblePages = 6;
  @Input()
  usePath = false
  @Input()
  linkPrefix = ""
  @Input()
  set pageCount(value) {
    if (value === this._pageCount) return
    this._pageCount = value;
    this.updatePageList()
  }

  get pageCount(): number {
    return this._pageCount
  }

  pageList: number[] = [];
  hasNextPage = false;
  hasPreviousPage = false;
  _pageCount: number = 0
  _locked = false;
  prevEnabled = false
  nextEnabled = false
  _pageIndex: number = 1
  subPath: any

  @Input()
  set pageIndex(value) {
    if (this.pageIndex === value) return
    this._pageIndex = value
    this.updatePageList()
    if (!this.usePath)
      this.pageChanged.emit(value)
  }

  get pageIndex() {
    return this._pageIndex
  }

  @Input()
  set locked(locked: boolean) {
    this._locked = locked
    this.updatePrevNext()
  }

  get locked() {
    return this._locked;
  }

  constructor(route: ActivatedRoute) {
    super();
    route.queryParams.pipe(map(p => parseInt(p['__pagina'])), map(p => isNaN(p) ? 1 : p)).subscribe(p => this.pageIndex = p)
  }
  ngAfterViewInit(): void {
    this.pageChanged.emit(this._pageIndex)
  }

  ngOnInit(): void {
  }

  private getPageList(maxVisibleCount: number, currentPage: number, totalPages: number): number[] {
    let visiblePages = maxVisibleCount > totalPages ? totalPages : maxVisibleCount;
    let half = Math.floor(visiblePages / 2);
    if (currentPage <= half) {
      return [...Array(visiblePages).keys()].map((e, i) => i + 1)
    }
    if (currentPage >= (totalPages - half)) {
      return [...Array(visiblePages).keys()].map((e, i) => totalPages - i).reverse()
    }
    let pages = []
    for (let i = currentPage - half; i < currentPage + half; i++) {
      pages.push(i + 1)
    }
    return pages
  }

  //TODO: corregir el comportamiento de la lista de páginas visibles cuando se selecciona la última página, solo se muestran 3
  private updatePageList() {
    this.pageList = this.getPageList(this.visiblePages, this.pageIndex, this.pageCount)
    this.hasPreviousPage = this.pageIndex > 1;
    this.hasNextPage = this.pageIndex < this.pageCount;
    this.updatePrevNext()
  }


  nextPage() {
    if (this.nextEnabled)
      this.pageIndex++
  }

  previousPage() {
    if (this.prevEnabled)
      this.pageIndex--
  }

  updatePrevNext() {
    this.prevEnabled = !this.locked && this.hasPreviousPage
    this.nextEnabled = !this.locked && this.hasNextPage
  }
}
