<div fxLayout="column" fxLayoutAlign="space-around center">
  <img src="/assets/images/inmueble/sync-fav.svg" style="width: 250px;">
  <p style="max-width: 180px; max-width: 350px; text-align: justify;">{{textoDialogo}}</p>

  <div class="button-panel" *ngIf="!this.data?.delete" >
    <button mat-raised-button (click)="guarSinAuth()" style="margin-right: 5px;">Guardar y no sincronizar</button>
    <button mat-raised-button color="primary" (click)="abrirAuth()">Guardar y sincronizar</button>
  </div>
  <div class="button-panel" *ngIf="this.data?.delete">
    <button mat-button (click)="guarSinAuth()" style="margin-right: 5px;">Guardar y no sincronizar</button>
    <button mat-button color="primary" (click)="abrirAuth()">Guardar y sincronizar</button>
    <button mat-raised-button color="primary" (click)="cancelar()">Cancelar</button>
  </div>
</div>
