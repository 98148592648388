<div class="logo">
    <img src="/assets/icons/zh_logo_bw_small.png">
</div>
<!-- <div class="pre-footer" fxLayout="row" fxLayout.xs="column" fxLayoutGap="1em" fxLayoutAlign="center center">
    <div class="content">
        <h1><b>Conviértete en un agente inmobiliario</b></h1>
        <p class="mtn15 mb0">Solo trabajamos con las mejores empresas del mundo</p>
    </div>
    <button class="zona-btn-transparent zona-text-b-500 zona-text-md px20">
        Regístrate ahora
    </button>
</div> -->
<div class="footer">
    <div class="container-responsive content" fxLayout="row wrap" fxLayoutAlign="space-between">
        <div class="contact" fxLayout="column" fxLayoutAlign="start start" fxFlex="48" fxFlex.gt-xs="33">
            <label class="section-title">Contacto</label>
            <a href="mailto:hola@zonahabitat.com" target="_blank" class="mt15"><i
                    class="far fa-envelope"></i>hola@zonahabitat.com</a>
            <a href="tel:+573045325151" target="_blank"><i class="fa fa-phone"></i>+57 304 532 51
                51</a>
            <span><i class="far fa-building"></i>Av Cra 9 # 113-52 Bogotá</span>
        </div>
        <div class="legal" fxLayout="column" fxLayoutAlign="end" fxLayoutAlign.gt-xs="center" fxFlex="48" fxFlex.gt-xs="33">
            <label class="section-title">Información de interés</label>
            <a routerLink="/aliado/login" class="mt20">Aliados</a>
            <a routerLink="/paga-referir" rel="nofollow">Zonabitero  - Referir</a>
            <a routerLink="/politica-privacidad" target="_blank" rel="nofollow">Política de privacidad</a>
            <a routerLink="/herramientas" rel="nofollow">Herramientas</a>
            <a routerLink="/consultoria" rel="nofollow">Consultoría</a>
            <a routerLink="/about" rel="nofollow">A cerca de Zona Hábitat</a>
            <a routerLink="/revista/1">Revista</a>
            <a href="https://storyset.com" target="_blank">Illustrations by Freepik Storyset</a>
        </div>
        <div class="social-networks" fxLayout="column" fxLayoutAlign="center" fxLayoutAlign.gt-xs="start end" fxFlex="100" fxFlex.gt-xs="33">
            <label class="section-title">Síguenos en</label>
            <div class="icons mt15" fxLayout="row" fxLayoutGap="1em" fxLayoutAlign.lt-sm="center center"
                fxLayoutAlign="center center">
                <a [attr.href]="env.facebook" target="_blank">
                    <i class="fa fa-facebook"></i>
                </a>
                <a [attr.href]="env.twitter" target="_blank">
                    <i class="fa fa-twitter"></i>
                </a>
                <a [attr.href]="env.instagram" target="_blank">
                    <i class="fa fa-instagram"></i>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="copyright">
    <label>Zona Hábitat. Todos los derechos reservados {{year}}.</label>
</div>
