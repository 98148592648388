import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zona-not-fount',
  templateUrl: './not-fount.component.html',
  styleUrls: ['./not-fount.component.scss']
})
export class NotFountComponent implements OnInit {


  titulo:String = "La publicacion no existe";
  subtitulo:String ="hola mundo";
  color: String ="white";

  constructor() { }

  ngOnInit(): void {
  }

}
