import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InmueblesDestacadosComponent } from './inmuebles-destacados/inmuebles-destacados.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TarjetaInmuebleModule } from '../tarjeta-inmueble/tarjeta-inmueble.module';
import { CarouselModule } from 'ngx-owl-carousel-o';



@NgModule({
  declarations: [InmueblesDestacadosComponent],
  exports: [InmueblesDestacadosComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TarjetaInmuebleModule,
    CarouselModule
  ]
})
export class InmueblesDestactadosModule { }
