import { Component, Input, OnInit, OnChanges } from '@angular/core';

@Component({
  selector: 'zona-imagen',
  templateUrl: './imagen.component.html',
  styleUrls: ['./imagen.component.scss']
})
export class ImagenComponent implements OnInit, OnChanges {

  @Input()
  url: any = "/assets/images/preview_img_no_disponible.png"
  @Input()
  urlNoDisponible: string = "/assets/images/preview_img_no_disponible.png"
  @Input()
  alto: number = 300
  _url: string = "/assets/images/preview_img_no_disponible.png"
  _imgIndex: number = -1
  _imgArray: string[] = []
  imagenCargada = false
  noDisponible = false
  cargando = true
  constructor() {
  }

  ngOnInit(): void {
    if (this.url == null) {
      this.noDisponible = true
      this.cargando = false
      return
    }
    if (typeof (this.url) === 'string') {
      this._imgArray = [this.url]
    }
    if (Array.isArray(this.url)) {
      this._imgArray = this.url
    }
    this.siguienteImagen()
  }

  ngOnChanges(): void {
    if (this.url !== null) this._url = this.url;
  }

  errorImagen() {
    //TODO: Registrar imagen no disponible
    this.siguienteImagen()
  }

  onImagenCargada() {
    this.imagenCargada = true
    this.cargando = false
    this.noDisponible = false
  }

  siguienteImagen() {
    if ((this._imgIndex + 1) < this._imgArray.length) {
      this._imgIndex++
      this._url = this.url[this._imgIndex]
    } else {
      this.noDisponible = true
      this.cargando = false
    }
  }

}
