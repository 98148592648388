export const environment = {
  production: true,
  app_url_:'https://zonahabitat.com',
  enableLogging: false,
  backendServer: {
    "propertySearch": "https://qzaingl8oj.execute-api.us-east-1.amazonaws.com/prod",
    "geo": "https://qzaingl8oj.execute-api.us-east-1.amazonaws.com/prod"
  },
  socialNetworks: {
    facebook: "https://www.facebook.com/Zonahabitat",
    twitter: "https://twitter.com/zonahabitat",
    instagram: "https://www.instagram.com/zonahabitat"
  },
  payu: {
    merchantId: "903587",
    accountId: "910277",
    postUrl: "https://checkout.payulatam.com/ppp-web-gateway-payu/"
  },
  stream: {
    visualizaciones: 'visualizaciones-zona-habitat-develop',
    postVisualizacion: 'zh-post-visualizacion-develop',
    busquedaMapa: 'zh-busqueda-mapa-develop'
  },
  imageRootUrl: `https://imagenes.zonahabitat.com`,
  awsmobile: {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "zona-habitat-ng-prod-bucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3okkrlo5l75c2.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:804081f5-32c8-40cf-b71d-2c1a3a1d6c14",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9yXe202tj",
    "aws_user_pools_web_client_id": "1doda470ese7vqaaetbk28eeho",
    "oauth": {
      "domain": "zonahabitat-prod.auth.us-east-1.amazoncognito.com",
      "scope": [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": "https://zonahabitat.com/signin/",
      "redirectSignOut": "https://zonahabitat.com/signout/",
      "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "6c96680399a3474db1ba7ffbd04db213",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_cloud_logic_custom": [
      {
        "name": "blog",
        "endpoint": "https://dpwvpnhb9k.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "contacto",
        "endpoint": "https://q3r2d0ocqe.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "inmuebles",
        "endpoint": "https://x3wo9wgngc.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "inmobiliarias",
        "endpoint": "https://xpjbshfyz4.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "fotos",
        "endpoint": "https://4e9xpupcl8.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "regiones",
        "endpoint": "https://x6pp3ost90.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "subscripcion",
        "endpoint": "https://ssg9pa9ag3.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "sitios",
        "endpoint": "https://tjwflb5dwk.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "zonacreditos",
        "endpoint": "https://93lyrulawc.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "leads",
        "endpoint": "https://wze6gxc5dk.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "inmueblesSeguro",
        "endpoint": "https://rk535r11r5.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "favoritos",
        "endpoint": "https://tag52uq6ze.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "leadsSeguro",
        "endpoint": "https://izvsn4f682.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "pagos",
        "endpoint": "https://2dqughpgpf.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "admin",
        "endpoint": "https://u8yr4bnqsb.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "inventarios",
        "endpoint": "https://3225wgfea7.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      },
      {
        "name": "wasi",
        "endpoint": "https://ettkdz0vz4.execute-api.us-east-1.amazonaws.com/prod",
        "region": "us-east-1"
      }
    ],
    "aws_user_files_s3_bucket": "zona-habitat-imagenes-inmueblesng-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
  }

};
