<section class="seccion">
    <div class="background" [style.background-image]=" imagen? 'url(' +imagen + ')' : ''"></div>
    <div class="foreground" [style.background-color]="color">
        <div class="container-responsive" [style.max-width]="maxWidth">
            <h2>{{titulo}}</h2>
            <p class="subtitulo">{{subtitulo}}</p>
            <div class="contenido">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</section>
