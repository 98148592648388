<div class="layout-container mat-typography">
  <app-top-nav (sideNavToggled)="snav.toggle()"></app-top-nav>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #snav class="sidenav mat-elevation-z10" [(mode)]="sideNavMode" [(opened)]="sideNavOpened">
      <div class="sidenav-content">
        <app-side-nav (click)="sidenavClicked()"></app-side-nav>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <main class="main-container" #scroll>
        <div [class.full-screen]="fullScreen">
          <div class="content" [class.full-screen]="fullScreen"
            [class.content-sidebar]="sideNavOpened && !mobileDevice">
            <router-outlet (activate)="onRouterActivated($event)" (deactivate)="onRouterDeactivated($event)">
              <div style="height: 100vh" *ngIf="loaderVisible">

              </div>
            </router-outlet>
            <!-- <zona-search-links *ngIf="!fullScreen && !hideSearchLinks"></zona-search-links> -->
          </div>
          <zona-footer class="footer" *ngIf="!fullScreen"></zona-footer>
        </div>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
  <zona-pqrs-floating-button fxHide fxShow.gt-sm="true"></zona-pqrs-floating-button>
  <zona-vender-floating-button fxShow fxHide.gt-sm></zona-vender-floating-button>
</div>
