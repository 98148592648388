import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZonaNotFoundHandlerComponentComponent } from './zona-not-found-handler-component/zona-not-found-handler-component.component';
import { ServiceWorkerModule } from '@angular/service-worker';



@NgModule({
  declarations: [ZonaNotFoundHandlerComponentComponent],
  imports: [
    CommonModule,
    ServiceWorkerModule
  ]
})
export class ZonaNotFoundHandlerModuleModule { }
