<div class="location mat-typography unselectable">
    <div class="button">
       <div class="layout" [class.layout-expanded]="displayDetail">

            <div class="detail" [class.visible]="displayDetail">
                <p class="title">Zona Hábitat te escucha</p>
                <p class="message">¿Felicitaciones, Sugerencias, Quejas? <br /> Haz click acá</p>
            </div>
            <span class="icon"></span>
        </div>
        <div class="background" [class.expanded]="displayDetail" (click)="openModal()"
            (mouseover)="this.displayDetail = true" (mouseout)="this.displayDetail = false"></div>

    </div>
</div>
